@import "@styles/mixins";

.downloadLink {
  white-space: nowrap;
  color: var(--secondary);
  font-weight: 500;
  border: none;
  padding: 0;
  background-color: transparent;
  cursor: pointer;

  &:hover {
    filter: brightness(1.2);
  }
  &.disabled {
    cursor: default;
    pointer-events: none;
    opacity: 0.3;
  }
}
