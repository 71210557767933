@import "@styles/mixins";

.sidenav:not(.mobileView) {
  position: fixed;
  top: var(--header-height);
  bottom: 0;
  background-color: var(--bg-white-opacity);
  padding: 0 var(--spacing-m);
  box-shadow: var(--box-shadow-sidebar);
  z-index: 300;
}
.sidenav,
.sidenavCollapsed {
  --inactive-status: grayscale(0.8);
  --inactive-opacity: 0.4;

  overflow: hidden;
  width: calc(var(--side-bar-width) + 32px);
  .innerWrapper {
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
    width: calc(100% + 28px);
    padding-right: 28px;
  }
}

.sidenavCollapsed:not(.mobileView) {
  position: fixed;
  top: var(--header-height);
  bottom: 0;
  background-color: var(--bg-white-opacity);
  box-shadow: var(--box-shadow-sidebar);
  width: var(--sidenav-mobile-width);
  z-index: 300;
  .innerWrapper {
    width: calc(100% + 32px);
  }
}

.sidenav,
.sidenavCollapsed {
  &.mobileView {
    position: relative;
    width: 100%;
    height: 100%;
    min-width: 300px;

    .wrapper {
      width: 100%;
    }
    .toggle {
      pointer-events: none;
    }
  }
}

.submenu {
  position: fixed;
  display: none;
  padding-left: var(--spacing-xxs);
  z-index: 200;
  animation: FadeIn 0.3s ease-in-out;

  .innerSubmenu {
    background-color: var(--white);
    box-shadow: var(--box-shadow);
    color: var(--text);
    font-size: var(--font-size-base);
    border-radius: var(--border-radius);
    cursor: pointer;
  }
}

.wrapper {
  font-size: var(--font-size-small);
  transition: var(--sidenav-transition) linear;

  &.toggleOpen {
    padding: var(--spacing-xs);
    transform: translate(-50%, -50%) rotate(180deg);
    right: -16px;
    padding: var(--spacing-xs);
    transform-origin: center center;
  }
  &:hover {
    color: #fff;
  }
}

.separator {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-m);
  gap: 0;

  &.toggleOpen {
    gap: 16px;
  }

  &.toggleOpen .separatorInner {
    transform-origin: center center;
    transform: rotate(-180deg);
  }
  &.toggleOpen .line {
    min-width: 182px;
    width: 100%;
    opacity: 1;
    transform: rotate(-180deg);
  }

  .line {
    width: 0;
    min-width: 0;
    border-top: 1px solid var(--main-color-separator);
    opacity: 0;
    transition: opacity, min-width 0.1s;
  }

  .separatorInner {
    width: 22px;
    height: 22px;
    transition: 0.3s;
    cursor: pointer;
  }
}

.seprator.toggleOpen {
  opacity: 1;
}

.wrapper {
  padding-bottom: 20px;
  height: 100%;
  min-height: 100%;
  transition: width var(--sidenav-transition) var(--bezier-transition), opacity 0ms;
  width: var(--sidenav-mobile-width);

  &.wrapperOpen {
    transition: width var(--sidenav-transition) var(--bezier-transition),
      opacity 0ms var(--sidenav-transition);
    width: var(--side-bar-width);
    opacity: 1;
    .nav {
      pointer-events: auto;
    }
    .logout {
      pointer-events: auto;
    }
  }
}

.nav {
  display: flex;
  flex-direction: column;
  min-height: calc(100% - var(--logout-height));
  padding-top: 15px;

  .processing {
    @include flexContainer(column);

    width: 100%;
    margin-top: auto;
  }
}

.link {
  display: flex;
  align-items: center;
  width: 100%;
  height: var(--sidenav-link-height);
  padding-right: 10px;
  color: var(--primary);
  background-color: transparent;
  border: none;
  outline: none;
  overflow: hidden;
  text-decoration: none;
  font-weight: 500;
  font-family: Inter;
  transition: all 0.3s;
  .text {
    opacity: 0.55;
    width: 100%;
    padding-left: 12px;
    overflow: hidden;
    white-space: nowrap;
    animation: FadeInText 0.3s ease-in-out;
    transition: 0.3s;
  }

  &:hover {
    background-color: var(--secondary-hover);
    &:first-child {
      border-radius: var(--border-radius) var(--border-radius) 0 0;
    }
    &:last-child {
      border-radius: 0 0 var(--border-radius) var(--border-radius);
    }
  }
  &:hover .text {
    opacity: 1;
  }
}

.linkCollapsed {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: var(--sidenav-link-height-collapsed);
  padding-right: 10px;
  color: var(--primary);
  transition: all 0.3s;
  &:hover {
    background-color: var(--secondary-hover);
  }
}

.mainLink {
  letter-spacing: 0.5px;
  font-size: 16px;
}

.subLink {
  letter-spacing: 0.5px;
  font-size: var(--font-size-base);
}

.staticLink {
  position: relative;
  font-size: var(--font-size-main);
  color: var(--primary);
  cursor: pointer;
  .arrow {
    position: absolute;
    right: 10px;
    align-self: center;
  }
  &.collapsed {
    .arrowIcon {
      transform: rotate(-180deg);
    }
    & + .linkWrapper {
      .subLink {
        height: 0;
        opacity: 0;
      }
    }
  }
}

.firstLevelLink {
  position: relative;
  &:hover {
    .linkWrapperMenuClose {
      animation: FadeIn 0.3s;
      display: block;
    }
  }

  .active,
  .linkWrapper .active,
  .linkWrapperMenuClose .active {
    opacity: 1;
  }

  .inactive,
  .linkWrapper .inactive,
  .linkWrapperMenuClose .inactive {
    filter: var(--inactive-status);
    opacity: var(--inactive-opacity);
  }
}
.firstLevelLink:last-child {
  margin-top: auto;
  margin-bottom: var(--spacing-m);
  .menuIcon {
    > svg {
      min-height: 28px;
      min-width: 28px;
    }
  }
}

.linkWrapperMenuClose {
  position: absolute;
  left: 84px;
  top: 0;
  padding-left: 4px;
  display: none;
  box-shadow: var(--box-shadow);
  .subLink {
    letter-spacing: 0.5px;
    font-size: var(--font-size-base);
    min-width: 140px;
    height: var(sidenav-link-height-collapsed);
    background-color: var(--white);
    &:hover {
      background-color: var(--secondary-hover);
    }
  }
}

.menuIcon {
  padding-left: 12px;

  svg {
    display: block;
    height: 28px;
    width: 22px;
    min-width: 22px;
  }
}

.icon {
  transition: var(--sidenav-transition) var(--bezier-transition);
}

.linkWrapper {
  transition: var(--sidenav-transition) var(--bezier-transition);
  padding-left: 48px;
}

.activeLink {
  background-color: var(--disabled);
}

.logout {
  height: var(--logout-height);
  pointer-events: none;
  &:hover {
    color: var(--error) !important;
  }
  @media (max-width: 991px) {
    pointer-events: none !important;
  }
}

.logoWrapper {
  height: var(--logo-height);
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid var(--border);
  pointer-events: none;
}

.sideBarFooter {
  padding-top: var(--spacing-m);
  padding-bottom: var(--spacing-l);
  border: none;

  > a {
    text-decoration: none;
  }
}

.logoText {
  display: none;
}

.sideMenuBorder:first-child {
  display: block;
  height: 0;
  border-left: 3px solid transparent;
  // padding-right: var(--spacing-m);
  transition: 0.3s;
}
.sideMenuBorder:last-child {
  display: block;
  height: 0;
  border-right: 3px solid transparent;
  padding-left: var(--spacing-m);
  margin-left: auto;
  transition: 0.3s;
}

.selected .sideMenuBorder:first-child {
  display: block;
  height: 100%;
  border-left-color: var(--main-color);
}

.selectedCollapsed .sideMenuBorder:last-child {
  display: block;
  height: 100%;
  border-left: none;
  border-right-color: var(--main-color);
}
.sidenavCollapsed .selectedCollapsed .sideMenuBorder:last-child {
  border-right-color: var(--main-color);
}

.sidenavCollapsed {
  .sideMenuBorder:last-child {
    border-left: none;
    border-right: 3px solid transparent;
  }
  .menuIcon {
    padding-left: 28px;
    margin-right: 0;
  }
  .nav {
    justify-content: flex-start;
    a,
    .linkCollapsed {
      padding-right: 0;
      justify-content: flex-start;
    }
  }
}

.sideBarFooter .linkCollapsed {
  .menuIcon {
    padding-left: 28px;
    margin-right: 0;
  }
  justify-content: space-between;
  padding: 0;
}

// Chat Menu Item
.odinMenuContainer {
  --odin-chat-button-height: 72px;

  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  // margin-top: var(--spacing-2xl);
  margin-bottom: var(--spacing-m);
  padding-top: var(--spacing-l);
  transition: 0.3s;

  &.collapsled {
    padding: 0 var(--spacing-xxs);
  }

  &:hover {
    .menuItemInner {
      filter: brightness(1.3);
    }
  }

  &.inactive {
    opacity: var(--inactive-opacity);
    filter: var(--inactive-status);
  }

  &.collapsled .menuItemInner {
    align-items: center;
    column-gap: 0;
    padding: var(--spacing-m) 16px;
    background-color: var(--primary);
    border: none;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      filter: brightness(1.3);
    }

    .buttonWrapper {
      > span {
        display: flex;
        align-items: center;
        flex-direction: column;
        font-size: var(--font-size-main);
        > span {
          margin-left: 0;
        }
      }
    }

    .badgeContainer {
      left: unset;

      &.collapsed {
        top: -42px;
      }
    }
  }

  .menuItemInner {
    @include flexContainer();

    position: relative;
    min-height: var(--odin-chat-button-height);
    max-height: var(--odin-chat-button-height);

    width: 100%;
    padding: var(--spacing-m);
    background-color: var(--primary);
    border-radius: var(--border-radius);
    column-gap: var(--spacing-m);
    transition: background-color, padding 0.3s;
    max-width: 250px;
    overflow: hidden;
    box-shadow: var(--box-shadow);
    transition: 0.3s;
    cursor: pointer;

    &:active {
      filter: brightness(1.4);
    }

    .title {
      position: absolute;
      bottom: calc(100% + 4px);
      color: var(--primary);
      font-weight: 500;
      font-size: var(--font-size-base);
    }
    .buttonWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      max-width: 100%;

      transition: 0.3s;
      animation: FadeIn 1s ease-in-out;

      > span {
        color: var(--secondary);
        font-size: var(--font-size-medium);
        font-weight: 600;
        white-space: nowrap;
        overflow: hidden;
        user-select: none;
        > span {
          margin-left: var(--spacing-xxs);
          color: var(--white);
        }
      }
    }
    .badgeContainer {
      position: absolute;
      top: 8px;
      left: 64px;
    }
  }
}

@keyframes FadeInText {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.55;
  }
}

@keyframes FadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
