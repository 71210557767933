@import "@styles/mixins";

.container {
  --banner-min-width: 300px;
  --banner-max-width: 800px;
  --card-icon-width: 100px;

  overflow-y: auto;
  overflow-x: hidden;
}

.container {
  @include flexContainer(column, flex-start, flex-start);

  width: 100%;
  flex-grow: 1;
  color: var(--white);
  padding: var(--spacing-4xl);
  padding-bottom: 0;
  background-color: var(--black-90);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  .inner {
    @include flexContainer(column, flex-start, flex-start);

    width: 100%;
    height: 100%;
    padding-top: var(--spacing-7xl);
    row-gap: var(--spacing-l);
    flex-grow: 1;
    max-width: var(--max-inner-content-width);
    margin: 0 auto;
    animation: var(--slide-up-animation);

    .title {
      font-size: var(--font-size-extart);
      font-weight: 600;
      width: 100%;
      text-align: center;
    }
    .info {
      @include flexContainer(column, flex-start, flex-start);

      width: 100%;
      padding: var(--spacing-l);
      border-radius: var(--border-radius-8);
      background-color: var(--white-opacity-10);
      font-size: var(--font-size-medium);

      > p {
        text-align: justify;
        line-height: var(--line-height-base);
        > a {
          text-decoration: none;
          color: inherit;
          font-weight: 400;
          color: var(--secondary-lighten);
        }
      }
      > p:not(:last-child) {
        margin-bottom: var(--spacing-2xl);
      }
    }
    .presentationSection {
      @include flexContainer(row, center, flex-start);

      width: 100%;
      padding-top: var(--spacing-7xl);

      .banner {
        @include flexContainer(column, flex-start, center);

        flex-grow: 1;
        width: 100%;
        min-width: var(--banner-min-width);
        max-width: var(--banner-max-width);
        margin-bottom: var(--spacing-7xl);

        > img {
          display: flex;
          width: 100%;
          height: auto;
          border-radius: var(--border-radius-8);
        }
      }
    }
    .footerText {
      font-size: var(--font-size-small);
      opacity: 0.6;
    }
  }
}

@media (max-width: 991px) {
  .container {
    .inner {
      .presentationSection {
        flex-direction: column;
        align-items: center;
        row-gap: var(--spacing-2xl);
        .about {
          .subtitle {
            text-align: center;
          }
          .list {
            width: auto;
          }
        }
      }
      .topSection {
        justify-content: center;
      }
    }
  }
}

@media (max-width: 575px) {
  .container {
    padding: var(--spacing-4xl) var(--spacing-l);
    .inner {
      .title {
        text-align: center;
        font-size: var(--font-size-large);
      }
      .info {
        font-size: var(--font-size-base);
      }
      .presentationSection {
        .about {
          align-items: center;
        }
      }
    }
  }
}

@keyframes FadeInZoom {
  0% {
    opacity: 0;
    // transform: translateX(300px);
    transform: scale(0.2);
  }
  100% {
    opacity: 1;
    // transform: translateX(0px);
    transform: scale(1);
  }
}
