@import "@styles/mixins";

.row {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: var(--spacing-m);

  &:not(:last-child) {
    padding-bottom: var(--spacing-l);
  }
  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &.noSpace {
    row-gap: 0;
    padding-bottom: 0;
    .itemWrapper {
      padding: 2px 0 var(--spacing-l) 0;
    }
  }
  .itemWrapper {
    width: calc((100% - 32px) * 1 / 3);
    min-width: 270px;
    flex-grow: 1;

    &.empty {
      padding: 0;
    }
  }
}
