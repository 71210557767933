.skeletonContainer {
  width: 100%;
  /* height: 100%; */
  min-height: 1.4em;
}
.skeletonBlock {
  width: 100%;
  border-radius: var(--border-radius-xs);
  background-color: var(--gray-5);
  min-height: 1.4em;
  opacity: 0.3;
}
.skeletonBox {
  height: 100%;
}
.skeletonBlock:not(:last-child) {
  margin-bottom: 0.6em;
  margin-top: 0.6em;
}
.skeletonActive {
  background-color: #7a7c8047;
  background-image: linear-gradient(
    90deg,
    #7a7c8000 0%,
    rgba(122, 124, 128, 0.8660057773109244) 50%,
    #7a7c8000 100%
  );
  animation: SlideShape 2s infinite;
  background-repeat: no-repeat;
  background-position: 200% center;
  background-size: 200% 100%;
}

@keyframes SlideShape {
  50% {
    background-position: 200% center;
    background-repeat: no-repeat;
  }
  100% {
    background-position: -200% center;
    background-repeat: no-repeat;
  }
}
