@import "@styles/mixins";

.btnWrapper {
  @include flexContainer;

  button {
    font-weight: 600;
    min-width: 200px;

    > svg {
      max-width: 18px;
      width: 18px;
      min-width: 18px;
    }
  }
}
