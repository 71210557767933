@import "@styles/mixins.scss";

.searchWrapper {
  width: 100%;
  margin-bottom: var(--spacing-l);

  @media (max-width: 991px) {
    width: 100%;
  }

  @media (max-width: 767px) {
    width: 100%;
  }
}

.emptyContainer {
  @include flexContainer(column);

  width: 100%;
  flex-grow: 1;
  border: 1px solid var(--main-border);
  border-radius: var(--border-radius);

  .info {
    padding: var(--spacing-l);
    text-align: center;
    font-size: var(--font-size-base);
    font-weight: 500;
    color: var(--main-color);
    opacity: 0.5;
  }
}
