@import "@styles/mixins.scss";

.container {
  @include flexContainer(column, flex-start, flex-start);

  flex-grow: 1;
  background-color: var(--container-bg);
  box-shadow: var(--box-shadow);
  width: 100%;
  min-height: var(--inner-content-min-height);
  border-radius: var(--border-radius);

  .inner {
    @include flexContainer(column, flex-start, flex-start);

    flex-grow: 1;
    width: 100%;
    padding: var(--spacing-l);
    border-radius: var(--border-radius);
    animation: var(--slide-up-animation);

    .heading {
      font-size: var(--font-size-medium);
      font-weight: 600;
      margin-bottom: var(--spacing-l);
    }
    .group {
      @include flexContainer(row, space-between, flex-end);

      margin-bottom: var(--spacing-xs);
      width: 100%;
      .helpText {
        font-size: var(--font-size-base);
        color: var(--muted-text);
        font-weight: 400;
      }
    }
    .tableSection {
      @include flexContainer(column, flex-start, flex-start);

      width: 100%;
      flex-grow: 1;

      .table {
        width: 100%;
      }
    }
  }
}
