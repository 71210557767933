@import "@styles/mixins";

.container {
  @include flexContainer(column, flex-start, flex-start);

  width: 100%;
  flex-grow: 1;

  &.updating {
    opacity: 0.4;
    pointer-events: none;
  }

  .loaderContainer {
    @include flexContainer(column, center);

    width: 100%;
    flex-grow: 1;
  }

  .count {
    opacity: 0.3;
  }
  .company {
    color: var(--secondary);
  }
  .status {
    text-transform: capitalize;

    &.denied {
      opacity: 0.3;
    }
    &.approved {
      color: var(--secondary);
      font-weight: 600;
    }
  }

  .pagination {
    padding-top: var(--spacing-l);
  }
}

.modalContainer {
  @include flexContainer(column, flex-start, flex-start);

  width: 100%;
  max-width: 550px;
  row-gap: var(--spacing-l);
  padding: var(--spacing-l);
  background-color: var(--white);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);

  .title {
    font-size: var(--font-size-extra-medium);
    font-weight: 600;
    width: 100%;
  }

  .userInfo {
    font-size: var(--font-size-main);
    font-weight: 600;
    color: var(--main-color-separator);
    > span {
      font-weight: 500;
      color: var(--primary);
    }
  }

  .dropdownWrapper {
    @include flexContainer(column, flex-start, stretch);

    width: 100%;
  }

  .btnGroup {
    @include flexContainer(row, space-between, center);

    width: 100%;
    column-gap: var(--spacing-l);
  }
}
