@import "@styles/mixins";

.container {
  --container-width: 200px;

  @include flexContainer(row, center);

  gap: var(--spacing-xs);

  &:has(.mobile) {
    width: var(--container-width);
  }

  .mobile {
    display: flex;
    width: 100%;
  }

  @media (max-width: 991px) {
    flex-direction: column;
    padding-top: calc(var(--spacing-10xl) + var(--spacing-l));
  }
}
