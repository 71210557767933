.main {
  width: 100vw;
  height: 100vh;
  min-height: -webkit-fill-available;
  min-height: 100svh;
}
.container {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
  overflow: hidden;
}
.inner {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center center;
  animation: FadeIn 0.5s ease-in-out;
}

@keyframes FadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
