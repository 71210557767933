@import "@styles/mixins";

.container {
  --button-width: 200px;

  @include flexContainer(row, flex-start, center);

  gap: var(--spacing-l);
  // width: 100%;

  .wrapper {
    max-width: var(--button-width);

    button > svg {
      max-width: 18px;
      min-width: 18px;
      path {
        stroke: var(--black-90);
        fill: var(--black-90);
      }
    }
  }

  @media (max-width: 575px) {
    flex-direction: column;
    row-gap: var(--spacing-s);
  }
}
