@import "@styles/mixins.scss";

.section {
  margin-bottom: var(--spacing-2xl);

  .headingGroup {
    @include flexContainer(row, flex-start);
    column-gap: var(--spacing-m);
    margin-bottom: var(--spacing-m);

    > svg {
      width: auto;
      height: 24px;
    }
  }

  .innerWrapper {
    @include borderedContainer(transparent);

    padding: var(--spacing-l);
    width: 100%;
    box-shadow: var(--box-shadow);

    .searchWrapper {
      margin-bottom: var(--spacing-l);
    }

    .actions {
      position: relative;
      @include flexContainer(center, space-between);
      .picker {
        width: 45%;
        min-width: 260px;
        margin-bottom: var(--spacing-m);
      }
    }
    .datePicker {
      width: 45%;
      min-width: 260px;
      display: flex;
      flex-grow: 1;
      margin-bottom: var(--spacing-m);

      @media (max-width: 575px) {
        width: 100%;
      }
    }
  }
}
