.container {
  .subHeading {
    margin-bottom: var(--spacing-m);
  }
  .sourcesAvailable {
    margin-top: var(--spacing-l);
    margin-bottom: var(--spacing-m);
    span {
      font-size: var(--font-size-main);
      font-weight: 600;
    }
  }
  .list {
    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      list-style: none;
      padding: var(--spacing-s);
      border-bottom: 1px solid rgba(238, 238, 238, 1);
      transition: all 0.3s;
      cursor: pointer;
      .nameGroupe {
        display: flex;
        flex-direction: column;
        .directory {
          font-size: var(--font-size-small);
        }
        .name {
          font-size: var(--font-size-main);
          font-weight: 500;
        }
        em {
          background-color: var(--warning);
        }
      }
      .tagsArrowContainer {
        display: flex;
        align-items: center;
        .tags {
          display: flex;
          flex-wrap: wrap;
          transform: translateX(5px);
          transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
          .tag {
            padding: 4px 8px;
            margin-left: var(--spacing-xxs);
            background: #e7e9ef;
            border-radius: var(--border-radius);
          }
        }
        .arrow {
          display: none;
          transform: rotate(90deg);
          margin-left: var(--spacing-s);
          animation: fadeIn 0.5s ease-in-out;
        }
      }
      &:hover {
        background: #f6f7f9;
        .name {
          color: var(--secondary);
        }
        .tagsArrowContainer {
          .arrow {
            display: block;
          }
          .tags {
            transform: translateX(0);
          }
        }
      }
    }
  }
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
