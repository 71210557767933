@import "@styles/mixins";

.container {
  .category {
    display: flex;
    flex-wrap: wrap;
    row-gap: var(--spacing-xxs);
    column-gap: var(--spacing-m);
    h3 {
      font-size: var(--font-size-medium);
      text-transform: capitalize;
    }
    .badgesContainer {
      @include flexContainer(row, flex-start);
      column-gap: var(--spacing-xs);
      min-width: 200px;

      .numberOfCategories {
        // margin-left: var(--spacing-xs);
        padding: var(--spacing-xxs) var(--spacing-xs);
        font-size: var(--font-size-base);
        border-radius: var(--border-radius);
        background-color: var(--secondary);
        color: var(--white);
        display: flex;
        align-items: center;
        column-gap: var(--spacing-xxs);
        cursor: default;
        span {
          font-size: var(--font-size-main);
        }
      }
    }
  }
  .subcategories {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
    padding-top: var(--spacing-xs);
    gap: var(--spacing-xs);
    cursor: pointer;

    .subcategory {
      @include flexContainer(row, space-between, center);
      padding: 6px 12px;
      border: 1px solid var(--main-border);
      border-radius: var(--border-radius);
      min-width: 230px;
      transition: all 0.3s;

      .arrow {
        display: none;
        transform: rotate(90deg);
        animation: var(--fade-in-default);
      }
      &:hover {
        background: var(--secondary-hover);
        border-radius: var(--border-radius);
        .arrow {
          display: block;
        }
      }
      .group {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: calc(100% - var(--spacing-2xl));

        .subcategoryTitle {
          text-transform: capitalize;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          font-size: var(--font-size-main);
          padding-right: var(--spacing-xs);
        }
        .numberOfSubcategory {
          padding: 4px 8px;
          background: #f6f7f9;
          border-radius: var(--border-radius);
          transform: translateX(5px);
          transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
        }
      }
      &:hover {
        .numberOfSubcategory {
          transform: translateX(0);
          background: #e7e9ef;
        }
      }
    }
  }
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  &:not(:last-child) > .subcategories {
    margin-bottom: var(--spacing-2xl);
  }
}

@media (max-width: 575px) {
  .container {
    .category {
      .badgesContainer {
        width: 100%;
      }
    }
  }
}
