@import "@styles/mixins.scss";

.container {
  --chart-min-height: 340px;

  padding: var(--spacing-xs);
  background: var(--primary);
  border-radius: var(--border-radius);

  .emptyData {
    @include flexContainer();
    @include borderedContainer(var(--main-border));
    padding: var(--spacing-2xl);
    width: 100%;
    height: 360px;
    color: var(--muted-text);
    background-color: var(--white);
    min-height: var(--chart-min-height);
  }
  .title {
    color: var(--white);
    font-size: 16px;
    font-weight: 600;
  }
  .inner {
    margin-right: 20px;
  }
  .chart {
    width: 100%;
    border-radius: var(--border-radius);
    overflow: hidden;
    background: var(--white);
    opacity: 1;
    min-height: 340px;
    justify-content: center;
    display: flex;

    .chartContainer {
      width: 100%;
      min-height: var(--chart-min-height);
    }
  }
  .head {
    display: flex;
    align-items: center;
    margin-bottom: var(--spacing-xs);
    .rangeSelect {
      display: flex;
      align-items: center;
    }
    .linkButton {
      display: flex;
      align-items: center;
      gap: var(--spacing-xs);
      margin-left: auto;
      transition: 0.1s;
      opacity: 0.7;
      cursor: pointer;

      &:hover {
        opacity: 1;
        gap: var(--spacing-m);
      }

      > span {
        color: var(--white);
      }
      > svg {
        color: white;
        width: 13px;
        height: auto;
        transform: rotate(90deg);
      }
    }
  }
}
