@import "@styles/mixins.scss";

.container {
  width: 100%;
  padding: var(--spacing-l);
  border-radius: var(--border-radius);
  background-color: var(--white);

  .heading {
    font-size: var(--font-size-medium);
    color: var(--secondary);
    font-weight: 600;
    margin-bottom: var(--spacing-xs);
  }
  .inner {
    @include flexContainer(column, flex-start, flex-start);
    @include borderedContainer(var(--main-border));
    padding: var(--spacing-m);
    color: var(--primary);

    .title {
      font-size: var(--font-size-main);
      font-weight: 600;
      margin-bottom: var(--spacing-xxs);
    }
    .description {
      font-size: var(--font-size-small);
      color: var(--primary);
      line-height: var(--line-height-main);

      &:not(:last-child) {
        margin-bottom: var(--spacing-m);
      }
    }
  }
}
