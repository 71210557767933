.container {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  flex-grow: 1;

  .tooltipContainer {
    position: absolute;
    display: inline-block;
    bottom: calc(100% - 16px);
    padding: var(--spacing-xs);
    background-color: #000;
    color: var(--white);
    border-radius: var(--border-radius);
    opacity: 0;
    transition: opacity, transform, 0.3s ease-in-out;
    transform: translateY(8px);
    z-index: 999;
    box-shadow: var(--hover-shadow);
    pointer-events: none;

    .tooltipContent {
      width: 175px;
      font-size: 10px;
      white-space: pre-line;
      text-align: center;
      line-height: 1em;
    }
  }
  .show {
    opacity: 0.8;
    transform: translateY(0px);
  }
}

.tooltipContainer {
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;

  &[data-truncate="true"] {
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

@keyframes fadeInBottom {
  0% {
    opacity: 0;
    transform: translateY(4px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(8px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.tooltipTextWrapper {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 500;
  height: 1px;

  &.left {
    justify-content: flex-end;

    .tooltipText {
      bottom: auto;
      animation-name: fadeInLeft;
    }
  }

  &.left .tooltipText:before {
    right: -3px;
    bottom: auto;
    top: calc(100% / 2 - 3px);
    transform: rotate(45deg);
  }

  .tooltipText {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    position: absolute;
    bottom: calc(100% + 4px);
    width: max-content;
    max-width: 250px;
    margin-bottom: var(--spacing-xxs);
    padding: var(--spacing-xs) var(--spacing-m);
    border-radius: var(--border-radius);
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    font-size: var(--font-size-base);
    font-family: Inter, Archivo, sans-serif;
    font-weight: 400;
    text-align: center;
    animation: fadeInBottom 0.3s ease-in-out;
    line-break: auto;

    > p {
      width: 100%;
      font-weight: 600;
      font-size: 1.1em;
      margin-bottom: var(--spacing-xxs);
      border-bottom: 1px solid rgba(255, 255, 255, 0.5);
      padding-bottom: var(--spacing-xxs);

      &[data-truncate="true"] {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }

    &:before {
      content: "";
      position: absolute;
      bottom: -3px;
      right: auto;
      width: 6px;
      height: 6px;
      transform-origin: center;
      transform: rotate(-45deg);
      background-color: rgba(0, 0, 0, 0.8);
    }
  }
}
