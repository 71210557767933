.container {
  margin-top: var(--spacing-m);
  .icon {
    cursor: pointer;
  }
}

.filterContainer {
  position: absolute;
  top: 70px;
  right: 0;
  width: 330px;
  height: auto;
  background-color: white;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  z-index: 999;
  padding: 20px;
  .label {
    font-size: var(--font-size-main);
    font-weight: 600;
    margin-bottom: var(--spacing-sx);
  }
  .countrySelect {
    .field {
      margin-bottom: var(--spacing-xxs);
    }
  }
  .checkbox {
    margin-top: 10px;
  }
  .resetFilter {
    display: flex;
    margin-top: var(--spacing-m);
  }
}
