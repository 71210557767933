.container {
  display: flex;
  width: 100%;
  margin-top: var(--spacing-sx);

  &.disabled {
    opacity: 0.3;
    pointer-events: none;
  }

  .inner {
    display: flex;
    width: 100%;
    align-items: center;

    .perPageWrapper {
      position: relative;
      display: flex;
      font-size: var(--font-size-main);
      cursor: pointer;
    }
    .perPageText {
      color: var(--main-color);
      font-weight: 500;
      opacity: 0.5;
    }
    .perPageCount {
      color: var(--main-color);
      padding-left: var(--spacing-xs);
    }
    .pagesWrapper {
      display: flex;
      align-items: center;
      padding-left: var(--spacing-2xl);

      .pagesList {
        display: flex;
        align-items: center;
        list-style: none;
        margin: 0;
        padding: 2px 0 0 0;
        font-size: var(--font-size-main);
        font-weight: 400;

        .pageItem {
          width: 34px;
          display: flex;
          justify-content: center;
          padding: 2px var(--spacing-xs);
          border-radius: var(--border-radius);
          color: var(--muted-text);
          user-select: none;

          &.active {
            background-color: var(--main-color);
            color: var(--white);
            margin: 0 var(--spacing-xxs);
          }

          &:not(.active) {
            cursor: pointer;
          }
          &:not(.active):hover {
            background-color: var(--secondary-hover);
          }
        }
        .navGroupArrows {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 22px;
          height: 14px;
          margin: 0 var(--spacing-xxs);

          cursor: pointer;
          svg:first-child,
          svg:last-child {
            position: absolute;
            left: 0;
            transition: 0.3s;
            height: 100%;
            opacity: 0.5;
            path {
              stroke: var(--main-color);
            }
            circle {
              fill: var(--main-color);
            }
          }
          &:hover svg:first-child {
            opacity: 1;
          }
          &:hover svg:last-child {
            opacity: 0;
          }
          svg:first-child {
            opacity: 0;
          }
          svg:last-child {
            opacity: 0.5;
          }
        }
      }
      .moreItem {
        display: flex;
        color: var(--secondary);
        font-size: var(--font-size-main);
        padding: 2px var(--spacing-xs);
        cursor: pointer;
        transition: 0.3s;

        &:hover {
          color: var(--primary);
        }
      }
    }
    .iconContainer {
      cursor: pointer;
      padding: 0 var(--spacing-xs);
      svg path {
        stroke: var(--main-color);
      }
      &.disabled {
        opacity: 0.3;
        cursor: default;
      }
    }
    .footerInfo {
      margin-left: auto;
      font-size: var(--font-size-main);
      > span:first-child {
        font-weight: 500;
        padding-right: var(--spacing-xs);
        white-space: nowrap;
        opacity: 0.5;
      }
    }
  }
}

.container .inner .perPageWrapper {
  &:hover .listContainer {
    max-height: 160px;
    opacity: 1;
  }
  .listContainer {
    max-height: 0;
    overflow: hidden;
    position: absolute;
    bottom: 100%;
    right: -28px;
    padding: var(--spacing-xs);
    padding-top: var(--spacing-xxs);
    z-index: 100;
    transition: 0.3s;
    opacity: 0;
    cursor: pointer;

    ul {
      padding: var(--spacing-xs) 0;
      background-color: white;
      border-radius: var(--border-radius);
      box-shadow: var(--box-shadow);
      overflow-y: auto;
      > li {
        font-size: var(--font-size-main);
        padding: var(--spacing-xxs) var(--spacing-m);
        &:not(.active):hover {
          background-color: var(--secondary-hover);
        }
        &.active {
          background-color: var(--main-color);
          color: var(--white);
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .container .inner {
    flex-wrap: wrap;
    gap: var(--spacing-xs);
  }
  .container .inner .pagesWrapper {
    width: 100%;
    order: 3;
    padding-top: var(--spacing-m);
    padding-left: 0;
  }
}

@media (max-width: 767px) {
  .container .inner {
    padding-top: var(--spacing-xs);
    flex-wrap: wrap;
    gap: 0;
  }
}
