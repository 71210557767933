@import "@styles/mixins.scss";

.container {
  position: relative;
  margin-bottom: var(--spacing-2xl);
  animation: FadeInText 1s ease-in-out;
  &.open {
    border-bottom: 1px solid var(--main-color-separator);
    padding-bottom: var(--spacing-2xl);
  }
  .userInfoBlock {
    @include flexContainer(row, flex-start, center);
    padding: var(--spacing-xs) 5px;
    padding-left: var(--spacing-xs);
    background-color: var(--main-color-border);
    border-radius: var(--border-radius);
    cursor: pointer;
    .bgImageMenu {
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      width: 57px;
      height: 57px;
      border: 2px solid var(--secondary);
      border-radius: 50%;
      cursor: pointer;
      margin-right: var(--spacing-m);
    }
    .userInfo {
      display: flex;
      flex-direction: column;
      .name {
        padding-bottom: var(--spacing-xs);
        font-size: var(--font-size-main);
        font-family: Archivo;
        font-size: var(--font--size-main);
        font-style: normal;
        font-weight: 600;
        color: var(--main-color);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: var(--font-size-main);
      }
      .email {
        font-style: normal;
        font-weight: 400;
        font-size: var(--font-size-small);
        color: var(--main-color);
        opacity: 0.5;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
    .arrow {
      align-self: center;
      position: absolute;
      right: 10px;
    }
    &.collapsed {
      .arrowIcon {
        transform: rotate(-180deg);
      }
    }
  }

  .menuItems {
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    display: none;
    span {
      letter-spacing: 0.5px;
      font-size: 16px;
      opacity: 0.55;
      &:hover {
        opacity: 1;
      }
    }
    &.isOpen {
      display: block;
      animation: FadeIn 1s;
    }

    .menuIcon {
      display: flex;
      align-items: center;
      margin-right: var(--spacing-s);
    }

    .links {
      margin-bottom: var(--spacing-sx);
      padding-top: var(--spacing-sx);
      padding-bottom: var(--spacing-sx);
      a {
        text-decoration: none;
        color: var(--main-color);
        display: flex;
        padding: var(--spacing-sx);
        align-items: center;
        font-weight: 500;
        transition: all 0.3s;
        &:hover {
          background-color: var(--secondary-hover);
          span {
            opacity: 1;
          }
        }
      }
      .disabled {
        opacity: 0.2;
        pointer-events: none;
      }
    }
    .logout {
      display: flex;
      color: var(--main-color);
      padding: var(--spacing-sx);
      align-items: center;
      cursor: pointer;
      font-weight: 500;
      transition: all 0.3s;

      &:hover {
        background-color: var(--secondary-hover);
        span {
          opacity: 1;
        }
      }
    }
  }
}

@keyframes FadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes FadeInText {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.55;
  }
}
