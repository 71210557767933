.container {
  width: 100%;
  background-color: var(--white);
  border-radius: var(--border-radius);
  box-shadow: var(--base-shadow);
  padding: var(--spacing-m);
  min-height: 150px;
}

.cellWrapper {
  cursor: default;
}

.withTag {
  display: inline-block;
  padding: 4px 8px;
  border: 1px solid var(--main-color-separator);
  border-radius: 6px;
  min-width: 62px;
}
.text {
  width: 100%;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 400;
  color: var(--primary);
  &.info {
    color: var(--secondary);
    font-weight: 600;
  }
}

.passive {
  font-weight: 700;
  color: var(--primary);
}

.btnGroupContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
