.container {
  background: var(--white);
  padding: var(--spacing-m);
  border-radius: var(--border-radius);
  margin-top: var(--spacing-xs);
  box-shadow: var(--box-shadow);

  .companyName {
    color: var(--secondary);
  }
  .statusActive {
    color: var(--secondary);
  }
  .statusInactive {
    color: var(--muted-text);
  }
  .avatar {
    .bgImage {
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      width: 52px;
      height: 52px;
      border: 2px solid var(--secondary);
      border-radius: 50%;
      cursor: pointer;
    }
  }
}
