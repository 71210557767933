@import "@styles/mixins";

.container {
  --white-opacity-90: rgba(255, 255, 255, 0.8);

  @include flexContainer(column);

  position: absolute;
  inset: 0;
  padding-top: var(--header-height);
  padding-left: var(--sidenav-mobile-width);
  background-color: var(--white-opacity-90);
  height: 100%;
  height: 100dvh;
  min-height: -webkit-fill-available;
  backdrop-filter: blur(5px);
  overflow: hidden;
  transition: padding-left var(--sidenav-transition) var(--bezier-transition);
  display: none;
  z-index: 400;

  &.animate {
    display: flex;
    animation: FadeInScale 1s ease;
  }

  &.fullWidth {
    padding-left: calc(var(--side-bar-width) + 32px);
  }
  &.mobileView {
    padding-left: 0;
  }

  .inner {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center center;
    background-attachment: fixed;
    padding: var(--spacing-2xl) var(--spacing-7xl);
    overflow-y: auto;

    .wrapper {
      @include flexContainer(column, flex-start);

      width: 100%;
      background-color: var(--container-bg);
      border-radius: var(--border-radius);
      padding: var(--spacing-l);
      box-shadow: var(--box-shadow);

      .hederWrapper {
        width: 100%;
      }

      .section {
        @include flexContainer(column, flex-start, flex-start);

        width: 100%;
        height: 100%;
        background-color: var(--white);
        padding: var(--spacing-m);
        gap: var(--spacing-l);

        .topInfoTitleContainer {
          @include flexContainer(row, flex-start, flex-start);

          padding: var(--spacing-xs);
          padding-bottom: var(--spacing-l);
          border-bottom: 1px solid var(--main-border);
          width: 100%;

          .icon {
            width: 36px;
          }
          .title {
            width: 100%;
            padding: 0 var(--spacing-2xl);
            font-size: var(--font-size-medium);
            color: var(--primary);
            text-align: center;
          }
        }
        .videoContainer {
          @include flexContainer(column);

          position: relative;
          width: 100%;
          height: 100%;
          gap: var(--spacing-l);

          .videoWrapper {
            @include flexContainer;

            position: relative;
            width: 100%;
            height: 100%;
            padding: var(--spacing-l);
            border-radius: var(--border-radius);
            background-color: #000;
            overflow: hidden;

            .videoBlock {
              opacity: 1;
              transition: 0.3s;
              width: 100%;
              aspect-ratio: 16 / 9;

              &.darken {
                opacity: 0.3;
              }

              > video {
                width: 100%;
                aspect-ratio: 16 / 9;
              }
            }
            .loaderContainer {
              @include flexContainer;

              position: absolute;
              inset: 0;
              z-index: 10;
              padding: var(--spacing-2xl);
            }
            .helpTextContainer {
              @include flexContainer;

              position: absolute;
              inset: 0;
              z-index: 10;

              .helpText {
                padding: var(--spacing-2xl);
                max-width: 350px;
                background-color: var(--white);
                font-size: var(--font-size-main);
                color: var(--muted-text);
                border-radius: var(--border-radius);
                box-shadow: var(--box-shadow);
                text-align: center;
                border: 2px solid var(--secondary-lighten);
              }
            }
            .errorContainer {
              @include flexContainer;

              position: absolute;
              inset: 0;
              z-index: 10;

              .helpText {
                padding: var(--spacing-2xl);
                max-width: 350px;
                background-color: var(--white);
                font-size: var(--font-size-main);
                color: var(--error);
                border-radius: var(--border-radius);
                box-shadow: var(--box-shadow);
                text-align: center;
              }
            }
            .imagePoster {
              @include flexContainer;

              position: absolute;
              inset: 0;
              z-index: 20;
              padding: var(--spacing-l);
              opacity: 0;
              transition: 1s;

              &.show {
                opacity: 1;
              }
              > img {
                width: 100%;
                height: auto;
              }
              > svg {
                position: absolute;
                top: 20%;
                width: 35%;
                height: auto;
              }
            }
            .playButtonBlock {
              @include flexContainer;

              position: absolute;
              inset: 0;
              z-index: 20;
              padding: var(--spacing-l);
              animation: FadeInScale 0.3s ease;

              .playButton {
                border: none;
                background-color: transparent;
                width: 100%;
                height: 100%;
                transition: 0.1s;

                &:active {
                  transform: scale(0.9);
                }

                > svg {
                  width: 20%;
                  height: auto;
                }
              }
            }
          }
          .buttonWrapper {
            @include flexContainer;

            width: 100%;
            max-width: 200px;
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .container {
    .inner {
      padding: var(--spacing-2xl) var(--spacing-xs);
      .wrapper .section {
        width: calc(100% + 24px);

        .topInfoTitleContainer {
          .title {
            font-size: var(--font-size-main);
          }
        }
      }
    }
  }
}

@keyframes FadeInScale {
  0% {
    opacity: 0;
    scale: 0.7;
  }
  100% {
    opacity: 1;
    scale: 1;
  }
}
