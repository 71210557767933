@import "@styles/mixins.scss";

.container {
  @include flexContainer(row, flex-start, stretch);

  --image-height: 260px;
  gap: var(--spacing-m);

  .avatarSection {
    @include flexContainer(column, flex-start, stretch);

    flex-grow: 1;
    width: var(--image-height);
    min-width: var(--image-height);
    row-gap: var(--spacing-xs);

    .image {
      @include flexContainer(column, center, center);

      height: var(--image-height);
      border: 4px solid var(--secondary);
      border-radius: var(--border-radius);
      background: var(--white);
      width: 100%;
      span {
        font-family: Archivo;
        font-size: var(--font-size-base);
        font-weight: 400;
        color: var(--muted-text);
      }
      .bgimage {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        width: 183px;
        height: 183px;
        border: 1px solid var(--main-border);
        border-radius: 50%;
        margin-bottom: 10px;
      }
    }
  }

  @media (max-width: 1199px) {
    flex-direction: column;
    .avatarSection {
      width: 100%;
    }
  }
}

.userInfoSection {
  @include flexContainer(column, flex-start, flex-start);
  width: 100%;
  overflow: hidden;

  padding: var(--spacing-l);
  border-radius: var(--border-radius);
  background: var(--white);
  box-shadow: var(--box-shadow);
  h3 {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    margin-bottom: var(--spacing-m);

    .status {
      font-size: var(--font-size-base);
      font-weight: 500;
      color: var(--secondary);
      margin-left: var(--spacing-xs);

      &.inactive {
        color: var(--muted-text);
      }
    }
  }
  .info {
    display: flex;
    gap: var(--spacing-m);
    margin-bottom: var(--spacing-xxs);
    .label {
      font-size: var(--font-size-main);
      font-weight: 400;
      color: var(--muted-text);
    }
  }
  .infoCompany {
    display: flex;
    gap: var(--spacing-m);
    margin-top: auto;
    padding-top: var(--spacing-2xl);
    overflow: hidden;
    width: 100%;

    .label {
      font-size: var(--font-size-medium);
      font-weight: 600;
      color: var(--secondary);
    }
    span {
      font-size: var(--font-size-medium);
      &:last-child {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }
}
