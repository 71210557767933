.container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-m);
  width: 100%;

  .constructorContainer {
    width: 100%;
    display: grid;
    grid-template-columns: calc(100% / 3 * 2) calc((100% / 3 * 1) - var(--spacing-m));
    gap: var(--spacing-m);

    .wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;

      .label {
        margin-bottom: var(--spacing-xs);
      }
      .table {
        position: relative;
        width: 100%;
        height: 300px;
        border: 1px solid var(--main-border);
        border-radius: var(--border-radius);
        padding: var(--spacing-m);
        min-height: 300px;
        overflow-y: auto;
        overflow-x: hidden;
        height: 100%;

        &.error {
          border: 1px solid var(--error);
        }
        .inner {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          gap: var(--spacing-xs);
          padding-bottom: 180px;

          &[data-disabled="true"] {
            opacity: 0.6;
            pointer-events: none;
          }

          .operator {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 36px;
            height: 36px;
            border-radius: var(--border-radius);
            background-color: var(--secondary);
            color: var(--white);
          }
        }
      }
    }
    .controls {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: var(--spacing-m);

      .btnGroup {
        padding-top: var(--spacing-xs);
        width: 100%;
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;
        gap: var(--spacing-m);
      }
    }
    .status {
      width: 100%;
      display: none;
    }
  }
  .footer {
    width: 100%;
    display: flex;
    width: calc(100% / 3 * 2);
  }
}

.actionContainer {
  display: flex;
  align-items: flex-end;
  column-gap: var(--spacing-xs);
  cursor: pointer;
  color: var(--primary);
  transition: 0.3s;
  font-size: var(--font-size-base);
  margin-bottom: var(--spacing-xs);

  > svg {
    width: 18px;
    path {
      fill: var(--primary);
    }
  }

  &:hover {
    color: var(--secondary);
    > svg path {
      transition: 0.3s;
      fill: var(--secondary);
    }
  }
}

@media (max-width: 991px) {
  .container {
    .constructorContainer {
      grid-template-columns: 100%;
      .status {
        display: block;
      }
    }
    .footer {
      width: 100%;
    }
  }
}
