.container {
  width: 100%;

  .footnote {
    width: 100%;
    padding: var(--spacing-m);
    background-color: var(--white);
    border-radius: var(--border-radius);
    font-size: var(--font-size-small);
    color: var(--muted-text);

    > p:first-child {
      font-size: var(--font-size-main);
      color: var(--primary);
      margin-bottom: var(--spacing-xs);
      font-weight: 600;
    }
    > p:nth-child(2) {
      margin-bottom: var(--spacing-2xl);
    }
    .note > span {
      color: var(--secondary);
      padding-right: var(--spacing-sx);
    }
    .note:not(:last-child) {
      margin-bottom: var(--spacing-xs);
    }
  }

  .section {
    width: 100%;
    color: var(--main-color);

    &:not(:last-child) {
      margin-bottom: var(--spacing-l);
    }

    .subheading {
      font-size: var(--font-size-medium);
      margin-bottom: var(--spacing-xs);
      font-weight: 500;
    }
    .headingNote {
      font-size: var(--font-size-base);
      color: var(--secondary);
    }
  }
}

.scenarioContainer {
  --attention-color: #f1d22e;

  background-color: var(--secondary);
  border-radius: var(--border-radius);
  padding: var(--spacing-l);
  width: 100%;
  box-shadow: var(--box-shadow);

  .inner {
    display: flex;
    align-items: center;
    gap: var(--spacing-m);
    width: 100%;
    font-size: 18px;
    font-weight: 600;
    color: var(--white);

    .iconContainer {
      height: 32px;
      width: 32px;
      color: var(--attention-color);

      > svg {
        width: auto;
        height: 32px;
      }
    }
  }
}
