@import "@styles/mixins.scss";

.tableContainer {
  width: 100%;
  display: flex;
  flex-direction: column;

  .tableHeader {
    display: flex;
    justify-content: space-between;
    color: var(--muted-text);
    font-size: var(--font-size-base);
    margin-bottom: var(--spacing-xs);
    width: 100%;
    .menuInner {
      display: flex;
      align-items: center;
      .icon {
        min-width: 20px;
        min-height: 20px;
      }
      span {
        margin-left: var(--spacing-sx);
      }
    }

    > h2 {
      color: var(--secondary);
      font-size: var(--font-size-main);
      font-weight: 500;
      padding-left: var(--spacing-m);
      margin-right: var(--spacing-m);
    }
  }
  .tableWrapper {
    @include borderedContainer;
    padding: var(--spacing-m);
    width: 100%;
  }
}
