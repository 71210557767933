@import "@styles/mixins";

.container {
  --icon-size: 28px;
}

.container {
  @include flexContainer(row, flex-start, flex-start);

  width: 100%;
  padding: var(--spacing-m);
  border-radius: var(--border-radius);
  background-color: var(--white);
  column-gap: var(--spacing-m);
  box-shadow: var(--box-shadow);

  &[data-type="warning"] {
    border: 1px solid var(--warning);
    .iconWrapper {
      color: var(--warning);
    }
    .textGroup .title {
      color: var(--warning);
    }
  }

  &[data-type="info"] {
    border: 1px solid var(--secondary);
    .iconWrapper {
      color: var(--secondary);
    }
    .textGroup .title {
      color: var(--secondary);
    }
  }

  &.hasBottomSpace {
    margin-bottom: var(--spacing-l);
  }

  .iconWrapper {
    @include flexContainer(row);

    width: var(--icon-size);
    height: var(--icon-size);
    min-width: var(--icon-size);

    > svg {
      width: 100%;
      height: auto;
    }
  }

  .textGroup {
    @include flexContainer(column, flex-start, flex-start);

    width: 100%;
    row-gap: var(--spacing-xs);
    .title {
      font-size: var(--font-size-extra-medium);
      font-weight: 600;
    }
    .text {
      color: var(--main-color);
      font-size: var(--font-size-main);
      font-weight: 400;
    }
  }
}
