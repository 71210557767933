@import "@styles/mixins";

.container {
  position: relative;
  background-color: var(--white);
  padding: var(--spacing-l);
  max-width: 660px;
  width: 70%;
  border-radius: var(--border-radius);
  h2 {
    font-size: var(--font-size-extra-medium);
    font-weight: 600;
    color: var(--primary);
    margin-bottom: var(--spacing-m);
  }
  .modalFooter {
    display: flex;
    justify-content: flex-end;
    margin-top: var(--spacing-l);
    .loader {
      margin-right: var(--spacing-sx);
    }
    .buttons {
      @include flexContainer();

      button {
        margin-left: var(--spacing-m);
      }
    }
  }
  .checkboxWrapper {
    margin-top: var(--spacing-xs);
  }
}
