.container {
  position: relative;
}

.icon {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 var(--spacing-xs);
  cursor: pointer;
  z-index: 1;
}

.clear {
  position: absolute;
  z-index: 2;
  top: -4px;
  left: 20px;
  cursor: pointer;
}

.customDatePickerWrapper {
  position: absolute;
  top: -5px;
}

.customDatePickerInputHidden {
  display: none;
}

:global(.react-datepicker) {
  border: none;
  box-shadow: var(--box-shadow);
}
:global(.react-datepicker__header) {
  background-color: var(--secondary-hover);
  border: none;
}
:global(.react-datepicker-popper) {
  margin-top: var(--spacing-xxs);
}
:global(.react-datepicker__day-name),
:global(.react-datepicker__current-month) {
  color: var(--main-color);
}
:global(.react-datepicker__navigation-icon::before) {
  border-color: var(--main-color);
  border-top-width: 2px;
  border-right-width: 2px;
}
:global(.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle) {
  display: none;
}
:global(.react-datepicker__month-read-view--selected-month) {
  color: var(--main-color);
}
:global(.react-datepicker__year-read-view--selected-year) {
  color: var(--main-color);
}
:global(.react-datepicker__year-dropdown) {
  background-color: var(--white);
  width: 33%;
  color: var(--main-color);
}
:global(.react-datepicker__month-dropdown) {
  background-color: var(--white);
  color: var(--main-color);
}
:global(.react-datepicker__year-option::before) {
  border-color: var(--main-color);
  border-width: 2px 2px 0 0;
}
:global(.react-datepicker__navigation--years) {
  background: none;
  line-height: 1.7rem;
  text-align: center;
  cursor: pointer;
  padding: 0;
  border: 0.45rem solid transparent;
  z-index: 1;
  height: 20px;
  width: 10px;
  text-indent: -999em;
  overflow: hidden;
  position: relative;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

:global(.react-datepicker__navigation--years.react-datepicker__navigation--years-upcoming) {
  top: -4px;
  border-bottom-color: var(--main-color);
}

:global(.react-datepicker__navigation--years.react-datepicker__navigation--years-previous) {
  top: 4px;
  border-top-color: var(--main-color);
}
:global(.react-datepicker__year-option:hover) {
  background-color: var(--secondary-hover);
}
:global(.react-datepicker__month-option:hover) {
  background-color: var(--secondary-hover);
}
:global(.react-datepicker__month-read-view--down-arrow) {
  border-color: var(--main-color);
  border-width: 2px 2px 0 0;
}
:global(.react-datepicker__year-read-view--down-arrow) {
  border-color: var(--main-color);
  border-width: 2px 2px 0 0;
}
