.tabs {
  width: 100%;
  height: 100%;
  min-height: 300px;
  display: grid;
  grid-template-rows: min-content 1fr;

  .navWrapper {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;

    ul.nav {
      width: 100%;
      display: flex;
      align-items: flex-end;
      padding-left: 0px;
      position: relative;
      top: 2px;

      li {
        display: flex;
        align-items: center;
        width: 140px;
        padding: var(--spacing-xs) var(--spacing-m);
        list-style: none;
        text-align: center;
        cursor: pointer;
        transition: all 0.3s;
        border-top-left-radius: var(--border-radius);
        border-top-right-radius: var(--border-radius);
        border: 1px solid var(--main-color-separator);
        background: var(--white);
        transition: 0.3s;
        height: 36px;

        &:last-child {
          border: 1px dashed var(--main-color-separator);
        }
        margin-right: var(--spacing-xs);
        .tabInner {
          display: flex;
          overflow: hidden;

          span {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
          .icon {
            width: var(--spacing-18);
            height: var(--spacing-18);
            margin-right: var(--spacing-m);
          }
        }
        &:last-child {
          margin-right: 0;
        }
      }
      li.active {
        background: var(--main-color);
        color: var(--white);
        height: 100%;

        .icon {
          width: var(--spacing-18);
          height: var(--spacing-18);
          margin-right: var(--spacing-m);
          svg path {
            fill: var(--white);
          }
        }
      }
      @media (max-width: 768px) {
        width: 100%;
      }
    }
  }
  .tabContent {
    display: grid;
    grid-template-rows: min-content 1fr;
    border: 2px solid var(--main-color);
    border-radius: 0 var(--border-radius) var(--border-radius) var(--border-radius);
    padding: var(--spacing-m);
    // min-height: 500px;
    background-color: var(--white);
    height: 100%;
  }

  @media (max-width: 769px) {
    padding: 2rem 0;
  }
}
