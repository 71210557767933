.container {
  width: 100%;

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
  .wrapper {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: initial;
    border: none;
    border-radius: var(--border-radius);
    color: var(--text);
    font-size: var(--font-size-main);
    min-height: 40px;
    outline: 1px solid var(--primary);
    padding: var(--spacing-xs) var(--spacing-m);
    padding-right: var(--spacing-6xl);
    transition: 0.3s;
    width: 100%;
    z-index: 20;

    input {
      width: 100%;
      border: none;
      font-size: var(--font-size-main);
      color: var(--main-color);
      background-color: transparent;
      padding: 0 var(--spacing-xs);
    }
    input:focus {
      border: none;
      outline: none;
    }
    input::placeholder {
      color: var(--main-color);
      opacity: 0.3;
    }
    .arrowWrapper {
      display: flex;
      align-items: center;
      padding: 0 var(--spacing-m);
    }
    .clearWrapper {
      width: 24px;
      position: absolute;
      right: var(--spacing-s);
      display: flex;
      align-items: center;
      padding: 0 4px;
      cursor: pointer;
    }

    // react-date-picker styles
    :global(.react-datepicker) {
      border: none;
      box-shadow: var(--box-shadow);
    }
    :global(.react-datepicker__header) {
      background-color: var(--main-color);
      border: none;
    }
    :global(.react-datepicker__triangle) {
      left: 10% !important;
    }
    :global(.react-datepicker__header__dropdown) {
      display: flex;
      justify-content: space-between;
      padding-top: var(--spacing-m);
      padding-right: var(--spacing-m);
    }
    :global(.react-datepicker-popper) {
      margin-top: var(--spacing-xxs);
    }
    :global(.react-datepicker__day-name),
    :global(.react-datepicker__current-month) {
      color: var(--white);
      font-size: var(--font-size-main);
    }
    :global(.react-datepicker__navigation-icon::before) {
      border-color: var(--main-color);
      border-top-width: 2px;
      border-right-width: 2px;
    }
    :global(.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle) {
      display: none;
    }
    :global(.react-datepicker__month-read-view--selected-month) {
      color: var(--white);
    }
    :global(.react-datepicker__year-read-view--selected-year) {
      color: var(--white);
    }
    :global(.react-datepicker__year-dropdown) {
      background-color: var(--white);
      width: 50%;
      color: var(--main-color);
    }
    :global(.react-datepicker__month-dropdown) {
      background-color: var(--white);
      color: var(--main-color);
      width: 50%;
    }
    :global(.react-datepicker__year-option::before) {
      border-color: var(--main-color);
      border-width: 2px 2px 0 0;
    }
    :global(.react-datepicker__navigation--years) {
      background: none;
      line-height: 1.7rem;
      text-align: center;
      cursor: pointer;
      padding: 0;
      border: 0.45rem solid transparent;
      z-index: 1;
      height: 20px;
      width: 10px;
      text-indent: -999em;
      overflow: hidden;
      position: relative;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }

    :global(.react-datepicker__navigation--years.react-datepicker__navigation--years-upcoming) {
      top: -4px;
      border-bottom-color: var(--main-color);
    }

    :global(.react-datepicker__navigation--years.react-datepicker__navigation--years-previous) {
      top: 4px;
      border-top-color: var(--main-color);
    }
    :global(.react-datepicker__year-option:hover) {
      background-color: var(--secondary-hover);
    }
    :global(.react-datepicker__month-option:hover) {
      background-color: var(--secondary-hover);
    }
    :global(.react-datepicker__month-read-view--down-arrow) {
      border-color: var(--white);
      border-width: 2px 2px 0 0;
    }
    :global(.react-datepicker__year-read-view--down-arrow) {
      border-color: var(--white);
      border-width: 2px 2px 0 0;
    }
  }
  .label {
    color: var(--primary);
    opacity: 0.8;
    font-size: var(--font-size-main);
    font-weight: 400;
    margin-bottom: var(--spacing-xs);
    text-transform: capitalize;
    cursor: default;

    &.required {
      position: relative;
      padding-left: var(--spacing-s);
      &:after {
        position: absolute;
        content: "*";
        left: 0;
        top: 0.2em;
        color: var(--error);
      }
    }
  }
}
