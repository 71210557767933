.portfolioContainer {
  position: relative;
  width: calc(100% - var(--spacing-m));
  height: 96px;
  border: 1px dashed var(--main-border);
  border-radius: var(--border-radius);
  display: flex;
  align-items: center;
  justify-content: center;

  .inner {
    display: flex;
    height: 100%;
    padding: var(--spacing-m);
    color: inherit;
    justify-content: center;
    align-items: center;
  }
  .text {
    color: var(--placeholder);
    padding: 0 var(--spacing-m) 0 var(--spacing-m);
    font-style: normal;
    font-weight: 500;
    font-size: var(--font-size-base);
    word-break: break-all;
  }
  .addIcon {
    width: 48px;
    height: 48px;
    align-self: center;
    img {
      width: 100%;
      height: 100%;
    }
  }
}

.backgroundOk {
  background-color: var(--green-drop-ok);
}
.backgroundActive {
  background-color: var(--side-bar-color);
}
