.container {
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
  position: relative;
  width: 100%;
  padding: var(--spacing-m);
  background: var(--white);
  border-radius: var(--border-radius);
  border: 1px solid var(--main-color-border);
  margin-top: var(--spacing-l);
  @media (max-width: 767px) {
    flex-direction: column;
    align-items: center;
  }
  .videoSection {
    width: 100%;
    aspect-ratio: 16/9;
    max-width: 600px;
    .videoContant {
      position: relative;
      transition: all 0.3s;
      border-radius: var(--border-radius);
      overflow: hidden;
      cursor: pointer;
      background-position: center;
      background-size: cover;
      video {
        width: 100%;
        height: auto;
        transition: transform 0.3s;
      }
    }
    .noVideo {
      height: 100%;
      background-color: var(--gray);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: var(--muted-text);
      border-radius: var(--border-radius);
      span {
        margin-bottom: var(--spacing-m);
      }
    }
  }
  .uploadSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    h3 {
      font-size: var(--font-size-medium);
      font-weight: 500;
      margin-bottom: var(--spacing-2xl);
    }
    .videoName {
      width: 100%;
      max-width: 400px;
      padding: var(--spacing-xs);
      border: 1px solid var(--main-color-border);
      border-radius: var(--border-radius);
      color: var(--muted-text);
      text-align: center;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      margin-bottom: var(--spacing-m);
    }
    .buttons {
      display: flex;
      width: 100%;
      max-width: 400px;
      gap: var(--spacing-m);
      @media (max-width: 575px) {
        flex-direction: column;
        align-items: center;
      }
    }
  }
}
