@import "@styles/mixins";

.container {
  @include flexContainer(column, flex-start, flex-end);

  width: 100%;
  row-gap: var(--spacing-m);
  margin-bottom: var(--spacing-l);

  &.disabled {
    opacity: 0.1;
  }

  .row {
    @include flexContainer(row, flex-start, flex-end);

    width: 100%;
    gap: var(--spacing-m);

    .itemWrapper {
      @include flexContainer(column, flex-start, stretch);

      position: relative;
      width: 100%;
    }

    .buttonsGroup {
      @include flexContainer;

      width: 100%;
      gap: var(--spacing-m);

      .loaderWrapper {
        @include flexContainer;

        width: 100px;
      }
    }
  }
}

@media (max-width: 991px) {
  .container {
    .row {
      flex-wrap: wrap;

      .buttonsGroup {
        flex-wrap: wrap;
      }
    }
  }
}
