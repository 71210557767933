@import "@styles/mixins";

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--container-bg);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  padding: var(--spacing-l);
  animation: var(--slide-up-animation);
  min-width: 365px;
  line-height: var(--line-height-base);

  &.blackTheme {
    background-color: transparent;
    max-width: calc(var(--max-inner-content-width) + var(--spacing-l) * 2);
    margin: 0 auto;
    .heading {
      color: var(--white);
    }
    .innerContent {
      color: var(--white);
      background-color: var(--white-opacity-10);
    }
  }

  .heading {
    margin-bottom: var(--spacing-l);
  }

  .innerContent {
    width: 100%;
    background-color: var(--white);
    border-radius: var(--border-radius);
    padding: var(--spacing-m);
    color: var(--text);
    font-size: var(--font-size-main);
    height: 100%;

    > b,
    > p > b {
      display: block;
      font-size: var(--font-size-medium);
      margin-bottom: var(--spacing-xs);
    }
    .footerForm {
      margin-top: var(--spacing-4xl);
      > p:not(:last-child) {
        margin-bottom: var(--spacing-xl);
      }
      > p > a {
        text-decoration: none;
        color: var(--secondary-lighten);
      }
    }
  }

  .buttonBlock {
    @include flexContainer(row, flex-end, center);
    padding-top: var(--spacing-l);
    margin-left: auto;
  }
}
