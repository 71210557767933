.copyTextContainer {
  display: flex;
  cursor: pointer;

  &:hover {
    .copyTextImageWrapper {
      opacity: 1;
      width: 22px;

      > svg {
        width: 100%;
        height: auto;
      }
    }
  }

  .copyTextImageWrapper {
    opacity: 0;
    display: flex;
    margin-left: var(--spacing-2xl);
    transition: 0.3s;
  }
}
