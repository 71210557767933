.container {
  position: relative;
  width: 100%;
  height: 100%;
  animation: var(--slide-up-animation);

  > .inner {
    height: 100%;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;

    .preloaderContainer {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      transition: 0.3s;
      overflow: hidden;
      height: 32px;

      &.show {
        padding-top: 16px;
        max-height: 32px;
        opacity: 1;
      }
      &.hide {
        padding-top: 0;
        max-height: 0;
        opacity: 0;
      }
    }
  }

  > .inputContainer {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0 var(--spacing-7xl);
    padding-right: 0;
    margin-right: calc(var(--spacing-7xl) + var(--sidebar-width));
    z-index: 200;

    .unreaded {
      position: absolute;
      bottom: 108px;
      right: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: auto;
      min-width: 36px;
      padding: var(--spacing-xs);
      height: 36px;
      background-color: var(--secondary);
      border-radius: var(--border-radius);
      box-shadow: var(--box-shadow);
      color: var(--white);
      font-size: var(--font-size-base);
      font-weight: 600;
      margin-bottom: var(--spacing-xs);
      cursor: pointer;
    }
  }
}

@media (max-width: 575px) {
  .container {
    .inputContainer {
      padding-left: var(--spacing-xl);
      margin-right: calc(var(--spacing-xl) + var(--sidebar-width));
    }
  }
}
