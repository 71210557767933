@import "@styles/mixins";
.container {
  @include flexContainer(row, center, center);
  gap: var(--spacing-xs);
  margin-bottom: var(--spacing-s);
  width: 100%;
  .order {
    text-align: center;
    width: var(--spacing-2xl);
  }

  &.disabled {
    pointer-events: none;
    cursor: default;
    opacity: 0.4;
  }

  .colItem {
    @include flexContainer(row, flex-start, center);
    width: 93%;
    border: 1px solid var(--main-border);
    border-radius: var(--border-radius);
    cursor: pointer;
    text-decoration: none;
    color: var(--primary);
    transition: 0.3s;

    &:hover {
      background-color: var(--secondary-row-hover);
    }

    &:not(:last-child) {
      margin-bottom: var(--spacing-xs);
    }

    .col:first-child {
      width: 48px;
      min-width: 48px;
      padding: var(--spacing-m);
      cursor: move;
    }
    .col:last-child {
      padding: var(--spacing-m);
      @media (max-width: 575px) {
        width: 60px;
        min-width: 60px;
        padding: var(--spacing-sx);
      }
    }
    .col:nth-child(2) {
      @include flexContainer(row, flex-start);
      width: 100%;
      padding: var(--spacing-s) var(--spacing-m);
      margin: var(--spacing-xxs) 0;
      border-left: 1px solid var(--main-border);
      border-right: 1px solid var(--main-border);
      overflow: hidden;

      .title {
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      .type {
        color: var(--secondary);
        font-weight: 600;
        padding-left: var(--spacing-m);
        white-space: nowrap;
        text-transform: capitalize;
        margin-left: auto;
      }
    }
  }
}
