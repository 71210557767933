@import "@styles/mixins.scss";

.container {
  background-color: var(--white);
  padding: 20px 0 40px 0px;
  width: 100%;
  @include flexContainer(column);
  @include borderedContainer(var(--main-border));

  span {
    color: var(--gray-4);
    margin-top: var(--spacing-m);
  }
}
