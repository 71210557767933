.tag {
  display: flex;
  padding: var(--spacing-xxs) var(--spacing-xs);
  background-color: #f0f0f0;
  border: 1px solid #d9d9d9;
  border-radius: var(--border-radius);
  font-size: var(--font-size-base);
  margin-right: var(--spacing-xs);
  margin-bottom: var(--spacing-xs);
  cursor: pointer;
  &:hover {
    transition: all 0.3s;
    opacity: 0.9;
  }
  .deleteIcon {
    position: relative;
    padding-right: var(--spacing-l);
    margin-left: var(--spacing-xxs);
    /* Styles for the cross icon */
    &::before,
    &::after {
      content: "";
      position: absolute;
      width: 1px;
      height: 12px; /* Adjust the height as needed */
      background-color: var(--white); /* Change the color as desired */
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &:hover {
      &::before,
      &::after {
        transition: all 0.3s;
        background-color: var(--error);
      }
    }

    &::before {
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &::after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }
}

.tag-primary {
  background-color: var(--primary);
  border-color: var(--primary);
  color: var(--white);
}
