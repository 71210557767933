.container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 350px;
  min-width: calc(200px + var(--spacing-m) * 2);
  padding: var(--spacing-m);
  // border: 1px solid var(--main-border);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  background-color: white;

  .inner {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: var(--spacing-m);
    border: 1px solid var(--main-border);
    border-radius: var(--border-radius);

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: var(--spacing-m);
      padding-bottom: var(--spacing-m);

      .heading {
        color: var(--gray-5);
        font-size: var(--font-size-base);
        font-weight: 500;
        width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        padding: var(--spacing-xs) 0;
      }
    }
  }
  .loaderContainer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: var(--border-radius);
    border: 1px solid var(--main-border);

    .errorContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: var(--spacing-xxs);
      font-size: var(--font-size-base);
      color: var(--muted-text);

      > p:first-child {
        font-weight: 600;
      }
    }
  }
}
