.container {
  padding: 0 10px;
  .sectionName {
    font-size: var(--spacing-m);
    font-weight: bold;
    margin-bottom: var(--spacing-xs);
  }
  .content {
    width: 250px;
  }
  .dateRangeContainer {
    .datePickerRange {
      display: flex;
      align-items: flex-end;
      margin-bottom: var(--spacing-xs);
      .remove {
        display: flex;
        height: 40px;
        margin-left: var(--spacing-sx);
        img {
          width: 18px;
          align-self: center;
          cursor: pointer;
          transition: all 0.3s;
          &:hover {
            opacity: 0.7;
          }
        }
      }
    }
  }
}
