@import "@styles/mixins.scss";

.container {
  display: flex;
  flex-direction: column;

  .inner {
    display: flex;
    flex-direction: column;
    margin-top: var(--spacing-2xl);
  }
}
