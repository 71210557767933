@import "@styles/mixins.scss";

.container {
  @include flexContainer(column);
  height: 100%;

  .helpText {
    padding-top: var(--spacing-s);
    font-size: var(--font-size-small);
    font-weight: 500;
    color: var(--muted-text);
  }
  &.small .wrapper {
    gap: 2px;
  }
  &.small .item {
    width: 5px;
    height: 5px;
  }
}

.container .wrapper,
.wrapper {
  @include flexContainer;
  gap: 4px;
  padding: var(--spacing-xxs);
}

.container.black .wrapper {
  .item {
    background-color: var(--black-90);
  }
}
.container.white .wrapper {
  .item {
    background-color: var(--white);
  }
}

.item {
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--secondary);
  animation: Scale 1s ease-in-out infinite;
  transform: scale(0.7);
  opacity: 0.3;
}
.item:nth-child(2) {
  animation-delay: 0.125s;
}
.item:nth-child(3) {
  animation-delay: 0.25s;
}

@keyframes Scale {
  0% {
    transform: scale(0.9);
    opacity: 0.3;
  }
  25% {
    transform: scale(1.2);
    opacity: 1;
  }
  50% {
    transform: scale(0.9);
    opacity: 0.3;
  }
}

.atomContainer {
  display: flex;
  justify-content: center;
  width: 100px;
  padding: var(--spacing-m);

  .loader {
    transform: rotateZ(45deg);
    perspective: 1000px;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    color: var(--primary);
    position: relative;
  }
  .loader:before,
  .loader:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: inherit;
    height: inherit;
    border-radius: 50%;
    transform: rotateX(70deg);
    animation: 1s spin linear infinite;
  }
  .loader:after {
    color: var(--secondary);
    transform: rotateY(70deg);
    animation-delay: 0.4s;
  }

  @keyframes rotate {
    0% {
      transform: translate(-50%, -50%) rotateZ(0deg);
    }
    100% {
      transform: translate(-50%, -50%) rotateZ(360deg);
    }
  }

  @keyframes rotateccw {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
      transform: translate(-50%, -50%) rotate(-360deg);
    }
  }

  @keyframes spin {
    0%,
    100% {
      box-shadow: 0.2em 0px 0 0px currentcolor;
    }
    12% {
      box-shadow: 0.2em 0.2em 0 0 currentcolor;
    }
    25% {
      box-shadow: 0 0.2em 0 0px currentcolor;
    }
    37% {
      box-shadow: -0.2em 0.2em 0 0 currentcolor;
    }
    50% {
      box-shadow: -0.2em 0 0 0 currentcolor;
    }
    62% {
      box-shadow: -0.2em -0.2em 0 0 currentcolor;
    }
    75% {
      box-shadow: 0px -0.2em 0 0 currentcolor;
    }
    87% {
      box-shadow: 0.2em -0.2em 0 0 currentcolor;
    }
  }
}
