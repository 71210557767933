@import "@styles/mixins";

.container {
  margin-top: var(--spacing-m);
  h3 {
    font-size: var(--font-size-medium);
    font-weight: 500;
    margin-bottom: var(--spacing-l);
  }
}
