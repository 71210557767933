@import "@styles/mixins.scss";

.tabsContainer {
  @include flexContainer(row, flex-start, flex-start);
  flex-flow: wrap;
  gap: var(--spacing-xs);
  padding-bottom: var(--spacing-m);

  .tabItem {
    @include flexContainer(row, center, center);

    min-width: 170px;
    max-width: 250px;
    width: min-content;
    padding: 0px 16px;
    height: 40px;

    border: 1px solid var(--main-color-separator);
    border-radius: var(--border-radius);
    cursor: pointer;
    user-select: none;
    color: var(--muted-text);
    background-color: var(--white);
    transition: 0.3s;

    &.disabled {
      cursor: no-drop;
      opacity: 0.5;
    }

    &.active {
      background-color: var(--primary);
      color: var(--white);
      box-shadow: var(--box-shadow);
      .tabInner > .icon {
        svg path {
          fill: var(--white);
        }
      }
    }
    &:not(.active):hover {
      color: var(--primary);
    }
    .tabInner {
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      width: 100%;
      .icon {
        display: flex;
        align-items: center;
        margin-right: var(--spacing-xs);
        width: 24px;

        > svg {
          width: 100%;
          height: auto;
        }
      }
      span {
        font-size: var(--font-size-main);
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }
}

.tabContent {
  @include borderedContainer(transparent);
  @include flexContainer(column, flex-start, stretch);

  flex-grow: 1;
  border-radius: var(--border-radius);
  padding: var(--spacing-m);
  min-height: 500px;
  background-color: var(--white);
  height: 100%;
  width: 100%;
  box-shadow: var(--box-shadow);

  .inner {
    @include flexContainer(column, flex-start, stretch);

    width: 100%;
    flex-grow: 1;
    min-height: 100%;
    animation: var(--fade-in-default);
  }
}

.tabWrapper {
  @include flexContainer(column, flex-start, stretch);

  flex-grow: 1;
  min-height: 500px;
  height: 100%;

  .inner {
    @include flexContainer(column, flex-start, stretch);

    flex-grow: 1;
    width: 100%;
    min-height: 100%;
    animation: var(--fade-in-default);
  }
}

@media (max-width: 575px) {
  .tabsContainer {
    .tabItem {
      width: 100%;
    }
  }
}
