.container {
  padding: 0 10px;
  .sectionName {
    font-size: var(--spacing-m);
    font-weight: bold;
    margin-bottom: var(--spacing-xs);
  }
  .dateDimensions {
    .tag {
      padding: var(--spacing-m) var(--spacing-xs);
      background-color: var(--gray);
    }
  }
  .content {
    width: 250px;
  }
}
