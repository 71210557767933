.container {
  width: 100%;
  &.marginBottom {
    margin-bottom: var(--spacing-l);
  }

  &[data-readonly="true"] {
    > textarea {
      color: var(--readonly);
      pointer-events: none;
      cursor: default;
    }
  }

  &.themed {
    > textarea {
      background-color: var(--white-opacity-10);
      outline: none;
      border: 1px solid transparent;
      color: var(--white);

      &::placeholder {
        color: var(--white-opacity-30);
      }
      &:focus {
        border: 1px solid var(--white);
      }
    }
    &.error {
      > textarea {
        border-color: var(--error-light);
      }
    }
    .errorText {
      color: var(--white);
    }
  }

  &.error {
    > textarea {
      outline-color: transparent;
      border: 2px solid var(--error);
    }
  }
  .errorText {
    color: var(--error);
    font-size: var(--font-size-base);
    font-weight: 500;
    padding: 0 var(--spacing-m);
    animation-name: bounceInRight;
    animation-duration: 0.5s;
    animation-fill-mode: both;
  }

  .label {
    margin-bottom: var(--spacing-xs);
    color: var(--primary);
    opacity: 0.8;
  }
  > textarea {
    width: 100%;
    height: 120px;
    border: 1px solid var(--primary);
    border-radius: var(--border-radius);
    padding: var(--spacing-m);
    font-size: var(--font-size-main);
    font-family: Inter, Arial, san-serif;
    resize: none;
    outline-color: var(--primary);
    color: var(--primary);

    &::placeholder {
      color: var(--muted-text);
    }
  }

  &[data-disabled="true"] {
    > textarea {
      opacity: 0.5;
      pointer-events: none;
      cursor: default;
    }
  }
}

@keyframes bounceInRight {
  0%,
  60%,
  75%,
  90%,
  100% {
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(3000px, 0, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0);
  }

  75% {
    transform: translate3d(10px, 0, 0);
  }

  90% {
    transform: translate3d(-5px, 0, 0);
  }

  100% {
    transform: none;
  }
}
