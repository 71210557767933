.tableContainer {
  width: 100%;
  display: flex;
  flex-direction: column;

  .tableHeader {
    display: flex;
    align-items: flex-end;
    color: var(--muted-text);
    font-size: var(--font-size-base);
    margin-bottom: var(--spacing-xs);

    > h2 {
      color: var(--secondary);
      font-size: var(--font-size-main);
      font-weight: 500;
      padding-left: var(--spacing-m);
    }
  }
  .tableWrapper {
    width: 100%;
  }
}
