@import "@styles/mixins.scss";

.container {
  @include flexContainer(row, flex-start, center);

  --icon-width: 28px;
  --icon-small-width: 24px;
  --icon-ext-width: 36px;

  width: 100%;
  column-gap: var(--spacing-m);
  padding: var(--spacing-xs) var(--spacing-m);
  border-radius: var(--border-radius);
  border: 1px solid var(--main-border);
  transition: 0.1s;
  cursor: pointer;
  opacity: 0;
  pointer-events: none;

  &.visible {
    opacity: 1;
    animation: FadeInScale 0.3s ease-in-out;
    pointer-events: all;
  }

  &:hover {
    background-color: var(--secondary-row-hover);
    transform: scale(0.995);

    > .rightIcon {
      transform: scale(1);
      opacity: 1;
    }
  }

  .leftIcon,
  .rightIcon {
    width: var(--icon-width);
    min-width: var(--icon-width);
    height: auto;

    > svg {
      width: 100%;
      height: auto;
    }
  }
  .rightIcon {
    padding-left: var(--spacing-m);
    width: var(--icon-ext-width);
    min-width: var(--icon-ext-width);
    border-left: 1px solid var(--main-color-border);
    cursor: pointer;

    transition: 0.1s;
    transform: scale(0.8);
    opacity: 0;

    > svg {
      transition: 0.1s;
      opacity: 0.3;
    }

    &:hover {
      > svg {
        transform: scale(1.1);
        opacity: 1;
      }
    }
  }
  .textGroup {
    @include flexContainer(row, space-between, center);

    width: 100%;
    overflow: hidden;
    column-gap: var(--spacing-m);
    row-gap: var(--spacing-xs);

    .text {
      display: flex;
      align-items: center;
      color: var(--muted-text);
      font-size: var(--font-size-main);
      column-gap: var(--spacing-xs);
      white-space: nowrap;
      overflow: hidden;

      > span {
        white-space: nowrap;
        color: var(--primary);
      }

      &:nth-child(1) {
        overflow: hidden;
        width: 100%;

        > span {
          overflow: hidden;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        &::before {
          content: attr(data-index) " " attr(data-label);
        }
      }
      &:nth-child(2) {
        overflow: unset;
      }
    }
  }
}

@media (max-width: 991px) {
  .container {
    .textGroup {
      flex-direction: column;
      align-items: flex-start;

      .text {
        font-size: var(--font-size-base);

        &:nth-child(2) {
          font-size: var(--font-size-small);
          font-weight: 400;
        }
        &:first-child {
          &::before {
            content: attr(data-index);
          }
        }
      }
    }
    .leftIcon {
      width: var(--icon-small-width);
      min-width: var(--icon-small-width);
    }
    .rightIcon {
      opacity: 1;
    }
  }
}

@keyframes FadeInScale {
  0% {
    opacity: 0;
    transform: scale(0.95);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
