@import "@styles/mixins";

.container {
  @include flexContainer(column);

  width: 100%;
  min-height: 80vh;
  width: 100%;
}

.heading {
  font-size: var(--font-size-large);
  font-weight: 500;
  color: var(--error);
  margin-top: var(--spacing-2xl);
  margin-bottom: var(--spacing-l);
}
.separator {
  width: 100%;
  border-bottom: 1px solid var(--primary);
  margin-bottom: var(--spacing-l);
  opacity: 0.3;
}
.subheading {
  display: flex;
  justify-content: center;
  gap: var(--spacing-xs);
  color: var(--primary);
}
.subheading > a {
  text-decoration: none;
  color: var(--secondary);
}
