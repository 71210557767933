.strategyItem {
  display: flex;
  align-items: center;
  padding: var(--spacing-m);
  background-color: var(--white);
  border: 1px solid var(--main-hover);
  border-radius: var(--border-radius);
  margin-bottom: var(--spacing-xs);
  svg {
    margin-right: var(--spacing-m);
  }
  table {
    width: 100%;
    color: var(--main-color);
    margin-right: var(--spacing-m);
  }
  th {
    padding: 4px 8px;
    background-color: var(--secondary-hover);
    font-weight: 600;
    font-size: var(--font-size-main);
    text-align: start;
    border-radius: var(--border-radius);
  }
  td {
    padding: 4px 8px;
    font-weight: 400;
    font-size: var(--font-size-base);
  }
  @media (max-width: 768px) {
    flex-direction: column;
    svg {
      margin-bottom: var(--spacing-m);
    }
  }
}
