@import "@styles/mixins";

.logoContainer {
  @include flexContainer;
  width: 100%;
  height: 100%;
  animation: var(--fade-bounce-in-right) 0.5s ease-in-out;
}

.sliderContainer {
  position: relative;
  @include flexContainer(column, center, center);
  width: 80%;
  height: 100%;
  animation: var(--fade-in) 1s ease-in-out;

  --max-slide_width: 800px;

  .slideWrapper {
    width: calc((100vw / 2) * 0.8);
    max-width: var(--max-slide_width);
    overflow: hidden;

    .slideInner {
      @include flexContainer(row, flex-start, flex-start);
      display: inline-flex;
      transition: 0.3s;

      .slideItemContainer {
        @include flexContainer(column, flex-start, center);
        width: calc((100vw / 2) * 0.8);
        max-width: var(--max-slide_width);
        padding: 0 var(--spacing-l);

        > h2 {
          width: 100%;
          font-size: 42px;
          color: var(--primary);
          font-weight: 600;
          margin-bottom: var(--spacing-l);
          align-self: flex-start;
          text-align: center;
        }

        .descriptionBox {
          padding: var(--spacing-4xl);
          background-color: var(--secondary);
          border-radius: 16px;
          width: 100%;
          margin-bottom: var(--spacing-9xl);
          box-shadow: 0 8px 16px rgba(0, 0, 0, 25%);

          .descriptionText {
            width: 100%;
            color: var(--white);
            font-size: var(--font-size-extra-medium);
            font-weight: 500;
            line-height: 1.44;
            text-align: center;
          }
        }
        .imageWrapper {
          @include flexContainer;
          width: 80%;

          > img {
            width: 300px;
            height: auto;
          }
        }
      }
    }
  }

  .slideNavContainer {
    position: absolute;
    bottom: 60px;
    @include flexContainer;
    width: 100%;

    .navBullet {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: 1px solid var(--primary);
      margin-right: var(--spacing-m);
      transition: 0.3s;
      cursor: pointer;

      &.active {
        border: none;
        background-color: var(--primary);
      }
    }
  }
}

@media (max-width: 767px) {
  .sliderContainer {
    padding-top: var(--spacing-6xl);

    .slideWrapper {
      width: calc(80vw);

      .slideInner {
        .slideItemContainer {
          width: calc(80vw);

          > h2 {
            font-size: 32px;
          }
          .descriptionBox {
            .descriptionText {
              font-size: var(--font-size-medium);
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1920px) {
  .sliderContainer {
    --max-slide_width: 600px;

    .slideWrapper {
      .slideInner {
        .slideItemContainer {
          > h2 {
            font-size: 32px;
          }
          .descriptionBox {
            .descriptionText {
              font-size: var(--font-size-medium);
            }
          }
        }
      }
    }
  }
}

@media (max-width: 575px) {
  .sliderContainer {
    width: 100%;
    --max-slide_width: 600px;

    .slideWrapper {
      width: 100vw;

      .slideInner {
        .slideItemContainer {
          width: 100vw;

          > h2 {
            font-size: 28px;
          }
          .descriptionBox {
            padding: var(--spacing-l);
            .descriptionText {
              font-size: var(--font-size-main);
            }
          }

          .imageWrapper {
            > img {
              width: auto;
              max-height: 170px;
            }
          }
        }
      }
    }
  }
}
