.container {
  position: relative;
  background-color: var(--white);
  padding: var(--spacing-l);
  max-width: 500px;
  width: 70%;
  border-radius: var(--border-radius);

  .close {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    width: 50px;
    height: 50px;
    font-size: 0;
    cursor: pointer;
    z-index: 50;

    &:before,
    &:after {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 2px;
      height: 25px;
      background-color: var(--gray-3);
      transform: rotate(45deg) translate(-50%, -50%);
      transform-origin: top left;
      transition: all 420ms;
      content: "";
    }
    &:after {
      transform: rotate(-45deg) translate(-50%, -50%);
    }
    &:hover:before,
    &:hover:after {
      background-color: var(--primary);
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    margin-top: var(--spacing-l);
  }
  .button {
    align-self: flex-end;
  }
  @media (max-width: 768px) {
    min-width: 400px;
    width: 100%;
  }
}
