@import "@styles/mixins.scss";

.avatar {
  --image-width: 52px;

  &.wrapped {
    @include flexContainer();

    flex-grow: 1;
    padding: var(--spacing-l);
    border-radius: var(--border-radius);
    border: 1px solid var(--main-border);
    background-color: var(--white);
    box-shadow: var(--box-shadow);
    --image-width: 180px;
  }
  .bgImage {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: var(--image-width);
    height: var(--image-width);
    border: 2px solid var(--secondary);
    border-radius: 50%;

    &.inactive {
      filter: grayscale(1);
      opacity: 0.5;
    }
  }
}
