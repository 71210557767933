.container {
  .label {
    display: flex;
    align-items: center;
    cursor: pointer;
    width: max-content;
    max-width: 100%;

    .labelText {
      padding-left: var(--spacing-xs);
      width: 100%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      color: var(--primary);
      opacity: 0.8;
    }
    .input {
      position: absolute;
      left: 0;
      right: 0;
      width: 1px;
      height: 1px;
      opacity: 0;
    }
    .input:checked ~ .customButton:before {
      background-color: var(--primary);
    }
    .customButton {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      width: 22px;
      height: 22px;
      min-width: 22px;
      border: 1px solid var(--primary);
      border-radius: 50%;
    }
    .customButton::before {
      position: absolute;
      content: "";
      width: 12px;
      height: 12px;
      background-color: transparent;
      border-radius: 50%;
      z-index: 1;
      transition: 0.3s;
    }
  }
  &.disabled {
    opacity: 0.3;
    pointer-events: none;
  }
}
