.ActionsMenuContainer {
  position: relative;
  cursor: pointer;
  align-self: center;
  max-width: 250px;
  // min-width: 150px;
  .menu.isOpen {
    animation: fadeIn 0.3s;
    display: block;
    @keyframes fadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  }

  .childrenContainer {
    font-size: var(--spacing-m);
  }

  .content {
    display: flex;
    justify-content: space-between;
    gap: var(--spacing-m);

    img {
      transition: 0.3s;
      transform: rotate3D(1, 0, 0, 180deg);
    }
    img.isOpen {
      transform: rotate3D(1, 0, 0, 0deg);
    }
  }

  .menu {
    position: absolute;
    top: 25px;
    right: 0;
    display: none;
    z-index: 12;
    box-shadow: var(--box-shadow);
    min-width: 180px;
    max-width: 350px;
    width: max-content;

    .listWrapper {
      overflow-y: auto;
      max-height: 250px;
      padding: var(--spacing-xs);
      background: var(--white);
      border-radius: var(--border-radius);
      // border: 1px solid var(--main-color-separator);

      ul {
        list-style: none;
        padding-left: 0;

        li {
          cursor: pointer;
          display: flex;
          align-items: center;
          padding: var(--spacing-xs) var(--spacing-m);
          user-select: none;
          transition: 0.3s;

          img {
            margin-right: var(--spacing-xs);
            width: 22px;
            height: 22px;
          }
          .text {
            font-weight: 500;
            font-size: var(--font-size-base);
            color: var(--main-color);
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            animation: ticker-scroll 20s linear infinite;
          }
          &:hover {
            background-color: var(--secondary-hover);
          }
        }
      }
    }
  }
}
