@import "@styles/mixins.scss";

.container {
  @include flexContainer(column, flex-start, flex-start);

  flex-grow: 1;
  background-color: var(--container-bg);
  box-shadow: var(--box-shadow);
  width: 100%;
  min-height: var(--inner-content-min-height);
  border-radius: var(--border-radius);

  .inner {
    @include flexContainer(column, flex-start, flex-start);

    width: 100%;
    flex-grow: 1;
    padding: var(--spacing-l);
    border-radius: var(--border-radius);
    animation: var(--slide-up-animation);

    .content {
      @include flexContainer(column, flex-start, flex-start);

      flex-grow: 1;
      width: 100%;
      row-gap: var(--spacing-2xl);
      border-radius: var(--border-radius);
      background-color: var(--white);
      box-shadow: var(--box-shadow);
      padding: var(--spacing-m);
      position: relative;

      &.loading > .frontLoader {
        @include flexContainer(column);
        opacity: 1;
      }

      .frontLoader {
        position: absolute;
        inset: 0;
        display: none;
        opacity: 0;
        background-color: var(--white);
        border-radius: var(--border-radius);
        z-index: 10;
      }

      .heading {
        @include flexContainer(row, space-between, center);

        width: 100%;
        font-weight: 600;
      }

      .listWrapper {
        @include flexContainer(column, flex-start, flex-start);

        width: 100%;
        flex-grow: 1;
        row-gap: var(--spacing-m);

        .list {
          @include flexContainer(column, flex-start, flex-start);

          width: 100%;
          flex-grow: 1;
          list-style: none;
          padding: 0;
          row-gap: var(--spacing-xs);

          &.disabled {
            pointer-events: none;
            opacity: 0.3;
          }
        }
      }
      .pagination {
        @include flexContainer(column, flex-start, flex-start);

        width: 100%;
      }

      .noDataContainer {
        @include flexContainer(column, center);

        width: 100%;
        flex-grow: 1;
      }
    }
  }
}
