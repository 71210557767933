.container {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;

  .diasbleContainer {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: var(--white);
    opacity: 0.5;
    z-index: 10;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: var(--spacing-m);

  &:not(:last-child) {
    padding-bottom: var(--spacing-l);
  }
  &:last-child .radioContainer {
    padding-bottom: 0;
  }
  .itemWrapper {
    width: calc((100% - 32px) / 3);
    flex-grow: 1;
    min-width: 270px;

    .customLabel {
      --icon-width: 24px;

      display: grid;
      grid-template-columns: calc(100% - var(--spacing-l) - var(--icon-width)) min-content;
      align-content: center;
      align-items: center;
      justify-content: space-between;
      column-gap: var(--spacing-xs);
      max-height: 1.2rem;
      overflow: hidden;
      width: 100%;
      cursor: default;

      &.disabled {
        opacity: 0.2;
        pointer-events: none;
      }

      .iconWrapper {
        display: flex;
        align-items: center;

        cursor: pointer;

        > svg {
          > path {
            transition: 0.3s;
          }
        }

        &:hover {
          > svg {
            > path {
              stroke: var(--secondary);
            }
          }
        }
      }
      .labelText {
        overflow: hidden;
        opacity: 0.8;
        text-overflow: ellipsis;
        white-space: nowrap;
        transition: 0.1s;
        margin-right: auto;

        &::after {
          content: "(Required)";
          font-size: var(--font-size-small);
          color: var(--error);
          margin-left: var(--spacing-xs);
        }
        &:hover {
          opacity: 1;
          color: var(--secondary);
        }
      }
    }
  }
  .itemsGroup {
    display: flex;
    column-gap: var(--spacing-m);
  }
  .radioContainer {
    display: flex;
    column-gap: var(--spacing-m);
    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }
    > p {
      margin-bottom: var(--spacing-m);
    }
    padding-bottom: var(--spacing-l);

    .itemWrapper {
      width: 100%;

      .title {
        margin-bottom: var(--spacing-m);
      }
    }
    .radioGroup {
      display: flex;
      align-items: center;
      gap: var(--spacing-2xl);
    }
  }
  .empty {
    width: calc((100% - 32px) / 3);
    flex-grow: 1;
    min-width: 270px;
  }
}

@media (max-width: 991px) {
  .container {
    .row {
      grid-template-columns: 1fr;
      .radioContainer {
        .radioGroup {
          margin-bottom: var(--spacing-m);
        }
      }
    }
  }
}
