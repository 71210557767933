@import "@styles/mixins";

.container {
  position: relative;
  width: 100%;

  --list-height: 405px;
  --label-item-width: 65px;
  --extended-item-width: 80px;
  --item-height: 40px;

  &.disabled:not(.customLabel) {
    .innerWrapper {
      > .labelWrapper {
        .labelInner {
          opacity: 0.2;
          pointer-events: none;
        }
      }
    }
  }
  &.disabled {
    .innerWrapper {
      > .labelWrapper {
        .inputContainer {
          opacity: 0.2;
          pointer-events: none;
        }
      }
    }
  }
  &[data-readonly="true"] {
    .innerWrapper {
      > .labelWrapper {
        .inputContainer {
          opacity: var(--readonly-opacity);
          pointer-events: none;
        }
      }
    }
  }

  .innerWrapper {
    > .labelWrapper {
      .labelInner {
        display: flex;
        justify-content: space-between;
        row-gap: var(--spacing-m);
        width: 100%;
        overflow: hidden;

        > .label {
          margin-bottom: 8px;

          > .labelText {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            text-transform: capitalize;
          }
        }
      }
      .inputContainer {
        position: relative;
        width: 100%;
        min-width: 140px;
        display: flex;
        align-items: center;

        .inputListContainer {
          width: 100%;
          display: flex;
          align-items: center;
          border: 1px solid var(--primary);
          border-radius: var(--border-radius);
          overflow: hidden;
          transition: 0.1s;

          .elementsContainer {
            display: flex;
            padding-left: var(--spacing-m);
            column-gap: var(--spacing-xxs);

            .element {
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: var(--spacing-xxs) var(--spacing-xs);
              border-radius: var(--border-radius);
              background: var(--secondary);
              color: var(--white);
              font-size: var(--font-size-small);
              font-weight: 500;
              overflow: hidden;
              width: var(--label-item-width);
              min-width: var(--label-item-width);
              transition: 0.1s;
              cursor: default;

              &.extended {
                width: var(--extended-item-width);
                min-width: var(--extended-item-width);
              }

              &:not(.extended):hover {
                background: var(--error);
              }

              .labelText {
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
              }

              .removeItem {
                padding-left: var(--spacing-xs);
                font-size: 16px;
                transition: 0.2s;
                cursor: pointer;
                opacity: 0.5;
                max-height: 15px;
                position: relative;
                bottom: 3px;

                &:hover {
                  opacity: 1;
                }
              }
            }
          }
        }

        .iconContainer {
          display: flex;
          align-items: center;
          position: absolute;
          right: var(--spacing-m);
          opacity: 0;
          z-index: 5;

          &.clear {
            right: var(--spacing-4xl);
            left: auto;
            opacity: 1;
            cursor: pointer;
          }
          > svg {
            width: 16px;
          }
        }

        .input {
          width: 100%;
          min-width: 120px;
          height: var(--item-height);
          padding: 9px 8px;
          padding-right: var(--spacing-9xl);
          font-size: var(--font-size-main);
          color: var(--main-color);
          font-family: Inter, sans-serif;
          background-color: transparent;
          border: none;

          &::placeholder {
            color: var(--muted-text);
          }
          &:focus {
            outline: none;
          }
        }

        .loaderContainer {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          border: 1px solid var(--main-color);
          border-radius: 4px;
          padding-right: 48px;
          padding-left: var(--spacing-4xl);
          height: var(--item-height);
        }
      }
    }
  }
}

.label {
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
  position: relative;
  color: var(--primary);
  font-size: var(--font-size-main);
  margin-bottom: var(--spacing-xs);

  &.required::after {
    content: "(Required)";
    font-size: var(--font-size-small);
    color: var(--error);
  }
  .labelIcon {
    max-height: 22px;
    cursor: pointer;
  }
  .labelText {
    opacity: 0.8;
  }
}

.selected {
  color: var(--primary);
}

.listContainer {
  position: absolute;
  left: 0;
  top: calc(100%);
  width: 100%;
  max-height: 200px;
  padding-top: 4px;
  max-height: 0;
  z-index: 50;
  transition: max-height, opacity 0.3s;
  opacity: 0;
  overflow: hidden;
  pointer-events: none;

  &.reversePosition {
    top: unset;
    bottom: calc(100% - 1em);
  }
  &.withoutLabel {
    bottom: calc(100% + var(--spacing-m));
  }
}
.container.open .listContainer {
  opacity: 1;
  max-height: var(--list-height);
  overflow: unset;
  pointer-events: all;
}

.container.loading .innerWrapper > .labelWrapper .inputContainer .iconContainer.clear {
  display: none;
}

.listContainer .wrapper {
  width: 100%;
  box-shadow: var(--box-shadow);
  min-height: min-content;
  max-height: var(--list-height);
  border-radius: var(--border-radius);
}
.list {
  list-style: none;
  padding: var(--spacing-xs) 0;
  background-color: var(--white);
  min-height: min-content;
  border-radius: var(--border-radius);
}
.listItem {
  display: flex;
  width: 100%;
  column-gap: var(--spacing-m);
  align-items: center;
  padding: var(--spacing-xs) var(--spacing-m);
  transition: 0.3s;
  color: var(--primary);
  overflow: hidden;
  cursor: pointer;
  border-bottom: 1px solid var(--white);
  border-radius: var(--spacing-xxs);

  .icon {
    display: flex;
    align-items: center;
    width: 18px;
    height: 18px;
    transition: 0.3s;
    opacity: 0.3;

    > svg {
      height: 100%;
      width: auto;

      > path {
        fill: var(--secondary);
      }
    }
  }
  .info {
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    min-width: 90%;
  }

  &.empty {
    @include flexContainer;

    height: var(--empty-list-item-height);
    width: calc(100% - var(--spacing-xs) * 2);
    color: var(--muted-text);
    font-weight: 500;
    border-radius: var(--border-radius);
    border: 1px solid var(--main-border);
    margin: 0 var(--spacing-xs);
    cursor: default;
  }
  &.groupItem {
    color: var(--primary);
    background-color: var(--main-border);
    text-transform: uppercase;
    font-weight: 600;
    cursor: default;
  }
}
.listItem:not(.groupItem):hover {
  background-color: var(--secondary-hover);
}
.listItem.active {
  position: relative;
  background-color: var(--primary);
  color: var(--white);

  .info {
    padding-right: var(--spacing-2xl);
  }
  .info::after {
    content: "";
    position: absolute;
    right: 20px;
    display: inline-block;
    transform: rotate(45deg);
    height: 10px;
    width: 6px;
    border-bottom: 2px solid var(--white);
    border-right: 2px solid var(--white);
  }
}

.container .innerWrapper .inputContainer:before,
.container .innerWrapper .inputContainer:after {
  content: "";
  position: absolute;
  width: 7px;
  border-bottom: 2px solid var(--primary);
  border-radius: 4px;
  transform-origin: center center;
  transition: 0.3s;
}

.container .innerWrapper .inputContainer:before {
  right: 24px;
  transform: rotate(45deg);
  opacity: 1;
}

.container .innerWrapper .inputContainer:after {
  right: 20px;
  transform: rotate(-45deg);
  opacity: 1;
}

.container.open .innerWrapper .inputContainer:before {
  transform: rotate(-45deg);
  opacity: 0;
}

.container.open .innerWrapper .inputContainer .iconContainer {
  opacity: 0.5;
}

.container.open .innerWrapper .inputContainer:after {
  transform: rotate(45deg);
  opacity: 0;
}

.container.open .innerWrapper .inputContainer .inputListContainer {
  outline: 1.5px solid var(--primary);
}

.innerWrapper .inputContainer .inputListContainer:has(.input:focus) {
  outline: 1.5px solid var(--primary);
}
