@import "@styles/mixins.scss";

.container {
  margin-bottom: var(--spacing-2xl);
  // margin-top: var(--spacing-2xl);
  .heading {
    font-size: var(--font-size-medium);
    font-weight: 600;
    margin-bottom: var(--spacing-xs);
  }

  .innerContainer {
    padding: var(--spacing-l);
    border-radius: var(--border-radius);
    border: 1px solid var(--main-color-separator);
    background-color: var(--white);

    .wrapper {
      display: flex;
    }
  }
}

.noDataContainer {
  @include borderedContainer(transparent);
  box-shadow: var(--box-shadow);
  padding: var(--spacing-l);

  .subTitle {
    font-weight: 600;
    margin-bottom: var(--spacing-xs);
  }
}
