.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  font-family: Inter, sans-serif;
  flex-grow: 1;

  .tabsNavigation {
    display: flex;
    gap: var(--spacing-xxs);

    .tabNavItem {
      position: relative;
      display: flex;
      gap: var(--spacing-m);
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: 250px;
      min-width: 50px;
      top: 1px;
      padding: var(--spacing-xs) var(--spacing-m);
      margin-top: 4px;
      height: 36px;
      color: var(--primary);
      border-top-left-radius: var(--border-radius);
      border-top-right-radius: var(--border-radius);
      border: 1px solid var(--main-color-separator);
      border-bottom: none;
      cursor: pointer;
      user-select: none;
      transition: 0.3s;

      &.active {
        background-color: var(--primary);
        color: var(--white);
        margin-top: 0;
        height: 40px;
      }
      &.active {
        background-color: var(--primary);
        color: var(--white);
        .iconContainer > svg path {
          fill: var(--white);
        }
      }

      .iconContainer {
        display: flex;
        align-items: center;
        width: 22px;
        height: auto;
      }
      .label {
        width: 100%;
        display: block;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }
  .tabActiveContainer {
    height: 100%;
    display: flex;
    flex-grow: 1;
    width: 100%;
    border-radius: var(--border-radius);
    border-top-left-radius: 0;
    border: 2px solid var(--primary);
    padding: var(--spacing-l);
    background-color: var(--white);
  }
  .loaderContainer {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    min-height: 350px;
  }
}
