.container {
  background-color: var(--secondary);
  // border: 2px solid var(--secondary);
  border-radius: var(--border-radius);
  padding: var(--spacing-l);
  width: 100%;
  box-shadow: var(--box-shadow);

  .inner {
    display: flex;
    align-items: center;
    gap: var(--spacing-m);
    width: 100%;
    font-size: var(--font-size-main);
    font-weight: 600;
    color: var(--white);

    .iconContainer {
      height: 28px;
      width: 28px;

      > svg path {
        fill: var(--white);
      }
    }
  }
}
