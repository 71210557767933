@import "@styles/mixins.scss";

.container {
  @include flexContainer(column, flex-start, flex-start);

  position: relative;
  width: 100%;
  flex-grow: 1;

  .title {
    font-size: var(--font-size-medium);
    font-weight: 500;
    color: var(--primary);
    margin-bottom: var(--spacing-m);
    width: 100%;
  }
  .dropdown {
    margin-bottom: var(--spacing-m);
    width: 100%;
  }
  .modalFooter {
    display: flex;
    justify-content: flex-end;
    margin-top: auto;
    width: 100%;

    .loader {
      margin-right: var(--spacing-sx);
    }
    .buttons {
      button {
        margin-left: var(--spacing-m);
      }
    }
  }
}
