@import "@styles/mixins";

.container {
  @include flexContainer(row);

  white-space: nowrap;
  width: min-content;
  border-radius: var(--border-radius);
  font-weight: 600;
  line-height: 1.44;

  &[data-type="primary"] {
    background-color: var(--main-color);
    color: var(--white);
  }

  &[data-type="secondary"] {
    background-color: var(--secondary);
    color: var(--white);
  }

  &[data-type="outlined"] {
    background-color: var(--white);
    border: 1px solid var(--main-color);
    color: var(--font-size-tiny);
  }

  &[data-type="grayed-out"] {
    background-color: var(--black-opacity-10);
    color: var(--black-opacity-50);
  }

  &[data-type="success"] {
    background-color: var(--success);
    color: var(--white);
  }

  &[data-type="error"] {
    background-color: var(--error);
    color: var(--white);
  }

  &[data-size="default"] {
    padding: var(--spacing-xxs) var(--spacing-s);
    font-size: var(--font-size-base);
  }

  &[data-size="medium"] {
    padding: var(--spacing-xxs) var(--spacing-xs);
    font-size: var(--font-size-small);
  }

  &[data-size="small"] {
    padding: 1px 8px;
    font-size: 11px;
  }
}
