@import "@styles/mixins.scss";

.subHeading {
  margin-bottom: var(--spacing-m);
  font-size: var(--font-size-medium);
}

.chartpreviewContainer {
  @include borderedContainer;
  padding: var(--spacing-l);
  margin-bottom: var(--spacing-2xl);
  width: 100%;

  .emptyData {
    @include flexContainer;
    @include borderedContainer(var(--main-border));
    width: 100%;
    height: 300px;
    background-color: var(--white);
    font-size: var(--font-size-main);
    color: var(--muted-text);
  }
}
.tablePreviewContainer {
  width: 100%;

  .tablePreviewWrapper {
    @include borderedContainer;
    padding: var(--spacing-l);
    width: 100%;
  }
}
