.odin-ultra-notification-container {
  position: fixed;
  top: 20px;
  left: 50vw;
  transform: translateX(-50%);
  z-index: 1000;
  max-width: 400px;
  min-width: 150px;
}
.odin-ultra-notification-inner {
  display: flex;
  align-items: center;
  gap: var(--spacing-m);
  padding: var(--spacing-m);
  margin-bottom: var(--spacing-xs);
  border-radius: var(--border-radius);
  transition: 0.3s;
  animation: SlideUpOpacity 0.3s ease-in-out;
  box-shadow: var(--box-shadow);
  cursor: pointer;
}
.odin-ultra-notification-inner > img {
  width: 22px;
  height: 22px;
}
.odin-ultra-notification-inner > p {
  color: var(--white);
  font-size: var(--font-size-base);
  text-align: center;
}

.odin-ultra-notification-inner-info {
  background-color: var(--secondary);
}
.odin-ultra-notification-inner-error {
  background-color: var(--error);
}
.odin-ultra-notification-inner-success {
  background-color: var(--success);
}
.odin-ultra-notification-inner-warning {
  background-color: var(--warning);
}

.hide {
  animation: FadeOut 1s ease-in-out;
}

@keyframes SlideUpOpacity {
  0% {
    opacity: 0;
    transform: translateY(-100px);
  }
  1000% {
    opacity: 1;
    transform: translateY(100px);
  }
}

@keyframes FadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
