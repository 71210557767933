.inputContainer {
  padding: var(--spacing-m);
  background-color: var(--secondary-hover);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  border: 1px solid var(--secondary);

  .wrapper {
    .buttonWrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: var(--spacing-xs);
    }
  }
}

@media (max-width: 575px) {
  .inputContainer {
    .wrapper {
      flex-wrap: wrap;

      .buttonWrapper {
        margin-left: auto;

        button > span {
          display: none;
        }
        button > img {
          margin: 0;
        }
      }
    }
  }
}
