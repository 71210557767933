@import "@styles/mixins";

.container {
  @include flexContainer(column, flex-start, flex-start);
  flex: 1;
  min-height: 100%;

  .headerInfo {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: var(--spacing-l);
    background-color: var(--secondary);
    border-radius: var(--border-radius);
    margin-bottom: var(--spacing-2xl);
    box-shadow: var(--box-shadow);

    .inner {
      color: var(--white);
      font-size: var(--font-size-large);
      font-weight: 600;
    }
  }

  .sectionWrapper {
    @include borderedContainer(transparent);

    width: 100%;
    padding: var(--spacing-l);
    box-shadow: var(--box-shadow);

    .innerContent {
      padding: var(--spacing-m);
      background-color: var(--white);
      border-radius: var(--border-radius);
      min-height: 50vh;
      width: 100%;
    }
  }
}
