@import "@styles/mixins.scss";

.heading {
  font-size: var(--font-size-extra-medium);
  margin-bottom: var(--spacing-2xl);
  color: var(--secondary);
}
.subHeading {
  margin-bottom: var(--spacing-m);
}
.goBack {
  margin-bottom: var(--spacing-xl);
}
.saveBlock {
  @include flexContainer(row, space-between, flex-end);
  margin-top: var(--spacing-xs);
  margin-bottom: var(--spacing-l);
  .saveButtonBlock {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .loader {
      margin-right: 39px;
    }
  }
  .title {
    font-size: var(--font-size-main);
    color: var(--secondary);
    font-weight: 500;

    > span {
      color: var(--muted-text);
    }
  }
}

.container {
  position: relative;
  padding: var(--spacing-l);
  border: 1px solid var(--border-base-color);
  border-radius: var(--border-radius);
  @include borderedContainer;

  .headingContainer {
    @include flexContainer(row, space-between, center);
    margin-bottom: var(--spacing-m);

    .head {
      display: flex;
      svg {
        margin-right: var(--spacing-m);
        path {
          fill: var(--primary);
        }
      }
      .heading {
        font-size: var(--font-size-medium);
        color: var(--primary);
        margin-bottom: 0;
      }
    }
  }
  .pagination {
    margin-top: var(--spacing-2xl);
  }
}
.checkbox {
  white-space: nowrap;
  &:not(:first-child) {
    margin-left: var(--spacing-m);
  }
}
.loader {
  @include flexContainer();
  @include borderedContainer;
  width: 100%;
  padding: var(--spacing-4xl);
  background-color: var(--white);
  border-radius: var(--border-radius);
  min-height: 300px;
}
