.noDataContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--gray-1);
}
.noDataContainer > p {
  font-size: var(--font-size-base);
  color: var(--muted-text);
}
.noDataIconWrapper {
  width: auto;
  height: 64px;
}
.noDataIconWrapper > img {
  height: 100%;
  width: auto;
}
