@import "@styles/mixins";

.container {
  --max-form-width: 550px;

  overflow: hidden;
}

.container {
  @include flexContainer(column, flex-start, flex-start);

  width: 100%;
  flex-grow: 1;
  color: var(--white);
  padding: var(--spacing-4xl);
  padding-bottom: 0;

  .inner {
    @include flexContainer(column, center);

    width: 100%;
    row-gap: var(--spacing-4xl);
    flex-grow: 1;
    max-width: var(--max-inner-content-width);
    margin: 0 auto;
    animation: var(--slide-up-animation);

    .formContainer {
      @include flexContainer(column, flex-start, center);

      width: 100%;
      row-gap: var(--spacing-l);
      background-color: var(--white-opacity-10);
      padding: var(--spacing-l);
      border-radius: var(--border-radius);
      max-width: 650px;

      > h1 {
        text-align: center;
        font-size: var(--font-size-large);
        font-weight: 600;
      }
      > p {
        text-align: center;
      }
      .mutedText {
        opacity: 0.4;
      }
      .btnWrapper {
        @include flexContainer(row, flex-start, stretch);

        width: 100%;
        max-width: 70%;
        flex-wrap: wrap;
        gap: var(--spacing-l);
      }
    }
  }
}

.container {
  &.inverseTheme {
    padding: var(--spacing-l);
    height: calc(100vh - var(--header-height));
    height: calc(100dvh - var(--header-height));
    color: var(--primary);

    .inner {
      .formContainer {
        background-color: var(--white);
      }
    }
  }
}
