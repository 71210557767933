@import "@styles/mixins";

.container {
  .hederRow {
    @include flexContainer(row, flex-start, center);
    margin-bottom: var(--spacing-m);

    > span {
      color: var(--muted-text);
      font-weight: 500;
      padding-left: var(--spacing-m);
    }
    > span:first-child {
      width: 76px;
      min-width: 76px;
    }
    > span:nth-child(2) {
      width: 100%;
    }
    > span:nth-child(3) {
      width: 125px;
      min-width: 125px;
    }
  }
  .listContainer {
    width: 100%;

    .itemRow {
      @include flexContainer(row, flex-start, center);
      width: 100%;
      border: 1px solid var(--main-border);
      border-radius: var(--border-radius);
      cursor: pointer;
      text-decoration: none;
      color: var(--primary);
      transition: 0.3s;

      &.disabled {
        cursor: default;

        .col:not(.active) > .title,
        .col:not(.active) > svg {
          opacity: 0.3;
        }
      }

      &:hover {
        background-color: var(--secondary-row-hover);
      }

      &:not(:last-child) {
        margin-bottom: var(--spacing-xs);
      }

      .col:first-child {
        width: 76px;
        min-width: 76px;
        padding: var(--spacing-m);
      }
      .col:nth-child(2) {
        @include flexContainer(row, flex-start);
        width: 100%;
        padding: var(--spacing-s) var(--spacing-m);
        margin: var(--spacing-xxs) 0;
        border-left: 1px solid var(--main-border);
        border-right: 1px solid var(--main-border);
        overflow: hidden;

        .title {
          width: 100%;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
    }
  }
}
