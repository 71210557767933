@import "@/styles/mixins";

.container {
  position: relative;
  margin-top: var(--spacing-xs);
  padding-top: var(--spacing-xxs);
  height: var(--sidenav-link-height);
  width: 100%;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    background-color: var(--secondary-hover);
  }

  &:hover > .menuItem > .itemText {
    color: var(--primary);
  }

  &.collapsed {
    .menuItem {
      .iconWrapper {
        width: 32px;
        min-width: 32px;
        padding-left: 0;
        margin-left: 24px;
      }
      .itemText {
        overflow: hidden;
        width: 8px;
      }
    }
  }

  .menuItem {
    @include flexContainer(row, flex-start, center);
    padding-left: 2px;

    .iconWrapper {
      width: 40px;
      min-width: 40px;
      padding-left: 8px;

      > svg {
        width: 100%;
        height: auto;
      }
    }
    .itemText {
      color: var(--muted-text);
      font-size: var(--font-size-main);
      font-weight: 500;
      padding-left: var(--spacing-xs);
    }
  }
}
