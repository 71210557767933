.pieChartContainer,
.defaultChartContainer {
  display: grid;
  grid-template-columns: minmax(450px, 50%) minmax(450px, 50%);
  grid-gap: var(--spacing-m);
}
.chartPalceholder {
  padding: var(--spacing-xxl);
  background-color: var(--white);
  border-radius: var(--border-radius);
  box-shadow: var(--base-shadow);
  min-height: 150px;
}

@media (max-width: 1920px) {
  .pieChartContainer,
  .defaultChartContainer {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-m);
  }
}
