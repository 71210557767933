@import "@styles/mixins.scss";

.container {
  @include flexContainer(column);

  --icon-width: 180px;

  flex-grow: 1;
  width: 100%;
  row-gap: var(--spacing-10xl);
  background-color: var(--white);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  padding: var(--spacing-m);

  .messageContainer {
    @include flexContainer(column);

    row-gap: var(--spacing-l);

    .iconWrapper {
      width: var(--icon-width);

      > svg {
        width: 100%;
        height: auto;
      }
    }
    > p {
      text-align: center;
      font-size: var(--font-size-medium);
      color: var(--muted-text);
    }
  }
}
