.filterContainer {
  position: absolute;
  top: 50px;
  right: 0;
  width: 330px;
  height: auto;
  background-color: white;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  z-index: 999;
  padding: 20px;
  .label {
    font-size: var(--font-size-main);
    font-weight: 600;
    margin-bottom: 10px;
  }
  .checkbox {
    margin-top: 10px;
  }
}
