.container {
  --avatar-container-width: 52px;

  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  // justify-content: space-between;
  background-color: var(--white);
  box-shadow: var(--box-shadow-header);
  height: var(--header-height);
  padding: 10px var(--spacing-m);
  transition: var(--sidenav-transition) var(--bezier-transition);
  z-index: 310;

  .itemsGroup {
    display: flex;
    align-items: center;
    gap: var(--spacing-2xl);
    width: var(--avatar-container-width);
    min-width: var(--avatar-container-width);

    .resources {
      color: var(--primary);
      opacity: 0.55;
      margin-right: var(--spacing-7xl);
      font-weight: 500;
      cursor: pointer;
      transition: 0.3s;

      &:hover {
        opacity: 1;
      }
    }
  }
  .breadCrumbs {
    display: flex;
    align-items: center;
    margin-right: auto;
    padding-left: var(--spacing-10xl);
    padding-right: var(--spacing-2xl);
    color: var(--secondary);
    font-weight: 500;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: var(--font-size-base);

    > p:not(:last-child) {
      padding: 0 var(--spacing-xs);
    }
    > p:first-child {
      padding-right: var(--spacing-xs);
      font-size: var(--font-size-main);
    }
    > p:last-child {
      padding-left: var(--spacing-xs);
    }
    > svg {
      opacity: 0.5;
      width: 8px;
      transform-origin: center;
      transform: rotate(90deg);
      > path {
        stroke: var(--secondary);
      }
    }
  }

  .logoContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(var(--side-bar-width) - 32px);
    a {
      text-decoration: none;
    }
  }
  .burgerMenu {
    position: relative;
    width: 36px;
    height: 36px;
    padding: var(--spacing-xs) var(--spacing-xxs);
    margin-left: var(--spacing-2xl);
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    cursor: pointer;

    &.opened {
      justify-content: center;
      > p {
        &:first-child {
          top: auto;
          width: calc(100% - var(--spacing-xs));
          transform: rotate(45deg);
        }
        &:nth-child(2) {
          opacity: 0;
        }
        &:last-child {
          bottom: auto;
          transform: rotate(-45deg);
        }
      }
    }

    &:hover > p {
      background-color: var(--secondary);
    }

    > p {
      position: absolute;
      margin: 0;
      width: 100%;
      height: 3px;
      background-color: var(--primary);
      border-radius: var(--border-radius);
      transition: 0.3s;
      transform-origin: center center;
      &:first-child {
        top: var(--spacing-xs);
        width: calc(60% - var(--spacing-xs));
      }
      &:nth-child(2) {
        opacity: 1;
        width: calc(80% - var(--spacing-xs));
      }
      &:last-child {
        bottom: var(--spacing-xs);
        width: calc(100% - var(--spacing-xs));
      }
    }
  }
  .sliderConatiner {
    width: calc(100% - var(--side-bar-width) - var(--avatar-container-width));
    padding: 0 var(--spacing-l);
    height: 100%;
    display: flex;
    align-items: center;
  }
}

@media (max-width: 991px) {
  .container {
    --side-bar-width: 162px;
    --avatar-container-width: 70px;

    justify-content: space-between;
  }
  .container .logoContainer {
    justify-content: flex-start;
    width: auto;
  }
  .container .sliderConatiner {
    padding: 0;
    display: none;
  }
  .container .breadCrumbs {
    padding-left: var(--spacing-xl);
  }
  .container .itemsGroup .resources {
    margin-right: 0;
  }
}

@media (max-width: 767px) {
  .container .breadCrumbs {
    display: none;
  }
}
