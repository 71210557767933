@import "@styles/mixins";

.container {
  @include flexContainer(row, flex-start, center);

  &.checked {
    .inner .label .switcher {
      .slider {
        background-color: var(--primary);
        margin-left: auto;
      }
    }
  }

  &.disabled {
    opacity: 0.1;
    pointer-events: none;
  }

  .inner {
    .label {
      @include flexContainer(row, flex-start, center);
      gap: var(--spacing-xs);
      cursor: pointer;

      .switcher {
        @include flexContainer(row, flex-start, center);
        @include borderedContainer(var(--primary));
        width: 50px;
        min-width: 50px;
        height: 20px;
        padding: 1px;

        .slider {
          display: block;
          background-color: var(--main-color-separator);
          border-radius: var(--border-radius);
          width: 50%;
          height: 100%;
          transition: 0.3s;
        }
      }

      > input {
        width: 0;
        height: 0;
        opacity: 0;
      }

      > p {
        color: var(--primary);
        font-size: var(--font-size-base);
      }
    }
  }
}
