@import "@styles/mixins.scss";

.container {
  @include flexContainer(column, flex-start, flex-start);

  flex-grow: 1;
  background-color: var(--container-bg);
  box-shadow: var(--box-shadow);
  width: 100%;
  min-height: var(--inner-content-min-height);
  border-radius: var(--border-radius);

  .inner {
    @include flexContainer(column, flex-start, flex-start);

    flex-grow: 1;
    width: 100%;
    padding: var(--spacing-l);
    border-radius: var(--border-radius);
    animation: var(--slide-up-animation);
  }
}

.content {
  @include flexContainer(column, center, center);

  width: 100%;
  flex-grow: 1;
  background-color: var(--white);
  box-shadow: var(--box-shadow);
  padding: var(--spacing-l);

  &[data-content="true"] {
    box-shadow: none;
  }

  .mainTitle {
    font-size: var(--font-size-extra-medium);
    color: var(--secondary);
    font-weight: 600;
    margin-bottom: var(--spacing-xl);
  }
  .subTitle {
    font-size: var(--font-size-medium);
    color: var(--primary);
    font-weight: 500;
    margin-bottom: var(--spacing-xs);
  }
  .info {
    font-size: var(--font-size-base);
    color: var(--muted-text);
    font-weight: 400;
    margin-bottom: var(--spacing-3xl);

    > a {
      color: var(--secondary);
      font-weight: 600;
      &:active {
        color: var(--primary);
      }
    }
  }
  .buttonWrapper {
    @include flexContainer(row);

    width: 200px;
  }
}
