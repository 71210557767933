@import "@styles/mixins";

.container,
.formContainer,
.formContainer > form {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.container {
  width: 100%;
  padding: var(--spacing-4xl) 0;
  padding-bottom: 0;
  flex-grow: 1;

  .inner {
    @include flexContainer(column, center);

    flex-grow: 1;
    width: 100%;
    overflow-y: auto;
    padding-bottom: var(--spacing-4xl);
    animation: SlideUp 0.3s ease-in-out;

    .formContainer {
      position: relative;
      background-color: var(--white-opacity-10);
      min-width: 300px;
      max-width: 500px;
      width: 100%;
      padding: var(--spacing-l);
      border-radius: var(--border-radius);

      .title {
        font-size: var(--font-size-extra-medium);
        color: var(--white);
        margin-bottom: var(--spacing-m);
        font-weight: 500;
      }
    }
    .formContainer > form {
      width: 100%;

      .formInnerWrapper {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 100%;
      }

      .btnGroup {
        padding-top: var(--spacing-l);
      }
    }
    .formContainer .inputWrapper {
      width: 100%;
    }
  }

  .loaderContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding: var(--spacing-l);
  }
}

@keyframes SlideUp {
  0% {
    transform: translateY(100px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
