@import "@styles/mixins";

.container {
  --list-max-height: 270;

  position: relative;

  &[data-readonly="true"] .dropdownContainer {
    opacity: var(--readonly-opacity);
    pointer-events: none;
    cursor: default;
  }
}
.dropdownContainer {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  border: 1px solid var(--primary);
  padding: var(--spacing-sx) var(--spacing-4xl) var(--spacing-sx) var(--spacing-m);
  cursor: pointer;
  border-radius: var(--border-radius);
  min-height: 40px;
  transition: 0.1s;

  .iconContainer {
    display: flex;
    margin-right: 10px;
  }

  .loadingWrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.selectedItem {
  width: 100%;
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: var(--placeholder);
  font-size: var(--font-size-main);
}
.labelInner {
  display: flex;
  justify-content: space-between;
  cursor: default;

  .label {
    position: relative;
    color: var(--primary);
    opacity: 0.8;
    font-size: var(--font-size-main);
    margin-bottom: var(--spacing-xs);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    &.required::after {
      content: "(Required)";
      font-size: var(--font-size-small);
      color: var(--error);
      margin-left: 8px;
    }
  }
}

.selected {
  color: var(--primary);
}
.disable .dropdownContainer {
  pointer-events: none;
  opacity: 0.2;
}

.listContainer {
  position: absolute;
  left: 0;
  top: calc(100%);
  width: 100%;
  max-height: var(--list-max-height);
  padding-top: 4px;
  max-height: 0;
  z-index: 50;
  transition: max-height, opacity 0.3s;
  cursor: pointer;
  opacity: 0;
  overflow: hidden;
  pointer-events: none;

  &.reversePosition {
    top: unset;
    bottom: calc(100% - 1em);
  }
  &.withoutLabel {
    bottom: calc(100% + var(--spacing-m));
  }
}
.container.open .listContainer {
  opacity: 1;
  max-height: var(--list-max-height);
  overflow: unset;
  pointer-events: all;
}
.container.open .dropdownContainer {
  outline: 1.3px solid var(--primary);
}
.listContainer .wrapper {
  width: 100%;
  box-shadow: var(--box-shadow);
  min-height: 36px;
  max-height: var(--list-max-height);
  overflow-y: auto;
  border-radius: var(--border-radius);
}
.list {
  list-style: none;
  padding: var(--spacing-xs);
  background-color: var(--white);
  min-height: min-content;

  .subItem {
    display: block;
    padding: var(--spacing-xs) var(--spacing-m);
    padding-left: var(--spacing-2xl);
    transition: 0.3s;
    color: var(--primary);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: var(--font-size-base);
    user-select: none;
    background-color: var(--main-color-border);

    &:hover {
      background-color: var(--secondary-hover);
    }
    &.active {
      background-color: var(--primary);
      color: var(--white);
    }
  }
  .listItem {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: var(--spacing-xs) var(--spacing-m);
    transition: 0.3s;
    color: var(--primary);
    overflow: hidden;
    border-bottom: 1px solid var(--white);
    border-radius: var(--spacing-xxs);

    .listItemLabel {
      text-overflow: ellipsis;
      white-space: nowrap;
      user-select: none;
      overflow: hidden;
      width: 100%;
    }

    .componentWrapper {
      @include flexContainer(row, flex-start, center);
      margin-left: auto;
    }

    &.subitemsLablel {
      font-weight: 400;
      padding-right: var(--spacing-2xl);
      background-color: var(--main-color-border);
      cursor: default;
      color: var(--muted-text);
    }
    &:hover {
      background-color: var(--secondary-hover);
    }
    &.active {
      background-color: var(--primary);
      color: var(--white);
    }
    .iconContainer {
      position: absolute;
      right: var(--spacing-m);
      width: 10px;
      height: auto;
      transform-origin: center;
      transition: 0.3s;

      > svg {
        width: 100%;
        height: auto;
      }
      &.open {
        transform: rotate(180deg);
      }
    }
    &.empty {
      @include flexContainer;

      height: var(--empty-list-item-height);
      width: calc(100% - var(--spacing-xs) * 2);
      color: var(--muted-text);
      font-weight: 500;
      border-radius: var(--border-radius);
      border: 1px solid var(--main-border);
      margin: 0 var(--spacing-xs);
      cursor: default;
    }
  }
}

.dropdownContainer:before,
.dropdownContainer:after {
  content: "";
  position: absolute;
  width: 7px;
  border-bottom: 2px solid var(--primary);
  border-radius: 4px;
  transform-origin: center center;
  transition: 0.3s;
}

.dropdownContainer:before {
  right: 24px;
  transform: rotate(45deg);
}

.dropdownContainer:after {
  right: 20px;
  transform: rotate(-45deg);
}

.container.open .dropdownContainer:before {
  transform: rotate(-45deg);
}

.container.open .dropdownContainer:after {
  transform: rotate(45deg);
}
