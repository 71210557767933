@import "@styles/mixins.scss";

.container {
  @include flexContainer(column, flex-start, flex-start);

  background-color: var(--white);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  padding: var(--spacing-l);
  row-gap: var(--spacing-m);
  max-width: 750px;

  .heading {
    font-size: var(--font-size-medium);
    margin-bottom: var(--spacing-xxs);
    width: 100%;
  }

  .mainSection {
    @include flexContainer(column, flex-start, flex-end);

    row-gap: var(--spacing-m);
    border-radius: var(--border-radius);
    border: 1px solid var(--main-border);
    padding: var(--spacing-m);
    width: 100%;

    .title,
    .description {
      @include flexContainer(row, flex-start, center);

      width: 100%;
      column-gap: var(--spacing-xs);
      color: var(--muted-text);
      font-size: var(--font-size-main);
      font-weight: 600;

      > span {
        font-size: var(--font-size-main);
        font-weight: 500;
        color: var(--primary);
      }
      .iconWrapper {
        display: flex;
        width: 24px;
        min-width: 24px;

        > svg {
          width: 100%;
          height: auto;
        }
      }
    }
    .description {
      > span {
        font-weight: 400;
        font-size: var(--font-size-base);
      }

      .iconWrapper {
        opacity: 0.5;

        > svg > path {
          fill: var(--primary);
        }
      }
    }

    .dateGroup {
      @include flexContainer(row, flex-start, flex-end);

      column-gap: var(--spacing-m);
      width: 100%;
    }

    .btnWrapper {
      @include flexContainer(row, flex-start, flex-start);

      column-gap: var(--spacing-m);
      width: 100%;
    }
  }
}
