@import "@styles/mixins.scss";

.container {
  display: flex;
  gap: var(--spacing-m);
  margin-bottom: var(--spacing-m);
  animation: SlideRight 0.3s ease-in-out;

  &.animated {
    animation: SlideRight 0.3s ease-in-out;
  }
  &.blank .messageContainer:before,
  &.blank .avatarContainer {
    display: none;
  }
  &.blank .messageContainer {
    margin-left: calc(48px + var(--spacing-m));
    .header {
      display: none;
    }
  }

  .avatarContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    min-width: 48px;
    border-radius: 50%;
    background-color: var(--white);
    box-shadow: var(--box-shadow);
    padding: 4px;

    > .bgImage {
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }
  .messageContainer {
    position: relative;
    min-width: 170px;

    &.withData {
      width: 100%;
    }
    &.botMessage .inner {
      background-color: var(--secondary-hover);
    }
    &.botMessage:before {
      background-color: var(--secondary-hover);
    }

    .header {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      gap: var(--spacing-2xl);
      width: 100%;
      margin-bottom: var(--spacing-xxs);
      color: var(--main-color);
      max-width: 200px;

      &.odin {
        font-weight: 600;
      }
      .name {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .newMessage {
        color: var(--success);
        font-size: var(--font-size-small);
        font-weight: 500;
      }
    }

    .inner {
      display: flex;
      flex-direction: column;
      padding: var(--spacing-xs);
      border-radius: var(--border-radius);
      border: 1px solid var(--secondary);
      background-color: var(--white);
      width: fit-content;
      min-width: 170px;
      max-width: 100%;
      font-size: var(--font-size-main);

      .text {
        font-size: var(--font-size-main);
        font-weight: 400;
        font-family: "Inter", sans-serif;
        color: var(--text);
        white-space: pre-wrap;
        word-break: break-word;
      }
      .emoji {
        font-size: var(--font-size-medium);
      }
      .footer {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: var(--spacing-m);
        margin-top: var(--spacing-xxs);

        .dateTime {
          display: flex;
          align-self: flex-end;
          border-radius: var(--border-radius);
          padding: 4px 8px;
          background-color: var(--main-border);
          font-size: var(--font-size-small);
          transition: 0.3s;
          color: var(--primary);
          opacity: 0;

          &:hover:before {
            content: attr(data-date);
            color: var(--secondary);
            padding-right: var(--spacing-xs);
            animation: fadeIn 0.3s ease-in;
          }
        }
        .status {
          margin-right: auto;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 18px;
          height: 18px;
          cursor: pointer;
          > svg {
            width: 100%;
            height: auto;
          }
        }
        .completed {
          width: 12px;
          height: 8px;
          transform: rotate(-45deg);
          transform-origin: center;
          border-left: 2px solid var(--secondary);
          border-bottom: 2px solid var(--secondary);
          margin-right: auto;
        }
      }
    }

    &:before {
      content: "";
      position: absolute;
      left: -6px;
      top: 36px;
      width: 12px;
      height: 12px;
      background-color: var(--white);
      transform: rotate(45deg);
      transform-origin: center center;
      border-bottom: 1px solid var(--secondary);
      border-left: 1px solid var(--secondary);
    }
  }
}

.container.blank .messageContainer .inner:hover .footer > .dateTime {
  opacity: 1;
}
.container:not(.blank) .messageContainer .inner .footer > .dateTime {
  opacity: 1;
}

@keyframes SlideRight {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@media (max-width: 575px) {
  .container {
    .avatarContainer {
      display: none;
    }
  }
  .container.blank {
    .messageContainer {
      margin-left: 0;
    }
  }
}
