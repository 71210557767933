@import "@styles/mixins";

.container {
  .hederRow {
    @include flexContainer(row, flex-start, center);
    margin-bottom: var(--spacing-m);

    > span {
      color: var(--main-color);
      font-weight: 600;
    }
    > span:first-child {
      display: flex;
      align-items: center;
      width: 70%;
      padding: var(--spacing-xxs) var(--spacing-xs);
    }
    > span:nth-child(2) {
      width: 45%;
      min-width: 50px;
      padding: var(--spacing-xxs) var(--spacing-xs);
      border-left: 1px solid var(--main-border);
      border-right: 1px solid var(--main-border);
    }
    > span:nth-child(3) {
      width: 80px;
      min-width: 80px;
      padding: var(--spacing-xxs) var(--spacing-xs);
    }
  }
  .listContainer {
    width: 100%;
    .emptyData {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: var(--spacing-2xl);
      width: 100%;
      height: 100%;
      min-height: 170px;
      color: var(--muted-text);
      background-color: var(--white);
      border-radius: var(--border-radius);
      border: 1px solid var(--main-color-border);
    }

    .itemRow {
      @include flexContainer(row, flex-start, center);
      width: 100%;
      border: 1px solid var(--main-border);
      border-radius: var(--border-radius);

      &:not(:last-child) {
        margin-bottom: var(--spacing-xs);
      }

      .col:first-child {
        width: 70%;
        // min-width: 200px;
        padding: var(--spacing-s) var(--spacing-m);
        overflow: hidden;
        color: var(--secondary);
        cursor: pointer;

        .title {
          width: 100%;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
      .col:nth-child(2) {
        width: 45%;
        min-width: 50px;
        padding: var(--spacing-m);
      }
      .col:nth-child(3) {
        @include flexContainer(row, center, center);
        column-gap: var(--spacing-xs);
        width: 80px;
        min-width: 80px;
        padding: var(--spacing-m);
        cursor: pointer;
        .menu {
          padding: 0 var(--spacing-m);
        }
      }
    }
  }
}
