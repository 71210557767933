@import "@styles/mixins.scss";

.container {
  @include flexContainer(column, flex-start, flex-start);

  width: 100%;
  margin-bottom: var(--spacing-2xl);
  .headingGroup {
    display: flex;
    justify-content: space-between;

    .heading {
      margin-bottom: var(--spacing-m);
    }
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 20px;
    border-radius: var(--border-radius);
    border: 1px solid var(--main-border);
    height: 210px;

    .scrollContainer {
      position: relative;
      width: 100%;
      overflow: hidden;
      height: 100%;
      min-height: calc(96px + 16px);

      .inner {
        position: absolute;
        top: 0;
        left: 0;
        min-height: calc(96px + 34px);
        display: flex;
        width: 100%;
        height: 100%;
        overflow: unset;
        transition: 0.3s;
        &:global(.scrollMobile) {
          overflow-x: auto;
          overflow-y: hidden;
        }

        .loaderContainer {
          display: flex;
          width: 100%;
          height: 100%;
          justify-content: center;
          align-items: center;
        }
        .empty {
          display: flex;
          width: 100%;
          height: 100%;
          justify-content: center;
          align-items: center;
          font-weight: 500;
          opacity: 0.3;
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .container .wrapper {
    height: 256px;
  }
}

@media (max-width: 767px) {
  .container .wrapper {
    height: calc(242px);
  }
  .container .wrapper .scrollContainer {
    min-height: calc(96px + 36px);
  }
}
