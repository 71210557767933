.container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: var(--spacing-l);
  background-color: var(--container-bg);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  min-width: 363px;
  width: 100%;

  animation: var(--slide-up-animation);
}
