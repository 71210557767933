@import "@styles/mixins";

.container {
  position: relative;
  background-color: var(--white);
  padding: var(--spacing-l);
  max-width: 660px;
  width: 70%;
  border-radius: var(--border-radius);
  max-height: 100vh;
  overflow-y: auto;

  h2 {
    font-size: var(--font-size-extra-medium);
    font-weight: 600;
    color: var(--primary);
    text-align: center;
  }
  .content {
    display: flex;
    flex-direction: column;

    .inner {
      @include borderedContainer;

      display: flex;
      flex-direction: column;
      margin-top: var(--spacing-l);
      padding: var(--spacing-l);

      .infobox {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 var(--spacing-l);
        color: var(--muted-text);
        border-radius: var(--border-radius);

        p {
          font-size: var(--font-size-medium);
          font-weight: 400;
          text-align: center;
          line-height: 1.33;
        }
      }

      .buttonTypes {
        @include flexContainer;

        margin-top: var(--spacing-2xl);
        gap: var(--spacing-sx);
      }
      .textInput {
        margin-top: var(--spacing-l);
        textarea {
          border-color: var(--main-border);
        }
      }
      .modalFooter {
        display: flex;
        justify-content: space-between;

        .buttons {
          display: flex;
          column-gap: var(--spacing-m);
        }
      }

      .uploadInfo {
        @include flexContainer(row, space-between, center);

        color: var(--primary);
        margin-bottom: var(--spacing-m);
        width: 100%;
        overflow: hidden;

        > p {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          max-width: 80%;
        }
        > p > span {
          margin-left: var(--spacing-xs);
          color: var(--muted-text);
        }
      }
    }

    .success {
      .successInner {
        display: flex;
        align-items: center;
        flex-direction: column;
        border: 1px solid var(--main-border);
        padding: var(--spacing-m);
        margin-top: var(--spacing-xl);
        margin-bottom: var(--spacing-xl);
        h3 {
          margin-bottom: var(--spacing-m);
        }
      }
      .close {
        display: flex;
        justify-content: flex-end;
      }
    }
  }

  @media (max-width: 768px) {
    min-width: 400px;
    width: 100%;
  }
}

.feedbackButtonContainer {
  @include flexContainer(column);
  row-gap: var(--spacing-xs);
  font-size: var(--font-size-base);
  font-weight: 500;
  color: var(--muted-text);
  transition: 0.3s;

  &.active {
    color: var(--primary);

    .feedbackButtonIconWrapper {
      background-color: var(--primary);

      > svg {
        color: var(--white);
      }
    }
  }

  .feedbackButtonIconWrapper {
    padding: var(--spacing-l) var(--spacing-xl);
    border-radius: var(--border-radius);
    border: 1px solid var(--main-border);
    box-shadow: var(--box-shadow);
    transition: 0.3s;
    cursor: pointer;

    > svg {
      color: var(--primary);
    }
    &:hover {
      transform: scale(1.05);
    }
  }
}

@media (max-width: 767px) {
  .container {
    .content {
      .inner {
        .modalFooter {
          flex-direction: column;
          row-gap: var(--spacing-4xl);

          .buttons {
            flex-direction: column;
            row-gap: var(--spacing-m);
          }
        }
      }
    }
  }
}
