.container {
  .saveButton {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: var(--spacing-l);
  }
  .inputText {
    margin-top: var(--spacing-l);
  }
  .groupSelect {
    display: flex;
    width: 100%;
    gap: var(--spacing-m);
    .select {
      width: 100%;
    }
  }
}
