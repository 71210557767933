.goBack {
  display: flex;
  align-items: center;
  text-decoration: none;
  border: none;
  background: transparent;
  cursor: pointer;
  color: var(--primary);
  transition: 0.3s;

  &.secondary {
    color: var(--secondary);
  }
  > span {
    font-family: "Inter";
    font-weight: 500;
    font-size: var(--font-size-base);
    color: inherit;
    padding-right: var(--spacing-2xl);
    white-space: nowrap;
    overflow: hidden;
  }
  > svg {
    color: inherit;
    transform: rotate(-90deg);
    margin-right: var(--spacing-xs);
    width: 17px;
  }
  &:hover {
    filter: brightness(1.3);
  }
}
