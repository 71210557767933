.messagesContainer {
  display: flex;
  align-items: center;
  padding: 2px var(--spacing-xs);
  margin-left: auto;
  border-radius: var(--border-radius);
  background-color: var(--error);
  animation: FadeInSlide 0.3s ease-in-out;
  border: 1px solid var(--white);
  cursor: pointer;

  .count {
    display: flex;
    align-items: center;
    gap: var(--spacing-xs);
    font-size: 11px;
    font-weight: 600;
    color: var(--white);

    > span:first-child {
      display: flex;

      > svg {
        width: 100%;
        height: auto;
      }
    }
  }
}

@keyframes FadeInSlide {
  0% {
    transform: translateX(-200px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
