@import "@styles/mixins";

.container {
  @include flexContainer(column, flex-start, flex-start);

  --min-card-height: 88px;
  --default-bg-color: #cacaca66;
  // --default-bg-color: var(--secondary-hover);

  width: 100%;
  padding: var(--spacing-xs);
  border-radius: var(--border-radius);
  background-color: var(--white);
  row-gap: var(--spacing-xxs);
  overflow: hidden;
  box-shadow: var(--box-shadow);
  min-height: var(--min-card-height);
  border: 1px solid var(--main-color-separator);

  .empty {
    @include flexContainer;

    width: 100%;
    flex-grow: 1;
    background-color: var(--container-bg);
    border-radius: var(--border-radius);
  }

  .title {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: var(--font-size-main);
    font-weight: 500;
    color: var(--primary);
  }
  .cardWrapper {
    @include flexContainer(row, space-between);

    padding: var(--spacing-xs);
    border-radius: var(--border-radius);
    background-color: var(--default-bg-color);
    width: 100%;
    flex-grow: 1;

    &.positive {
      background-color: var(--success);
    }
    &.negative {
      background-color: var(--error);
    }
    .left {
      padding: var(--spacing-xs);
      border-radius: var(--border-radius);
      background-color: var(--white);
      color: var(--muted-text);
      font-weight: 600;
      font-size: var(--font-size-base);
      white-space: nowrap;
    }
    .right {
      padding: 0 var(--spacing-xxs);
      color: var(--primary);
      font-weight: 600;
      font-size: var(--font-size-medium);

      &.inverted {
        color: var(--white);
      }
    }
  }
}
