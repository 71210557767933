.container {
  --bg-fade-color: rgb(126 126 126 / 18%);
  --bg-fade-lite-color: rgb(255 255 255 / 94%);

  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  height: 100dvh;
  width: 100vw;
  background-color: var(--bg-fade-color);
  z-index: 320;
  animation: FadeIn 0.3s ease-in-out;
  backdrop-filter: blur(50px);

  &.scoped {
    background-color: var(--bg-fade-lite-color);
    position: absolute;
    width: auto;
    height: auto;
    inset: 0;
    backdrop-filter: blur(0);
    border-radius: var(--border-radius);
    backdrop-filter: blur(5px);
    z-index: 250;
  }

  > div:first-child {
    box-shadow: var(--box-shadow);
    max-height: 88vh;
    max-height: 88dvh;
    animation: FadeInScaleUp 0.3s ease-out;
    transition: max-height 0.3s;
  }
}

@keyframes FadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes FadeInScaleUp {
  0% {
    opacity: 0;
    transform: scale(0.7);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
