@import "@styles/mixins.scss";

.container {
  @include flexContainer(column, flex-start, flex-start);

  flex-grow: 1;
  width: 100%;

  .headingGroup {
    @include flexContainer(row, flex-start);

    margin-bottom: var(--spacing-m);
  }

  .searchWrapper {
    @include flexContainer(row, flex-start);

    width: 100%;
    column-gap: var(--spacing-l);
    margin-bottom: var(--spacing-l);
    padding-right: var(--spacing-m);
  }

  .wrapper {
    @include flexContainer(column, flex-start, flex-start);

    flex-grow: 1;
    width: 100%;
    min-height: 150px;

    .paginationContainer {
      margin-bottom: var(--spacing-l);
      padding-right: var(--spacing-l);
      width: 100%;
    }
  }
}
