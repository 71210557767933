.container {
  position: relative;
  background-color: var(--white);
  padding: var(--spacing-l);
  min-width: 800px;
  width: 80vw;
  border-radius: var(--border-radius);
  display: flex;
  flex-direction: column;
  min-height: 400px;

  .close {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    width: 50px;
    height: 50px;
    font-size: 0;
    cursor: pointer;

    &:before,
    &:after {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 2px;
      height: 25px;
      background-color: var(--gray-3);
      transform: rotate(45deg) translate(-50%, -50%);
      transform-origin: top left;
      transition: all 420ms;
      content: "";
    }
    &:after {
      transform: rotate(-45deg) translate(-50%, -50%);
    }
    &:hover:before,
    &:hover:after {
      background-color: var(--primary);
    }
  }
  .markeActionTab {
    width: 100%;
    height: 100%;
    .daysSelect {
      display: grid;
      grid-template-columns: repeat(2, 150px) min-content 1fr;
      justify-items: flex-start;
      gap: var(--spacing-m);
      .button {
        margin-top: 29px;
      }
      @media (max-width: 575px) {
        display: flex;
        flex-direction: column;
        gap: 0;
        .button {
          margin-top: 0px;
        }
      }
    }
  }

  .descriptionList {
    height: 390px;
    width: 100%;
    overflow: scroll;
    list-style: none;
    li {
      padding: var(--spacing-xs) 0;
      border-bottom: 1px solid var(--border);
    }
  }

  .head {
    display: flex;
    width: 95%;
    margin-bottom: var(--spacing-m);
    .menuInner {
      display: flex;
      align-items: center;
      white-space: nowrap;

      .icon {
        min-width: 20px;
        min-height: 20px;
      }
      span {
        margin-left: var(--spacing-sx);
      }
    }
  }

  @media (max-width: 768px) {
    min-width: 400px;
    width: 100%;
  }
}
