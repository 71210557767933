.searchWrapper {
  margin-bottom: var(--spacing-m);
  @media (max-width: 991px) {
    width: 100%;
  }

  @media (max-width: 767px) {
    width: 100%;
  }
}
