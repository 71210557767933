@import "@styles/mixins.scss";

.container {
  @include flexContainer(row, flex-start);

  --icon-width: 32px;
  --icon-small-width: 42px;
  --icon-min-width: 28px;

  width: 100%;
  column-gap: var(--spacing-m);
  padding: var(--spacing-xs) var(--spacing-m);
  border: 1px solid var(--main-color-border);
  border-radius: var(--border-radius);
  overflow: hidden;

  .iconWrapper {
    display: flex;
    width: var(--icon-width);
    min-width: var(--icon-width);
    height: auto;

    > svg {
      width: 100%;
      height: auto;

      &[data-type="overlay"] {
        color: var(--secondary);
      }
      &[data-type="core"] {
        color: var(--primary);
      }
    }
    > p {
      display: none;
      padding: var(--spacing-xs) 0;
      padding-right: var(--spacing-xs);
      width: 100%;
      align-items: center;
      border-right: 1px solid var(--main-border);
      color: var(--muted-text);
    }
  }
  .icon {
    display: none;
    width: var(--icon-small-width);
    min-width: var(--icon-small-width);
    padding: var(--spacing-xs);
    padding-left: var(--spacing-m);
    border-left: 1px solid var(--main-color-border);
    cursor: pointer;

    > svg {
      width: 100%;
      height: auto;
    }
  }
  .innerText {
    display: flex;
    overflow: hidden;
    column-gap: var(--spacing-2xl);
    justify-content: space-between;
    width: 100%;

    .textGroup {
      @include flexContainer(row, flex-start);

      width: 100%;
      column-gap: var(--spacing-xs);
      color: var(--muted-text);
      font-size: var(--font-size-base);
      font-weight: 400;

      &:nth-child(1) {
        width: 100%;
        color: var(--primary);
        overflow: hidden;

        > span {
          &:first-child {
            color: var(--secondary);
          }
          &:nth-child(2) {
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        .empty {
          font-size: var(--font-size-main);
          padding: var(--spacing-xs) 0;
        }
      }

      &:nth-child(2) {
        color: var(--primary);
        width: auto;

        > span {
          &:nth-child(1) {
            color: var(--muted-text);
          }

          &:nth-child(2) {
            &[data-type="overlay"] {
              color: var(--secondary);
            }
            &[data-type="core"] {
              color: var(--primary);
            }
          }
        }
      }
      &:has(.empty) {
        justify-content: center;
      }
      &:has(.empty) > span:nth-child(2) {
        width: auto;
      }

      > span {
        white-space: nowrap;
      }
    }
  }
}

@media (max-width: 767px) {
  .container {
    .innerText {
      flex-wrap: wrap;
      row-gap: var(--spacing-xxs);
    }
    .icon {
      display: flex;
    }
    .iconWrapper {
      min-width: var(--icon-min-width);
      width: var(--icon-min-width);

      > p {
        display: flex;
      }
    }
    .textGroup {
      &:nth-child(1) {
        overflow: hidden;

        > span:nth-child(1) {
          display: none;
        }
        > span:nth-child(2) {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          &:before {
            content: "name:";
            color: var(--muted-text);
            padding-right: var(--spacing-xs);
          }
        }
      }
      &:nth-child(2) {
        > span {
          font-size: var(--font-size-small);
        }
      }
    }
  }
}
