.container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--white-opacity-10);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  min-width: 300px;
  width: 100%;
  max-width: 500px;
  width: 100%;
  padding: var(--spacing-l);
  overflow: hidden;
  animation: SlideUp 0.3s ease-in-out;
  color: var(--white);
}
.textContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: var(--spacing-l);
}
.iconContainer {
  margin-bottom: var(--spacing-l);
}

.title {
  font-size: var(--font-size-extra-medium);
  color: var(--warning);
  margin-bottom: var(--spacing-l);
  font-weight: 500;
}
.subtitle {
  font-size: var(--font-size-medium);
  margin-bottom: var(--spacing-4xl);
  text-align: center;
}

.message {
  font-size: var(--font-size-main);
  padding: 0 var(--spacing-2xl);
  line-height: 1.44;
  text-align: center;
  color: var(--white-opacity-70);
  > span {
    font-weight: 600;
    color: var(--white);
  }
  > a {
    color: var(--secondary);
  }
}

@keyframes SlideUp {
  0% {
    transform: translateY(100px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
