@import "@styles/mixins";

.container {
  --slider-max-width: 800px;

  @include flexContainer(column, flex-start, center);

  width: 100%;
  row-gap: var(--spacing-l);
  padding: 0 var(--spacing-2xl);

  .inner {
    @include flexContainer(column, center);

    width: 100%;
    position: relative;
    border-radius: var(--border-radius-8);
    background-color: var(--white);
    max-width: var(--slider-max-width);

    .navArrowLeft,
    .navArrowRight {
      position: absolute;

      button {
        border: none;
        width: 20px;
        height: 20px;
        background: transparent;
        border-top-right-radius: var(--border-radius);
        border-top: 3px solid var(--white);
        border-right: 3px solid var(--white);
        cursor: pointer;
      }
    }
    .navArrowRight {
      right: -28px;
      left: unset;
      transform: rotate(45deg);
    }
    .navArrowLeft {
      left: -28px;
      transform: rotate(-135deg);
    }

    .imageWrapper {
      @include flexContainer(column, center);

      width: 100%;
      position: relative;
      animation: FadeIn 0.3s ease-in-out;
      height: 0;
      padding-bottom: 66%;
      border-radius: var(--border-radius-8);
      overflow: hidden;

      > img {
        top: 0;
        left: 0;
        position: absolute;
        width: 100%;
        height: auto;
      }
    }
  }

  .navigation {
    @include flexContainer(row, center, center);

    width: 100%;
    gap: var(--spacing-xs);
    flex-wrap: wrap;

    .navItem {
      width: 100%;
      height: 12px;
      background-color: var(--white);
      max-width: 16px;
      min-width: 16px;
      width: 16px;
      border-radius: var(--border-radius);
      opacity: 0.5;
      cursor: pointer;

      &.active {
        opacity: 1;
      }
    }
  }
}

@keyframes FadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
