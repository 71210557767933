@import "@styles/mixins.scss";
.chartCard {
  position: relative;
  padding: var(--spacing-m);
  background: var(--white);
  width: 100%;
  border: 1px solid var(--main-color-separator);
  border-radius: var(--border-radius);
  max-height: 240px;
  min-height: 160px;
  overflow: hidden;
  animation: fadeInScaleUp 0.1s ease-in;

  .buttonsContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    background: var(--white);
    z-index: 11;
    transition: all 0.3s;
    @include flexContainer(flex, center, center);
    .menu {
      position: absolute;
      top: -5px;
      right: 20px;
      .menuInner {
        transform: rotate(90deg);
        padding: var(--spacing-m);
        svg {
          path {
            fill: var(--primary);
          }
        }
      }
    }
    .buttons {
      @include flexContainer(column, center, center);
      .dragIcon {
        padding: var(--spacing-m) var(--spacing-xl);
        cursor: move;
        border-radius: var(--border-radius);
        border: 1px solid var(--gray-3);
      }
      .preview {
        display: flex;
        align-items: center;
        gap: var(--spacing-xs);
        padding: var(--spacing-2xl);
        cursor: pointer;
        span {
          font-size: var(--font-size-base);
          font-weight: 600;
          color: var(--secondary);
        }
        svg {
          path {
            fill: var(--secondary);
          }
        }
      }
    }
    &:hover {
      opacity: 0.85;
      ~ div {
        filter: blur(8px) grayscale(1);
        transition: 0.3s;
      }
    }
  }
}

@keyframes fadeInScaleUp {
  0% {
    opacity: 0;
    transform: scale(0.8);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
