@import "@styles/mixins.scss";

.container {
  width: 100%;
  margin-bottom: var(--spacing-xs);

  .title {
    color: var(--secondary);
    font-size: var(--font-size-main);
    font-weight: 500;
    padding-left: var(--spacing-m);
    margin-right: var(--spacing-m);
    margin-bottom: var(--spacing-sx);
  }

  .videoWrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: white;
    padding: var(--spacing-m);
    border-radius: var(--border-radius);

    > video {
      max-width: 950px;
      width: 100%;
      height: auto;
      aspect-ratio: 16/9;
    }
    .logo {
      position: absolute;
      right: var(--spacing-m);
      top: var(--spacing-m);
    }
    .frontContainer {
      @include flexContainer;
      position: absolute;
      inset: 0 0 0 0;
      background-color: rgba(255, 255, 255, 50%);
      backdrop-filter: blur(8px);

      &:hover {
        cursor: pointer;
      }

      &:hover::before {
        transform: scale(1.2);
      }

      &.playing {
        display: none;
      }
      &:before {
        position: absolute;
        content: "";
        width: 60px;
        height: 60px;
        border-radius: 50%;
        background-color: var(--primary);
        transition: 0.3s;
      }
      &:after {
        position: absolute;
        content: "";
        border: 20px solid transparent;
        border-top-width: 14px;
        border-bottom-width: 14px;
        border-right: none;
        border-left-color: var(--white);
        margin-left: 5px;
      }
    }
  }

  .notImplemented {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: white;
    border: 1px solid var(--main-border);
    border-radius: var(--border-radius);
    padding: var(--spacing-m);
    color: var(--muted-text);
    font-size: var(--font-size-main);
    font-weight: 600;
    min-height: 300px;
  }
}
