@import "@styles/mixins";

.container {
  .row {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    column-gap: var(--spacing-m);

    &:last-child .radioContainer {
      padding-bottom: 0;
    }
    .itemWrapper {
      width: calc((100% - 32px) / 3);
      flex-grow: 1;
      min-width: 270px;

      &:not(.nospace) {
        padding-bottom: var(--spacing-l);
      }
      .innerItemWrapper {
        display: grid;
        grid-template-columns: minmax(120px, 1fr) minmax(120px, 1fr);
        width: 100%;
        column-gap: var(--spacing-m);
      }
    }
    .empty {
      width: calc((100% - 32px) / 3);
      flex-grow: 1;
      min-width: 270px;
    }
  }
}
