.messageContainer {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 var(--spacing-7xl);
  padding-bottom: 75px;

  > .laderContainer {
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  > .inner {
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    animation: FadeIn 1s ease-in-out;

    &.empty {
      justify-content: center;
    }

    .dateSeparator {
      position: sticky;
      top: 0;
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;
      z-index: 120;
      padding-left: calc(48px + var(--spacing-m));
      transition: opacity 0.3s;
      opacity: 1;

      &.hidden {
        opacity: 0;
      }

      > time {
        position: relative;
        top: 12px;
        font-size: var(--font-size-base);
        font-weight: 500;
        background-color: var(--secondary);
        color: var(--white);
        padding: var(--spacing-xxs) var(--spacing-m);
        border-radius: var(--border-radius);
        width: 130px;
        text-align: center;
      }
    }

    .messagesGroup {
      padding-top: var(--spacing-l);
      border-top: 1px solid var(--secondary-row-hover);

      .newMessageDividerContainer {
        position: sticky;
        top: 8px;
        width: 100%;
        padding-left: calc(48px + var(--spacing-m));
        margin-bottom: var(--spacing-2xl);
        margin-top: var(--spacing-2xl);
        list-style: none;
        z-index: 100;

        .newMessageDividerInner {
          display: flex;
          justify-content: flex-end;
          width: 100%;
          padding: var(--spacing-xs) 0;
          padding-right: var(--spacing-l);
          background-color: var(--white);
          border-radius: var(--border-radius);
          color: var(--error);
          font-weight: 500;
          font-size: var(--font-size-base);
          box-shadow: var(--box-shadow);
          border: 2px dashed var(--main-color-separator);
        }
      }
    }
  }
}

@media (max-width: 575px) {
  .messageContainer {
    padding: 0 var(--spacing-xl);
    padding-bottom: 186px;

    > .inner {
      .dateSeparator {
        padding-left: 0;
      }
      .messagesGroup {
        .newMessageDividerContainer {
          padding-left: 0;

          .newMessageDividerInner {
            justify-content: center;
          }
        }
      }
    }
  }
}

@keyframes FadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
