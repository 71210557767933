@import "@styles/mixins.scss";

.container {
  width: 100%;
  position: relative;
  display: grid;
  grid-gap: var(--spacing-m);
  column-gap: var(--spacing-m);
  grid-template-columns: repeat(auto-fit, minmax(450px, 1fr));
}
.emptyData {
  @include flexContainer();
  @include borderedContainer(var(--main-border));
  padding: var(--spacing-2xl);
  width: 100%;
  height: 100%;
  min-height: 240px;
  color: var(--muted-text);
  background-color: var(--white);
}

@media (max-width: 1919px) {
  .container {
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  }
}

@media (max-width: 1439px) {
  .container {
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  }
}

@media (max-width: 767px) {
  .container {
    grid-template-columns: minmax(240px, 1fr);
  }
}
