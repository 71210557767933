@import "@styles/mixins.scss";

.container {
  @include flexContainer(row, flex-start, center);

  width: 100%;
  padding: var(--spacing-xs) var(--spacing-m);
  border-radius: var(--border-radius);
  border: 1px solid var(--main-color-border);
  column-gap: var(--spacing-m);

  .iconWrapper {
    width: 42px;
    min-width: 42px;
    height: auto;

    > svg {
      width: 100%;
      height: auto;
    }
  }

  > p {
    color: var(--muted-text);
    font-size: var(--font-size-main);
    font-weight: 500;

    > span {
      font-weight: 400;
      color: var(--primary);
      margin-left: var(--spacing-xs);
    }

    &:nth-child(2) {
      margin-right: auto;
    }
  }
}

@media (max-width: 767px) {
  .container {
    flex-direction: column;
    row-gap: var(--spacing-xs);

    > p {
      &:nth-child(2) {
        margin-right: 0;
      }
    }
    .iconWrapper {
      width: 64px;
      min-width: 64px;
    }
  }
}
