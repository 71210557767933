@import "@styles/mixins.scss";

.chart {
  position: relative;
  background-color: var(--white);
  padding: var(--spacing-l);
  width: 80vw;
  border-radius: var(--border-radius);
  display: grid;
  grid-template-rows: min-content 1fr;
  grid-auto-columns: minmax(200px, 1fr);
  overflow-y: auto;
  max-height: 100dvh;
  max-height: 100vh;

  .wrapper {
    @include borderedContainer;
    padding: var(--spacing-l);
    // overflow: scroll;
  }
  .heading {
    margin-bottom: var(--spacing-l);
  }

  .close {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    width: 50px;
    height: 50px;
    font-size: 0;
    cursor: pointer;
    z-index: 50;

    &:before,
    &:after {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 2px;
      height: 25px;
      background-color: var(--gray-3);
      transform: rotate(45deg) translate(-50%, -50%);
      transform-origin: top left;
      transition: all 420ms;
      content: "";
    }
    &:after {
      transform: rotate(-45deg) translate(-50%, -50%);
    }
    &:hover:before,
    &:hover:after {
      background-color: var(--primary);
    }
  }
}

@media (max-width: 991px) {
  .chart {
    .wrapper {
      height: 100%;
      overflow-y: auto;
    }
  }
}

@media (max-width: 575px) {
  .chart {
    width: 95vw;
    height: 80vh;
  }
}
