@import "@styles/mixins.scss";

.container {
  @include flexContainer();
  width: 100%;
  height: 100vh;
  height: 100dvh;
  padding: var(--spacing-7xl);
  overflow: hidden;
  background-size: cover;

  .inner {
    @include flexContainer(column);
    width: 100%;
    height: 100%;
    padding: var(--spacing-l);
    background-color: var(--white);
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
    animation: var(--slide-up-animation);

    > h1 {
      font-size: var(--font-size-large);
      font-weight: 600;
      color: var(--primary);
      margin-bottom: var(--spacing-m);
    }
    .textMessage {
      font-size: var(--font-size-main);
      font-weight: 500;
      color: var(--muted-text);
      margin-bottom: var(--spacing-m);
      max-width: 330px;
      text-align: center;
    }
    .iconWrapper {
      display: flex;
      margin-bottom: var(--spacing-4xl);
      max-width: 700px;
      width: 100%;
      min-width: 150px;
    }
  }
}
