.radioSelect {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  list-style-type: none;
  padding: 0;
  margin: 0;
  border-radius: var(--border-radius);
  overflow: auto;
  max-height: 250px;
  font-size: 12px;
  border-top: 1px solid var(--main-color-border-2);
  border-left: 1px solid var(--main-color-border-2);
}

.option {
  padding: 7px 10px;
  text-transform: uppercase;
  cursor: pointer;
  border-bottom: 1px solid var(--main-color-border-2);
  border-right: 1px solid var(--main-color-border-2);
  flex-grow: 1;
  text-align: center;
  transition: all 0.3s;
  &.selected {
    background-color: var(--primary);
    color: #fff;
  }
  &:hover {
    background-color: var(--secondary-hover);
  }
}
