@import "@styles/mixins";

.container {
  @include flexContainer();

  width: 100%;
  gap: var(--spacing-xs);
  flex-wrap: wrap;
  position: relative;
  width: 100%;
  padding: var(--spacing-m);
  background: var(--white);
  border-radius: var(--border-radius);
  border: 1px solid var(--main-color-border);

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: center;
  }

  .imageSection {
    @include flexContainer();

    width: 100%;
    max-width: 400px;

    > svg {
      height: auto;
      width: 100%;
      max-width: 250px;
    }
  }

  .uploadSection {
    @include flexContainer(column);

    width: 100%;
    max-width: 400px;
    width: 100%;

    h3 {
      font-size: var(--font-size-medium);
      font-weight: 500;
      margin-bottom: var(--spacing-2xl);
    }
    .uploadButton {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 300px;
      gap: var(--spacing-m);
      margin-top: var(--spacing-m);

      &:last-child {
        margin-top: var(--spacing-2xl);
      }
      @media (max-width: 575px) {
        flex-direction: column;
        align-items: center;
      }
    }
  }
}
