@import "@styles/mixins";

.tableContainer {
  --header-row-height: 50px;

  @include flexContainer(column, flex-start, flex-start);

  display: block;
  height: 100%;
  overflow-x: auto;
  width: 100%;
  // min-height: 100%;

  &.empty {
    overflow-x: hidden;
  }

  .emptyData {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: var(--spacing-2xl);
    width: 100%;
    height: calc(100% - var(--header-row-height));
    min-height: 170px;
    color: var(--muted-text);
    background-color: var(--white);
    border-radius: var(--border-radius);
    border: 1px solid var(--main-color-border);
  }
}
.table {
  width: 100%;
  border-collapse: collapse;
  color: var(--main-color);
  thead {
    position: sticky;
    top: 0;
    z-index: 10;
  }
  &.small {
    > tbody {
      font-size: var(--font-size-small);
    }
    > thead {
      font-size: var(--font-size-base);
    }
    .tableRowCell {
      padding: var(--spacing-xxs) 0 var(--spacing-xxs) var(--spacing-m);
    }
  }
  &.medium {
    > tbody {
      font-size: var(--font-size-base);
    }
    > thead {
      font-size: var(--font-size-base);
    }
    .tableRowCell {
      padding: var(--spacing-xs) 0 var(--spacing-xs) var(--spacing-m);
    }
  }
}
.tableHeader {
  display: grid;
  width: 100%;
  text-align: left;
}
.tableHeaderCell {
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--spacing-s) 0 var(--spacing-s) var(--spacing-m);
  font-weight: 600;
  overflow: hidden;
  background-color: var(--sidebar-menu);
}
.tableHeaderSeparator {
  display: inline-block;
  border-right: none;
  margin-left: auto;
  height: 1rem;
}
.tableHeaderCell:not(:last-child) .tableHeaderSeparator {
  border-right: 1px solid var(--table-separator);
}

.tableHeaderCell:first-child {
  border-radius: 4px 0 0 4px;
}
.tableHeaderCell:last-child {
  border-radius: 0 4px 4px 0;
}

.tableRow {
  display: grid;
  width: 100%;
  text-align: left;
  transition: 0.3s;
  cursor: pointer;

  &.notClickable {
    cursor: default;
  }
}
.tableRow .tableRowCell {
  border-bottom: solid 1px var(--main-border);
}
.tableRow:last-child .tableRowCell {
  border-bottom: none;
}
.tableRowCell {
  display: flex;
  width: 100%;
  padding: var(--spacing-s) 0 var(--spacing-s) var(--spacing-m);
  background: transparent;
}
.tableRow:hover .tableRowCell {
  background-color: var(--table-hedaer-fixed-bg);
  // transition: all 0.3s;
}

/* fixed columns */
.tableHeader > .tableHeaderCell:first-child,
.tableHeaderCell:first-child,
.tableRow > .tableRowCell:first-child {
  position: sticky;
  left: 0;
  z-index: 1;
}
.tableHeader > .tableHeaderCell {
  background-color: var(--sidebar-menu);
}
.tableRow > .tableRowCell {
  background-color: #fdfeff;
}

.tableRow > .tableRowCell.showScroll:first-child {
  background-image: linear-gradient(-90deg, #c7c7c780, #fdfeff 15px);
}
.tableRow:hover > .tableRowCell.showScroll:first-child {
  background-image: linear-gradient(-90deg, #c7c7c780, var(--table-hedaer-fixed-bg) 15px);
}
.tableRowCell .truncated {
  display: flex;
  align-items: center;
  width: 100%;
}
.tableRowCell .truncated > span {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
}
.tableRowCell .simpleCell,
.tableRowWrappwer .simpleCell {
  display: flex;
  align-items: center;
  width: 100%;
}

/* table action cell  */

.actionCell {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  &.active .actionTitle {
    text-decoration: underline;
    cursor: pointer;
  }
  &.active:hover {
    .actionTitle {
      color: var(--secondary);
    }
    .actionInner {
      display: block;
      animation: Show 0.3s ease-in-out;
      opacity: 1;
    }
  }
  .actionTitle {
    color: var(--main-color);
    transition: 0.3s;
  }
  .actionInner {
    position: absolute;
    left: 100%;
    padding-left: var(--spacing-xs);
    min-width: 250px;
    display: none;
    opacity: 0;
    bottom: 0;

    .actionList {
      padding: var(--spacing-xs) var(--spacing-m);
      border-radius: var(--border-radius);
      background-color: var(--white);
      box-shadow: var(--box-shadow);
      width: 100%;
      list-style: none;
      transition: 0.3s;

      > li {
        color: var(--secondary);
        font-size: var(--font-size-base);
      }
    }
  }
}

.cellText {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding-right: var(--spacing-m);
}

.cellTextExpandTable {
  padding-right: var(--spacing-m);
  font-size: var(--font-size-small);
}

.arrowUp {
  transition: all 0.3s;
  transform: rotate(0deg);
}
.arrowDown {
  transition: all 0.3s;
  transform: rotate(180deg);
}

.arrow {
  margin-left: 8px;
  margin-right: 8px;
  align-self: center;
}

.tableRowWrappwer {
  display: flex;
  justify-content: space-between;
  min-width: -webkit-fill-available;
}

.arrowsGroup {
  display: flex;
  flex-direction: column;
  height: 16px;
  width: 44px;
  margin-left: auto;
  padding-left: var(--spacing-m);
  padding-right: var(--spacing-m);
  cursor: pointer;
}
.arrowsGroup ~ .tableHeaderSeparator {
  margin-left: 0;
}
.sortUp > path:nth-child(1) {
  opacity: 0.3;
}
.sortDown > path:nth-child(2) {
  opacity: 0.3;
}
.sortDisabled {
  opacity: 0.3;
}

.positive {
  color: var(--success);
}
.negative {
  color: var(--error);
}
.novalue {
  opacity: 0.3;
}
.expand {
  opacity: 1;
  visibility: visible;
  transition: all 0.3s;
  overflow: hidden;
}
.expandHide {
  opacity: 0;
  visibility: collapse;
  transition: all 0.3s;
}

@keyframes Show {
  0% {
    opacity: 0;
    transform: translateX(50px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
