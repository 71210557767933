@import "@styles/mixins.scss";

.container {
  margin-top: var(--spacing-m);
  padding: var(--spacing-l);
  border-radius: var(--border-radius);
  background-color: var(--white);
  border: 1px solid var(--main-color-separator);
}

.noDataContainer {
  @include borderedContainer(transparent);
  box-shadow: var(--box-shadow);
  padding: var(--spacing-l);

  .subTitle {
    font-weight: 600;
    margin-bottom: var(--spacing-xs);
  }
}
