@import "@styles/mixins";

.labelWrapper {
  @include flexContainer(row, flex-start, center);

  column-gap: var(--spacing-xs);
  opacity: 0.8;

  .infoIconContainer {
    @include flexContainer(row, flex-start, center);

    width: 18px;
    height: auto;
    transition: 0.1s;
    cursor: pointer;
    opacity: 0.5;

    &:hover {
      opacity: 1;
    }
    > svg {
      width: 100%;
      height: auto;

      > path {
        fill: var(--warning);
      }
    }
  }
}
