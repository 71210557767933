.sliderContainer {
  display: flex;
  flex-direction: column;
  min-width: 45%;
  max-width: 100%;
  flex-grow: 1;

  .labaelText {
    font-weight: 500;
  }
  .sliderWrapper {
    min-width: 200px;
    margin-top: var(--spacing-xs);
    margin-bottom: var(--spacing-xs);
    padding-bottom: var(--spacing-m);

    &:hover .thumb {
      color: var(--primary);

      .thumbLabel {
        opacity: 1;
      }
    }

    &.disabled {
      opacity: 0.3;
      pointer-events: none;
    }

    &.disabled .active {
      background-color: var(--gray-4);
    }

    .labelsContainer {
      display: flex;
      width: 100%;
      justify-content: space-between;
      font-size: var(--font-size-small);
      padding-bottom: var(--spacing-m);
      color: var(--secondary);

      > span:nth-child(2) {
        color: var(--primary);
        font-weight: 600;
        font-size: var(--font-size-base);
      }
    }
    .slider {
      position: relative;
      display: flex;
      align-items: center;

      &:hover {
        cursor: pointer;
      }
    }
    .thumb {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 28px;
      min-width: 36px;
      padding: 0 var(--spacing-xxs);
      width: min-content;
      text-align: center;
      background-color: var(--main-color);
      border-radius: 8px;
      border: 3px solid var(--white);
      color: var(--white);
      font-size: var(--font-size-small);
      cursor: grab;
      transition: color 0.3s;

      // &:hover .thumbLabel {
      //   opacity: 1;
      // }

      .thumbLabel {
        display: flex;
        justify-content: center;
        position: absolute;
        top: calc(100% + 8px);
        min-width: 34px;
        border-radius: var(--border-radius);
        background-color: var(--secondary);
        padding: 6px var(--spacing-xs);
        color: var(--white);
        transition: 0.3s;
        opacity: 0;
        font-size: var(--font-size-base);

        &::before {
          position: absolute;
          content: "";
          top: -4px;
          width: 12px;
          height: 12px;
          background-color: var(--secondary);
          transform-origin: center;
          transform: rotate(45deg);
          transition: 0.3s;
        }
      }
    }
    .track {
      background-color: var(--main-border);
      height: 8px;
      border-radius: 4px;
    }
    .active {
      background-color: var(--secondary);
    }
  }
}
