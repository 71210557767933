.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: var(--container-bg);
  box-shadow: var(--box-shadow);
  animation: var(--slide-up-animation);
  min-height: var(--inner-content-min-height);

  .loaderContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  .innerContainer {
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: var(--spacing-l);
    border-radius: var(--border-radius);

    .subHeading {
      margin-bottom: var(--spacing-m);
      font-size: var(--font-size-medium);
    }
    .headingNavWrapper {
      margin-bottom: var(--spacing-2xl);
    }
    .tableContainer {
      padding: var(--spacing-l);
      border: 1px solid var(--main-color-border);
      border-radius: var(--border-radius);

      &.loading {
        opacity: 0.3;
        pointer-events: none;
      }
      .tableHeding {
        display: flex;
        gap: var(--spacing-m);
        margin-bottom: var(--spacing-m);
        font-weight: 600;
        > svg {
          width: 22px;
          height: 22px;
          path {
            fill: var(--main-color);
          }
        }
      }
      .tableWraper {
        width: 100%;
      }
    }
    .navGroup {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-bottom: var(--spacing-m);

      .title {
        font-size: var(--font-size-main);
        color: var(--secondary);

        > span {
          color: var(--muted-text);
          font-weight: 600;
        }
      }
      .buttonsGroup {
        display: flex;
        align-items: center;
        column-gap: var(--spacing-m);
      }
    }
  }
}

.loaderErrorContainer,
.loaderDataContainer {
  width: 100%;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: var(--spacing-l);
  border-radius: var(--border-radius);
  border: 1px solid var(--main-border);
}
.loaderErrorContainer {
  color: var(--muted-text);

  .iconContainer {
    margin-bottom: var(--spacing-2xl);
  }
}
