.arrowsContainer {
  display: flex;
  flex-direction: column;
  width: 24px;
  height: 100%;
  font-size: 12px;
  cursor: pointer;
}

.arrowItem {
  height: 10px;
  opacity: 0.3;
}

.rotated {
  transform: rotate(180deg);
}

.active {
  opacity: 1;
  color: var(--primary);
}
