@import "@styles/mixins.scss";

.container {
  @include flexContainer(column, center, flex-start);

  position: relative;
  height: 96px;
  min-width: calc(100% / 3);
  max-width: calc(100% / 3);
  width: 100%;
  padding-right: var(--spacing-m);
  cursor: pointer;
  opacity: 0;
  transform: scale(0.6);
  transition: 0.3s;

  &.onStartAnimated {
    opacity: 1;
    transform: scale(1);
  }

  &[data-template="true"] {
    .inner > .infoContainer {
      display: none;
    }

    &:hover .inner {
      box-shadow: var(--box-shadow);
    }

    .inner {
      border: 2px solid var(--primary);

      .iconContainer {
        background-color: var(--black-opacity-10);
      }
    }
  }

  &:not([data-template="true"]):hover {
    .inner {
      box-shadow: var(--box-shadow);
      background-color: var(--card-bg-hover-color);

      > .iconContainer,
      > .textGroup {
        opacity: 0;
        transform: rotateX(-180deg);
      }
      .infoContainer {
        opacity: 1;
        transform: rotateX(0deg);
      }
    }

    [data-menu="menu-actions"] {
      background-color: var(--card-bg-hover-color);

      > div:last-child {
        background-color: var(--card-bg-hover-color);
      }
    }
  }

  .inner {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    border: 1px solid var(--black-opacity-20);
    border-radius: var(--border-radius);
    background-color: var(--white);
    padding: var(--spacing-m);
    transition: 0.3s;

    > .iconContainer,
    > .textGroup {
      transition: 0.3s;
      transform: rotateX(0);
    }

    > .textGroup {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      width: 100%;
      height: 100%;
      padding-left: var(--spacing-m);
      overflow: hidden;

      .title {
        font-weight: 500;
        font-size: var(--font-size-base);
        width: calc(100% - 16px);
        overflow: hidden;
        text-overflow: ellipsis;
        height: 1.2em;

        > span[data-match="true"] {
          background-color: var(--warning);
        }
        > span:not([data-match]) {
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .footerText {
        @include flexContainer(row, flex-start);

        column-gap: var(--spacing-l);
        width: 100%;
        font-weight: 400;
        font-size: var(--font-size-small);
        // opacity: 0.5;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        height: 18px;

        > span {
          @include flexContainer(row, flex-start);

          column-gap: var(--spacing-xxs);
          color: var(--black-opacity-30);
          .number {
            color: var(--primary);
          }
        }
      }
      .label {
        font-size: var(--font-size-base);
        font-weight: 500;
        color: var(--main-hover);
      }
    }
  }
  .infoContainer {
    display: flex;
    align-items: center;
    column-gap: var(--spacing-m);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    padding: var(--spacing-m);
    transition: 0.3s;
    opacity: 0;
    transform: rotateX(-180deg);

    .textContainer {
      overflow: hidden;
      margin-right: 8px;

      .title:first-child {
        color: var(--secondary);
        font-size: var(--font-size-base);
        font-weight: 600;
        margin-bottom: var(--spacing-s);
      }

      > p {
        font-size: var(--font-size-small);
        font-weight: 400;
        color: var(--primary);
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;

        &:not(:last-child) {
          margin-bottom: var(--spacing-xxs);
        }
        &:last-child {
          opacity: 0.5;
        }
      }
    }
    .iconContainer {
      padding: var(--spacing-s);
    }
  }

  .infoContainer > .iconContainer {
    background-color: transparent;
  }

  .inner > .iconContainer {
    @include flexContainer(column, flex-start);

    background-color: var(--secondary-row-hover);

    &[data-template="true"] {
      > svg {
        width: 32px;
        height: 32px;
      }
    }
    > span {
      color: var(--primary);
      font-size: var(--font-size-tiny);
      font-weight: 500;
      padding: 2px 4px;
    }
  }

  .infoContainer > .iconContainer,
  .inner > .iconContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--main-color);
    width: 64px;
    min-width: 64px;
    height: 64px;
    border-radius: var(--border-radius);
    > svg.overlay {
      color: var(--secondary);
    }
  }

  .favIconContainer {
    position: absolute;
    right: 22px;
    bottom: 6px;
    color: transparent;
    transition: 0.1s;
    z-index: 10;

    svg {
      transition: 0.1s;
      opacity: 0.3;

      &.active {
        color: var(--secondary);
        opacity: 1;
      }
    }

    &:hover {
      transform: scale(1.2);

      svg {
        opacity: 1;
      }
    }
  }
  .downloadActionContainer {
    position: absolute;
    display: flex;
    align-items: center;
    min-height: 22px;
    right: 23px;
    bottom: 5px;
    z-index: 20;
    opacity: 0.5;
    transition: 0.1s;

    &:not(.disabled):hover {
      opacity: 1;
      transform: scale(1.2);
    }
    &.disabled {
      opacity: 0.1;
    }
  }
}

.copied {
  opacity: 0;
  animation: fadeInScaleUp 0.3s 0.5s ease-in-out forwards;
}

@media (max-width: 991px) {
  .container {
    min-width: calc(100% / 2);
    max-width: calc(100% / 2);
  }
}

@media (max-width: 575px) {
  .container {
    min-width: 100%;
    max-width: 100%;
  }
}

@keyframes fadeOutScaleDown {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale(0.7);
  }
}

@keyframes fadeInScaleUp {
  0% {
    opacity: 0;
    transform: scale(0.8);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
