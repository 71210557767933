.container {
  --min-card-height: 39px;

  display: flex;
  align-items: center;
  width: calc(100% + var(--spacing-sx));

  .actionContainrt {
    height: var(--min-card-height);
    padding: var(--spacing-xs);
    border-radius: var(--border-radius);
    margin-right: var(--spacing-xxs);
    cursor: pointer;
    opacity: 0.5;
    transition: 0.1s;

    &:hover {
      opacity: 1;
    }

    > svg {
      transform: rotate(-90deg);
      color: var(--primary);
    }
  }

  .inner {
    display: flex;
    position: relative;
    align-items: center;
    overflow: hidden;
    width: 100%;
    padding-left: 2px;
    margin-right: var(--spacing-m);
    animation: var(--slide-up-animation);

    .wrapper {
      position: relative;
      display: flex;
      width: 100%;

      .groupContainer {
        display: flex;
      }
    }

    .cardContainer {
      display: flex;
      flex-direction: column;
      padding-right: var(--spacing-xs);

      .innerContainer {
        display: grid;
        grid-template-columns: repeat(2, minmax(var(--card-item-width), 1fr));
        min-height: var(--min-card-height);
        column-gap: var(--spacing-xxs);
        background-color: var(--white);
        border-radius: var(--border-radius);
        font-size: var(--font-size-base);
        border: 1px solid var(--main-color-separator);
        overflow: hidden;

        .row {
          display: flex;
          height: var(--min-card-height);
          flex-direction: column;
          justify-content: center;
          align-items: flex-end;
          column-gap: var(--spacing-xs);
          width: 100%;
          padding: var(--spacing-xxs) var(--spacing-xs);

          > span {
            width: 100%;
            font-weight: 500;
            animation: Appear 0.3s ease-in-out;
            white-space: nowrap;
            font-size: var(--font-size-small);
            text-align: start;
            text-overflow: ellipsis;
            overflow: hidden;

            &.fullText {
              text-overflow: unset;
              white-space: unset;
              min-height: 2.6em;
              max-height: 2.6em;
            }
          }
        }

        > span:first-child {
          width: 100%;
          height: var(--min-card-height);
          display: flex;
          align-items: center;
          padding: 7px var(--spacing-xs);
          overflow: hidden;
          font-weight: 600;
          color: var(--primary);
          border-right: 1px solid var(--main-border);

          > p {
            width: 100%;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            font-size: var(--font-size-main);
          }
        }
      }
      .noDataContainer {
        display: flex;
        width: 100%;
        min-height: var(--min-card-height);
        padding: var(--spacing-xs);
        background-color: var(--white);
        border-radius: var(--border-radius);
        font-size: var(--font-size-base);
        border: 1px solid var(--main-border);
        .inner {
          width: 100%;
          display: flex;
          background-color: var(--main-border);
          border-radius: var(--border-radius);
          overflow: hidden;
          .skeleton {
            opacity: 0;
            min-width: 200px;
            height: 100%;
            background-image: linear-gradient(90deg, transparent, white, transparent);
            animation: SkeletonLside 3s ease-in-out infinite;
            transform: translate(-500px);
          }
        }
      }
    }

    .arrow {
      position: absolute;
      width: 36px;
      height: 36px;
      border: 1px solid var(--main-border);
      z-index: 1;

      .arrowInner {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
      }
    }
  }
}

:global(.full-width).wrapper {
  .groupContainer {
    display: flex;
    width: 100%;
    min-width: 100%;
    .cardContainer {
      display: flex;
      width: calc(100% / 5);
      min-width: calc(100% / 5);
    }
  }
}
:global(.mobile-width).wrapper {
  .groupContainer {
    display: flex;
    width: 100%;
    min-width: 100%;
    .cardContainer {
      display: flex;
      width: 100%;
      min-width: 100%;
    }
  }
}

.container .inner :global(.slide) {
  animation: Slide 0.5s ease-in-out;
}
.noValue {
  color: var(--main-color);
  opacity: 0.3;
}
.positive {
  color: var(--success);

  &::before {
    content: "\25B2";
    margin-right: var(--spacing-xxs);
  }
}
.negative {
  color: var(--error);

  &::before {
    content: "\25B2";
    display: inline-block;
    margin-right: var(--spacing-xxs);
    transform: rotate(180deg);
  }
}
.zero {
  color: var(--muted-text);

  &::before {
    display: none;
  }
}

.large {
  font-size: var(--font-size-medium);
}

@keyframes Slide {
  0% {
    left: 0;
  }

  100% {
    left: -100%;
  }
}

@keyframes Slide-3 {
  0% {
    left: 0;
  }

  100% {
    left: calc(100% / 1 * -1);
  }
}

@keyframes SkeletonLside {
  0% {
    transform: translate(-500px);
  }
  20% {
    transform: translate(-500px);
    opacity: 1;
  }
  80% {
    transform: translate(500px);
  }
  100% {
    transform: translate(500px);
    opacity: 0;
  }
}

@keyframes Appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media (max-width: 991px) {
  .container {
    .actionContainrt {
      display: none;
    }
  }
  .container .inner {
    --min-card-height: 42px;

    cursor: pointer;
    .wrapper {
      .groupContainer {
        flex-direction: column;
        row-gap: var(--spacing-xs);
      }
    }
    .cardContainer {
      width: calc(100% / 1);
      min-width: calc(100% / 1);

      .innerContainer {
        flex-direction: row;

        .row {
          padding: 0 var(--spacing-xs);
          flex-direction: row;
          justify-content: flex-end;
          flex-wrap: nowrap;
          align-items: center;
          height: 100%;

          > span {
            width: auto;
            font-size: var(--font-size-base);
          }
        }
      }
    }
  }
  .container .inner :global(.slide) {
    animation-name: Slide-3;
  }
}
