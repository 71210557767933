.container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-l);
  background-color: var(--white);
  border-radius: var(--border-radius);
  border: 1px solid var(--main-border);
  padding: var(--spacing-l);

  .cahrtContainer {
    width: 40%;
    min-width: 300px;
    flex-grow: 1;

    .chartWrapper {
      border: 1px solid var(--main-color-separator);
      border-radius: var(--border-radius);
      overflow: hidden;
    }
  }
  .tableContainer {
    width: 55%;
    min-width: 300px;
    flex-grow: 1;

    .chartWrapper {
      overflow-y: auto;

      .remark {
        color: var(--secondary);
      }
    }
    .footnote {
      font-size: var(--font-size-small);
      padding-top: var(--spacing-xl);
      color: var(--muted-text);

      > span {
        color: var(--secondary);
        display: block;
        margin-bottom: var(--spacing-xxs);
      }
    }
  }

  .title {
    font-size: var(--font-size-main);
    color: var(--main-color);
    margin-bottom: var(--spacing-xs);
  }
  .nodata {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    width: 100%;
    padding: var(--spacing-m);
    border: 1px solid var(--main-color-separator);
    border-radius: var(--border-radius);
    color: var(--muted-text);
  }
}
