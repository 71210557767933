.container {
  padding: 0 10px;
  .sectionName {
    font-size: var(--spacing-m);
    font-weight: bold;
    margin-bottom: var(--spacing-xs);
  }
  .content {
    width: 250px;
  }
  .inputItem {
    margin-top: var(--spacing-xs);
    overflow: hidden;
  }
}
