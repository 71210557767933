.chartWrapper {
  width: 100%;
  min-height: 382px;

  > h2 {
    color: var(--secondary);
    font-size: var(--font-size-main);
    font-weight: 500;
    padding-left: var(--spacing-m);
    margin-bottom: var(--spacing-xs);
  }
}
.head {
  display: flex;
  margin-bottom: var(--spacing-xs);
  justify-content: space-between;
  > h2 {
    color: var(--secondary);
    font-size: var(--font-size-main);
    font-weight: 500;
    padding-left: var(--spacing-m);
    margin-right: var(--spacing-m);
  }
  .menuInner {
    display: flex;
    align-items: center;
    // margin-bottom: var(--spacing-xs);
    .icon {
      min-width: 20px;
      min-height: 20px;
    }
    span {
      margin-left: var(--spacing-sx);
    }
  }
}
