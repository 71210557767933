@import "@styles/mixins";

.container {
  --small-image-width: 80px;
  --card-icon-width: 60px;
}

.container {
  @include flexContainer(column, flex-start, center);

  width: 100%;
  flex-grow: 1;

  padding: var(--spacing-4xl);
  padding-bottom: 0;
  overflow-y: auto;
  overflow-x: hidden;

  .inner {
    @include flexContainer(column, flex-start, center);
    animation: var(--slide-up-animation);

    width: 100%;
    row-gap: var(--spacing-2xl);
    max-width: var(--max-inner-content-width);
    padding-top: var(--spacing-7xl);
    flex-grow: 1;

    .deliverySection {
      @include flexContainer(column, flex-start, flex-start);

      width: 100%;
      row-gap: var(--spacing-2xl);
      margin-bottom: var(--spacing-7xl);
      padding-top: var(--spacing-7xl);
      color: var(--white);
      animation: FadeIn 1s ease-out;

      .title {
        font-size: var(--font-size-large);
        font-weight: 600;
        width: 100%;
        text-align: center;
      }

      .cardsWrapper {
        @include flexContainer(row, center, flex-start);

        width: 100%;
        gap: var(--spacing-l);
        flex-wrap: wrap;

        .itemCard {
          @include flexContainer(column, flex-start, center);

          max-width: 250px;
          min-width: 170px;
          width: auto;
          padding: var(--spacing-l);
          background-color: var(--white-opacity-10);
          border-radius: var(--border-radius-8);
          opacity: 0;

          &.animated {
            opacity: 1;
            animation: ZoomIn 0.3s ease-out;
          }

          .iconWrapper {
            @include flexContainer(column, center);

            width: var(--card-icon-width);
            margin-bottom: var(--spacing-l);
            > svg {
              width: 100%;
              height: auto;
            }
          }
          .title {
            font-size: var(--font-size-medium);
            font-weight: 600;
            text-align: center;
            line-height: var(--line-height-small);
            width: 100%;
          }
          .description {
            font-size: var(--font-size-base);
            font-weight: 400;
            opacity: 0.7;
            text-align: center;
          }
        }
      }
    }

    .heading {
      @include flexContainer(row, space-between, center);

      column-gap: var(--spacing-l);
      width: 100%;

      .title {
        font-size: var(--font-size-extart);
        font-weight: 600;
        color: var(--white);
        line-height: var(--line-height-small);
      }
    }

    .mainSection {
      @include flexContainer(row, center, flex-start);

      width: 100%;
      row-gap: var(--spacing-6xl);
      column-gap: var(--spacing-l);

      .leftSide {
        @include flexContainer(column, flex-start, flex-start);

        width: 100%;
        row-gap: var(--spacing-2xl);
        flex-grow: 1;
        height: 100%;

        .listContainer {
          @include flexContainer(column, flex-start, flex-start);

          width: 100%;
          margin: 0;
          list-style: none;
          column-gap: var(--spacing-xs);
          animation: Spacing 0.5s ease-out;
          row-gap: var(--spacing-xs);
          flex-grow: 1;

          .listItem {
            @include flexContainer(row, flex-start, flex-start);

            width: 100%;
            height: 100%;
            padding: var(--spacing-l);
            background-color: var(--white-opacity-10);
            border-radius: var(--border-radius-8);
            position: relative;
            color: var(--white);
            font-size: var(--font-size-main);
            line-height: var(--line-height-small);
            padding-left: 32px;
            position: relative;
            opacity: 0;

            &.animated {
              opacity: 1;
              animation: ZoomIn2 0.3s ease-out;
            }

            &:before {
              position: absolute;
              content: "•";
              font-size: var(--font-size-extra-medium);
              left: 12px;
              top: 15px;
            }
          }
        }
      }
      .imageSide {
        @include flexContainer(column, flex-start, flex-start);

        width: 100%;
        height: 100%;
        flex-grow: 1;
        opacity: 0;

        &.animated {
          opacity: 1;
          animation: FadeSlideIn 0.5s ease-out;
        }

        .imageWrapper {
          width: 100%;

          .videoWrapper {
            display: flex;
            width: 100%;
            position: relative;
            height: auto;
            border-radius: var(--border-radius-8);
            cursor: pointer;
            display: none;
            margin: 0 auto;

            .front {
              @include flexContainer(row);

              position: absolute;
              inset: 0;
              border-radius: var(--border-radius-8);
              background-color: var(--white-opacity-70);
              z-index: 10;

              > svg {
                width: 40px;
                height: auto;
              }
            }
          }
          > img,
          > .videoWrapper video {
            display: flex;
            width: 100%;
            height: auto;
            border-radius: var(--border-radius-8);
          }
          > .videoWrapper video {
            object-fit: cover;
          }
          &[data-can-play="true"] {
            > .videoWrapper {
              display: flex;
            }
            > img {
              display: none;
            }
          }
        }
      }
    }

    .footerText {
      color: var(--white);
      opacity: 0.6;
      font-size: var(--font-size-small);
      width: 100%;
      text-align: end;
    }
  }
}

@media (max-width: 991px) {
  .container {
    justify-content: flex-start;

    .inner {
      justify-content: flex-start;

      .mainSection {
        flex-direction: column;
        .leftSide {
          width: 100%;
          row-gap: var(--spacing-5xl);

          .btnWrapper {
            margin: 0 auto;
          }
          .listContainer {
            flex-direction: column;
            row-gap: var(--spacing-xs);

            .listItem {
              position: relative;
              padding: var(--spacing-s) var(--spacing-l);
              padding-left: 48px;

              &::before {
                position: absolute;
                content: "•";
                font-size: var(--font-size-large);
                left: 20px;
                top: 2px;
              }
            }
          }
          .heading {
            .title {
              font-size: var(--font-size-large);
            }
          }
        }
      }
      .footerNav {
        padding: 0;
      }
    }
  }
}

@media (max-width: 767px) {
  .container {
    .inner {
      .mainSection {
        .imageSide {
          .imageWrapper {
            .videoWrapper {
              width: 100%;
            }
          }
        }
      }
      .footerText {
        text-align: center;
      }
    }
  }
}

@media (max-width: 575px) {
  .container {
    --small-image-width: 60px;

    padding: var(--spacing-4xl) var(--spacing-l);
    padding-bottom: 0;
    .inner {
      .deliverySection {
        .title {
          font-size: var(--font-size-large);
        }
        .cardsWrapper {
          flex-direction: column;
          align-items: center;
          row-gap: var(--spacing-2xl);
        }
      }
      .mainSection {
        .leftSide {
          .listContainer {
            .listItem {
              padding: var(--spacing-s) var(--spacing-l);
              padding-left: var(--spacing-8xl);
              font-size: var(--font-size-base);
            }
          }
        }
      }
    }
  }
}

@keyframes FadeSlideIn {
  0% {
    opacity: 0;
    transform: translateX(200px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

@keyframes Spacing {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes ZoomIn {
  0% {
    opacity: 0;
    transform: scale(0.3);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes ZoomIn2 {
  0% {
    opacity: 0;
    transform: scale(0.8);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
