.container,
.formContainer,
.formContainer > form {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.container {
  animation: SlideUp 0.3s ease-in-out;
  padding: 0 var(--spacing-l);
  width: 100%;
  flex-grow: 1;
  justify-content: center;
}
.formContainer {
  position: relative;
  background-color: var(--white-opacity-10);
  box-shadow: var(--box-shadow);
  min-width: 300px;
  max-width: 500px;
  width: 100%;
  padding: var(--spacing-l);
  overflow: hidden;
  border-radius: var(--border-radius);

  .form {
    width: 100%;
    margin-bottom: var(--spacing-m);
  }

  .resetButton {
    border: none;
    padding: 0 var(--spacing-xs);
    background: none;
    color: var(--white);
    cursor: pointer;
    transition: var(--base-transition-delay);

    &:hover {
      opacity: 0.6;
    }
  }
  .formInnerWrapper {
    display: flex;
    justify-content: flex-start;
    width: 100%;

    .slideGroup {
      display: flex;
      justify-content: flex-start;
      gap: var(--spacing-l);
      transition: var(--base-transition-delay);
      width: 100%;

      &.showPass {
        transform: translateX(calc(-100% - var(--spacing-l)));
      }

      .inputWrapper {
        width: 100%;
        min-width: 100%;
        margin-bottom: var(--spacing-m);
      }
    }
  }
}

.frontBg {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: var(--bg-white-opacity);
  z-index: 10;
  backdrop-filter: grayscale(1);
}

.loaderContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 30px;
  padding: var(--spacing-l);
}
.title {
  font-size: var(--font-size-extra-medium);
  color: var(--white);
  margin-bottom: var(--spacing-m);
  font-weight: 500;
}

.hidden {
  display: none;
}

@keyframes SlideUp {
  0% {
    transform: translateY(100px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
