@import "@styles/mixins";

.container {
  --footer-height: 60px;
  --arrow-width: 32px;
  --action-item-width: 40px;
}

.container {
  @include flexContainer(column, flex-start, flex-start);

  width: 100%;
  height: 100%;
  flex-grow: 1;
  font-family: "Poppins", "Archivo", sans-serif;

  background-color: var(--black-90);
  position: relative;
  overflow: hidden;
  z-index: 10;

  .videoThemContainer {
    @include flexContainer(column, center);

    width: 100%;
    height: 100%;
    flex-grow: 1;
    opacity: 1;
    transition: var(--base-transition-delay);
    animation: FadeIn 1.2s ease-in;
    overflow: hidden;
    position: absolute;
    inset: 0;

    &.mute {
      opacity: 1;
    }

    .video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .inner {
    @include flexContainer(column, flex-start, flex-start);

    width: 100%;
    flex-grow: 1;
    padding: 0 var(--spacing-9xl);
    position: absolute;
    bottom: 0;
    background-color: var(--black-opacity-10);
    backdrop-filter: blur(10px);
    transition: var(--base-transition-delay);

    &:hover {
      background-color: var(--black-opacity-55);
    }

    .action {
      @include flexContainer(column, center);

      opacity: 1;
      cursor: pointer;
      transition: var(--base-transition-delay);
      width: var(--action-item-width);
      padding: var(--spacing-xs);
      border-radius: 50%;
      background-color: var(--white-opacity-30);
      box-shadow: 0 0 20px var(--black-opacity-10);
      backdrop-filter: blur(10px);
      position: absolute;
      top: -60px;
      right: 40px;
      z-index: 200;
      transition: var(--base-transition-delay);

      &:hover {
        background-color: var(--white-opacity-70);
      }

      > svg {
        width: 100%;
        height: auto;
      }

      &::before,
      &::after {
        position: absolute;
        content: "";
        opacity: 0;
        width: 90px;
        height: 90px;
        border-radius: 50%;
        background-color: transparent;
        border: 1px solid var(--white-opacity-70);
        animation: Circles 3s infinite ease-out;
        animation-delay: 2s;
        box-shadow: 0 0 20px var(--white-opacity-30), inset 0 0 20px var(--white-opacity-30);
        transform: scale(0.4);
      }
      &::after {
        animation-delay: 2.3s;
      }
    }
  }
}

@media (max-width: 767px) {
  .container {
    .inner {
      padding: 0 var(--spacing-4xl);
    }
  }
}

@media (max-width: 575px) {
  .container {
    .inner {
      padding: 0 var(--spacing-l);
    }
    .footerText {
      text-align: center;
      padding-left: var(--spacing-xxl);
    }
  }
}

@keyframes FadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}

@keyframes Circles {
  0% {
    transform: scale(0.4);
    opacity: 0;
  }
  20% {
    transform: scale(1);
    opacity: 1;
  }
  25% {
    transform: scale(1.1);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
