.container {
  h2 {
    font-size: var(--font-size-medium);
    font-weight: 600;
    margin-bottom: var(--spacing-xs);
  }
  > form {
    background: var(--white);
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
    padding: var(--spacing-m);
  }
  .formInner {
    display: flex;
    gap: var(--spacing-l);
    width: 100%;
    margin-bottom: var(--spacing-m);

    .section {
      width: 50%;
      border-radius: var(--border-radius);
      border: 1px solid var(--main-border);
      padding: var(--spacing-m);

      h3 {
        font-size: var(--font-size-medium);
        margin-bottom: var(--spacing-m);
        color: var(--muted-text);
      }

      > div:last-child {
        padding-bottom: var(--spacing-xs);
      }
    }
    @media (max-width: 768px) {
      flex-direction: column;
      gap: var(--spacing-m);
      .section {
        width: 100%;
      }
    }
  }
  .btn {
    display: flex;
    justify-content: flex-end;
  }
}
