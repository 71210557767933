.spin {
  text-align: center;
}
.chartContainer {
  width: 100%;
  position: relative;
  background-color: white;

  .zoomButton {
    display: flex;
    align-items: center;
    position: absolute;
    top: var(--spacing-xs);
    right: var(--spacing-6xl);
    z-index: 2;
    opacity: 0.3;

    &:hover {
      opacity: 1;
    }
  }
  .logo {
    position: absolute;
    top: 14px;
    right: var(--spacing-6xl);
    z-index: 1;

    &.leftAligned {
      right: var(--spacing-xs);
    }
    &.withZoomedSpace {
      right: 200px;
    }
  }

  &:hover {
    .gauge-line-data-arrow {
      opacity: 1;
    }
  }
}

.tooltipContainer {
  min-width: 200px !important;
  width: 100% !important;
  max-width: 300px !important;

  .chartTooltip {
    width: 100%;

    &:not(:first-child) {
      margin: 0;
      display: flex;
      justify-content: flex-start;
      width: 100%;
      column-gap: 8px;

      > span {
        display: inline-block;
        &:first-child {
          font-weight: bold;
          min-width: 100px;
        }
        &:nth-child(2) {
          margin-left: auto;
          font-size: var(--font-size-base);
          color: var(--primary);
          font-weight: 500;
        }
      }
      .yoyLabel {
        font-size: var(--font-size-small);
        color: var(--primary);
        > span {
          font-weight: 500;
          font-size: var(--font-size-base);
        }
      }
    }
    &:first-child {
      width: 100%;
      padding-bottom: 4px;
      border-bottom: 1px solid silver;
      font-weight: bold;
      font-size: var(--font-size-base);
      opacity: 0.6;
    }
  }
}
