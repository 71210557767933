.topSection {
  display: grid;
  grid-template-columns: 1fr max-content;
  justify-content: space-between;
  margin-bottom: var(--spacing-l);

  .title {
    font-weight: 400;
    font-size: var(--font-size-main);
    color: var(--muted-text);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 90%;

    > span {
      font-weight: 500;
      padding-left: var(--spacing-xs);
      color: var(--primary);
    }
  }
  .menuInner {
    display: flex;
    align-items: center;
    white-space: nowrap;
    span {
      margin-left: var(--spacing-sx);
      white-space: nowrap;
    }
  }
}

@media (max-width: 575px) {
  .topSection {
    grid-template-columns: 1fr;
    grid-gap: var(--spacing-xs);
    justify-items: flex-end;

    .title {
      width: 100%;
      max-width: 100%;
    }
  }
}
