@import "@styles/mixins.scss";
.wrapper {
  width: 100%;
}
.container {
  position: relative;
  padding: var(--spacing-m);
  background-color: var(--white);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  min-height: 100px;
  .subHeading {
    margin-bottom: var(--spacing-m);
  }
  .tableSelector {
    margin-top: var(--spacing-m);
  }
  .icon {
    cursor: pointer;
    > path {
      transition: 0.3s;
    }
    &:hover {
      > path {
        stroke: var(--secondary);
      }
    }
  }
  .controls {
    @include flexContainer(row, space-between, center);
    margin-top: var(--spacing-m);
    margin-bottom: var(--spacing-m);
    .firstActionsContainer {
      display: flex;
      .rangeSelector {
        margin-right: var(--spacing-xs);
      }
    }
    .actionsContainer {
      display: flex;
      align-items: center;
      .filter {
        cursor: pointer;
      }
      .saveMenu {
        margin-right: var(--spacing-m);
        .menuInner {
          @include flexContainer(row, flex-start, flex-start);
          gap: var(--spacing-xs);
        }
      }
    }
    @media (max-width: 767px) {
      flex-direction: column;
      align-items: stretch;
      gap: var(--spacing-m);
      .firstActionsContainer {
        flex-direction: column;
        gap: var(--spacing-m);
        .rangeSelector {
          margin-right: 0;
        }
      }
      .actionsContainer {
        justify-content: flex-end;
      }
    }
  }
  .tabel {
    margin-top: var(--spacing-m);
  }
}
