.container {
  background-color: var(--white);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  padding: var(--spacing-m);
  min-height: 350px;

  .inner {
    position: relative;
    border-radius: var(--border-radius);
    border: 1px solid var(--main-border);
    padding: var(--spacing-m);
    height: 100%;
    width: 100%;

    .title {
      text-align: center;
      font-size: 17px;
      margin-bottom: var(--spacing-m);
    }

    .watermarkImageContainer {
      position: absolute;
      display: flex;
      right: var(--spacing-m);
      top: var(--spacing-m);
      width: 115px;
      height: 24px;
      opacity: 0.5;

      .watermarkImage {
        width: 100%;
        height: auto;
        opacity: 0.5;
      }
    }

    .chartContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      min-height: 200px;

      .chartsGroup {
        display: flex;
        width: 100%;
        min-height: 250px;

        &[data-highcharts-chart] :global(.bdf-chart-watermark) {
          display: none !important;
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .container .inner .chartContainer .chartsGroup {
    flex-wrap: wrap;
  }
}

@media (max-width: 575px) {
  .container .inner {
    .watermarkImageContainer {
      left: var(--spacing-m);
      right: auto;
      top: auto;
      bottom: var(--spacing-m);
      z-index: 50;
    }
  }
}
