.container {
  background: var(--white);
  padding: var(--spacing-m);
  border-radius: var(--border-radius);
  margin-top: var(--spacing-xs);
  .name {
    color: var(--secondary);
    cursor: pointer;
  }
  .statusActive {
    color: var(--secondary);
  }
  .statusInactive {
    color: var(--muted-text);
  }
  .actions {
    display: flex;
    gap: var(--spacing-m);
    .recomend {
      cursor: pointer;
      color: var(--secondary);
    }
    .download {
      cursor: pointer;
      display: flex;
      align-items: center;
    }
  }
  .delete {
    cursor: pointer;
    color: var(--error);
  }
}
