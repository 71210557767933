@import "@styles/mixins";

.container {
  @include flexContainer(column, flex-start, flex-start);

  --item-height: 70px;
  --layer-icon-width: 42px;

  width: 100%;
  border-radius: var(--border-radius);
  padding: var(--spacing-m);
  row-gap: var(--spacing-xs);
  background-color: var(--primary);
  padding-top: var(--spacing-xs);
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    // transform: scale(1.01);
    box-shadow: 0px 4px 12px var(--main-color-separator);
  }

  .rowtitle {
    @include flexContainer(row, space-between, flex-end);

    width: 100%;

    .left,
    .right {
      @include flexContainer;

      column-gap: var(--spacing-xs);
      font-weight: 500;
      color: var(--white);
      font-size: var(--font-size-main);
      overflow: hidden;

      > span:first-child {
        opacity: 0.5;
      }
      > span:nth-child(2) {
        overflow: hidden;
        width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .right {
      font-size: var(--font-size-small);
      > span:nth-child(2) {
        text-transform: uppercase;
      }
    }
  }

  .rowBlocks {
    @include flexContainer;

    width: 100%;
    height: var(--item-height);
    column-gap: var(--spacing-xs);

    .left,
    .right {
      @include flexContainer(column);

      background-color: var(--white);
      border-radius: var(--border-radius);
    }

    .left {
      width: var(--item-height);
      min-width: var(--item-height);
      height: 100%;
      row-gap: var(--spacing-xs);
      background-color: var(--secondary-hover);
      padding: var(--spacing-xs);

      .iconContainer {
        width: var(--layer-icon-width);

        > svg {
          width: 100%;
          height: auto;
        }
      }
      .textFooter {
        color: var(--primary);
        font-size: var(--font-size-small);

        > span:first-child {
          opacity: 0.5;
          color: var(--primary);
        }
      }
    }
    .right {
      width: 100%;
      height: 100%;
      overflow: hidden;
      position: relative;

      &:hover {
        .chartWrapper {
          opacity: 0;
        }
        .infoWrapper {
          opacity: 1;
        }
      }
      .chartWrapper {
        opacity: 1;
        width: 100%;
        height: 100%;
        transition: 0.3s;
        padding: var(--spacing-xxs) 0;
      }
      .infoWrapper {
        @include flexContainer(column, space-between, flex-start);

        position: absolute;
        inset: 0;
        padding: var(--spacing-xs);
        border-radius: var(--border-radius);
        padding: var(--spacing-xs);
        opacity: 0;
        transition: 0.3s;

        > p {
          font-size: var(--font-size-small);
          color: var(--muted-text);
          max-height: 2.5em;
          overflow: hidden;

          &:last-child > span {
            color: var(--secondary);
          }

          > span {
            color: var(--primary);
          }
        }
      }
    }
  }
}

.infoContainer {
  position: absolute;
  inset: 0;
  border-radius: var(--border-radius);
  padding: var(--spacing-xs);
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: var(--white);
  opacity: 0;
  transition: 0.3s;
}
