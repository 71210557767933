@import "@styles/mixins.scss";

.container {
  @include flexContainer(column, flex-start, flex-start);

  .description {
    font-size: var(--font-size-small);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }
  .content {
    margin-top: var(--spacing-m);
    width: 100%;
  }
  .labels {
    display: flex;
    column-gap: var(--spacing-xs);
    max-width: 400px;
    margin: auto;

    > span {
      font-size: 8px;
      margin-right: 3px;
      padding: var(--spacing-xxs);
      border-radius: 2px;
      border: 1px solid var(--main-color-separator);
      opacity: 0.5;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;

      :last-child {
        margin-right: 0;
      }
    }
    .selected {
      background-color: var(--main-color);
      color: var(--white);
      opacity: 1;
    }
  }

  .heading {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
    font-size: var(--font-size-base);
    width: 100%;

    > span {
      color: var(--muted-text);
      padding-right: var(--spacing-xs);
    }
  }
}

.error {
  @include flexContainer();
}
