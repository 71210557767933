.container {
  position: relative;
  animation: var(--slide-up-animation);

  .userInfoSection {
    margin-top: var(--spacing-2xl);
  }
  .editDataSection {
    margin-top: var(--spacing-2xl);
  }
}
