@import "@styles/mixins";

.container {
  @include flexContainer(row);

  padding-right: var(--spacing-l);
  height: 22px;
  position: relative;

  .iconWrapper {
    display: flex;
    cursor: pointer;

    &:hover > svg path {
      stroke: var(--secondary);
    }
    &.disabled {
      opacity: 0.3;
      pointer-events: none;
      cursor: default;
    }
    > svg {
      height: 100%;
      > path {
        transition: var(--base-transition-delay);
      }
    }
  }

  &:has(.iconWrapper:hover)[data-active="true"]::before {
    filter: brightness(1.2);
  }

  &[data-active="true"] {
    &::before {
      position: absolute;
      content: "Active";
      bottom: calc(100% - 2px);
      font-size: 12px;
      font-weight: 600;
      border-radius: var(--border-radius);
      color: var(--secondary);
      transition: var(--base-transition-delay);
    }
  }

  .innerMenu {
    @include flexContainer(column, flex-start, flex-start);

    position: absolute;
    top: calc(100% + 8px);
    right: 0;
    z-index: 100;

    .content {
      @include flexContainer(column, flex-start, flex-start);

      padding: var(--spacing-m);
      background-color: var(--white);
      box-shadow: var(--box-shadow);
      max-width: 400px;
      min-width: 150px;
      border-radius: var(--border-radius);
    }
  }
}
