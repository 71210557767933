@import "@styles/mixins";

.container {
  --max-form-width: 550px;

  overflow: hidden;
}

.container {
  @include flexContainer(column, flex-start, flex-start);

  width: 100%;
  flex-grow: 1;
  color: var(--white);
  padding: var(--spacing-4xl);
  padding-bottom: 0;

  .inner {
    @include flexContainer(column, flex-start, center);

    width: 100%;
    padding-top: var(--spacing-7xl);
    row-gap: var(--spacing-l);
    flex-grow: 1;
    max-width: var(--max-inner-content-width);
    margin: 0 auto;
    animation: var(--slide-up-animation);

    .formContainer {
      @include flexContainer(column, center);

      max-width: var(--max-form-width);
      width: 100%;
      height: 100%;
      padding-bottom: var(--spacing-4xl);

      .loader {
        @include flexContainer(row, center);

        width: 100%;
        height: var(--spacing-4xl);
        padding: var(--spacing-l) 0;
      }
      .mainForm {
        @include flexContainer(column, flex-start, stretch);

        width: 100%;
        background-color: var(--white-opacity-10);
        padding: var(--spacing-l);
        border-radius: var(--border-radius);

        .title {
          font-size: var(--font-size-extra-medium);
          font-weight: 600;
          line-height: var(--line-height-small);
          text-transform: capitalize;
          margin-bottom: var(--spacing-l);
          text-align: center;
          width: 100%;
        }

        .fieldWrapper {
          @include flexContainer(row, stretch, flex-start);

          width: 100%;
          column-gap: var(--spacing-xs);

          .btnWrapper {
            padding-top: 2px;
          }
        }
      }
    }
  }
}

.container {
  &.inverse {
    align-items: center;
    height: calc(100dvh - var(--header-height));
    padding: var(--spacing-l);

    .inner {
      padding: 0;
      margin: 0;
      width: 100%;

      .formContainer {
        .mainForm {
          background-color: var(--white);
          box-shadow: var(--box-shadow);

          .title {
            color: var(--primary);
          }
        }
      }
    }
  }
}

@media (max-width: 575px) {
  .container {
    .inner {
      .formContainer {
        .mainForm {
          .fieldWrapper {
            flex-direction: column;
            column-gap: var(--spacing-m);

            .btnWrapper {
              display: flex;
              width: 100%;
            }
          }
        }
      }
    }
  }
}
