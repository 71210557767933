.container {
  --icon-size: 64px;
}

.container {
  position: relative;
  display: flex;
  align-items: center;
  padding-right: var(--spacing-m);
  width: 100%;
  height: 96px;
  opacity: 0;
  transform: scale(0.6);
  transition: 0.3s;

  &.onStartAnimated {
    opacity: 1;
    transform: scale(1);
  }

  &:not([data-template="true"]):hover {
    .inner {
      box-shadow: var(--box-shadow);
      background-color: var(--card-bg-hover-color);

      .innerContainerHovered {
        opacity: 1;
        transform: rotateX(0deg);
      }
      .innerContainer {
        opacity: 0;
        transform: rotateX(180deg);
      }
    }

    [data-menu="menu-actions"] {
      background-color: var(--card-bg-hover-color);

      > div:last-child {
        background-color: var(--card-bg-hover-color);
      }
    }
  }

  &[data-template="true"] {
    &:hover {
      .inner {
        box-shadow: var(--box-shadow);
      }
    }
    .inner {
      border: 2px solid var(--primary);

      .innerContainer .portfolioIcon {
        row-gap: 2px;
        background-color: var(--black-opacity-10);
        > svg {
          height: 32px;
          width: 32px;
        }
        > .label {
          font-size: var(--font-size-tiny);
          color: var(--primary);
          font-weight: 500;
        }
      }
    }
  }

  .inner {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: var(--white);
    border: 1px solid;
    border-color: var(--black-opacity-20);
    border-radius: var(--border-radius);
    transition: all 0.3s;
    width: 100%;

    .template {
      position: absolute;
      padding: 2px 10px;
      background-color: var(--secondary);
      border-radius: var(--border-radius);
      font-size: var(--font-size-base);
      color: var(--white);
      top: -10px;
      margin-left: 50%;
      transform: translate(-50%);
      z-index: 5;
    }
    a {
      text-decoration: none;
      display: flex;
      align-items: center;
    }

    .innerContainer {
      position: absolute;
      display: flex;
      padding: var(--spacing-m);
      opacity: 1;
      transition: all 0.3s;
      width: 100%;

      .info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 0 var(--spacing-m) 0 var(--spacing-m);
        width: calc(100% - var(--icon-size) - 20px);

        .title {
          font-weight: 500;
          font-size: var(--font-size-base);
          color: var(--main-color);
          width: 100%;
          overflow: hidden;
          display: flex;

          > span[data-match="true"] {
            background-color: var(--warning);
          }
          > span {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            display: block;
          }
        }
        .label {
          font-size: var(--font-size-base);
          color: var(--main-hover);
          font-weight: 500;
        }
        .index {
          font-weight: 400;
          font-size: var(--font-size-small);
          color: var(--gray-4);
          > span {
            color: var(--primary);
          }
        }
      }
      .portfolioIcon {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: var(--icon-size);
        min-width: var(--icon-size);
        height: var(--icon-size);
        align-self: center;
        background-color: var(--secondary-hover);
        border-radius: var(--border-radius);
        color: var(--secondary);

        > svg {
          width: 40px;
          height: 40px;
        }
      }
    }
    .innerContainerHovered {
      position: absolute;
      opacity: 0;
      display: flex;
      padding: var(--spacing-m);
      transition: all 0.3s;
      transform: rotateX(-180deg);
      height: calc(100% - 4px);
      width: calc(100% - 18px);
      overflow: hidden;
      z-index: 5;
      background-color: var(--card-bg-hover-color);

      .infoHovered {
        display: flex;
        flex-direction: column;
        padding: 0 var(--spacing-m) 0 var(--spacing-m);
        height: 100%;
        width: calc(100% - var(--icon-size));

        > p {
          font-style: normal;
          font-weight: 500;
          width: 100%;
          font-size: var(--font-size-base);
          color: var(--secondary);
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          > span {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: block;
          }
        }
        > span {
          font-style: normal;
          font-weight: 400;
          font-size: var(--font-size-small);
          color: var(--primary);
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          line-height: var(--line-height-small);

          &:last-child {
            opacity: 0.5;
          }
          &:nth-child(2) {
            margin-top: auto;
          }
        }
      }
      .hoveredDragIcon {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: var(--icon-size);
        min-width: var(--icon-size);
        height: var(--icon-size);
        align-self: center;
        background-color: var(--secondary-hover);
        border-radius: var(--border-radius);
        color: var(--secondary);

        > svg {
          width: 40px;
          height: 40px;
        }
      }
    }
  }
  .favIconContainer {
    position: absolute;
    right: 22px;
    bottom: 6px;
    color: transparent;
    transition: 0.3s;
    cursor: pointer;
    z-index: 10;

    svg {
      transition: 0.1s;
      opacity: 0.3;

      &.active {
        color: var(--secondary);
        opacity: 1;
      }
    }

    &:hover {
      transform: scale(1.3);
    }
  }
}

.copied {
  opacity: 0;
  animation: fadeInScaleUp 0.3s 0.7s ease-in-out forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeInScaleUp {
  0% {
    opacity: 0;
    transform: scale(0.8);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
