.container {
  background: var(--white);
  padding: var(--spacing-m);
  border-radius: var(--border-radius);
  margin-top: var(--spacing-xs);
  box-shadow: var(--box-shadow);

  .companyName {
    color: var(--secondary);
  }
  .statusActive {
    color: var(--secondary);
  }
  .statusInactive {
    color: var(--muted-text);
  }
  .comment {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}
