@import "@styles/mixins";

.container {
  width: 100%;
  .rowGroup {
    @include flexContainer(row, space-between, flex-end);

    margin-top: var(--spacing-l);
  }
}
