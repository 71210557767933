@import "@styles/mixins";

.container {
  --tab-item-height: 48px;
  color: var(--white);
}

.container {
  @include flexContainer(column, flex-start, center);

  width: 100%;
  flex-grow: 1;

  padding: var(--spacing-4xl) var(--spacing-9xl);
  padding-bottom: 0;
  overflow-y: auto;
  overflow-x: hidden;

  .inner {
    @include flexContainer(column, flex-start, stretch);

    width: 100%;
    flex-grow: 1;
    padding-top: var(--spacing-7xl);
    max-width: var(--max-inner-content-width);
    animation: var(--slide-up-animation);

    .actionNav {
      @include flexContainer(row, center);

      width: 100%;
      margin-bottom: var(--spacing-2xl);
      .btnWrapper {
        display: flex;
        width: 200px;

        button {
          font-weight: 600;
          min-width: 200px;

          > svg {
            max-width: 18px;
            width: 18px;
            min-width: 18px;
          }
        }
      }
    }
    .tabsNavigation {
      @include flexContainer(row, flex-start, flex-start);

      width: 100%;
      overflow: hidden;
      height: var(--tab-item-height);
      position: relative;
      // top: 3px;

      .navList {
        @include flexContainer(row, flex-start, flex-start);

        flex-grow: 1;
        column-gap: var(--spacing-xs);
        padding: 0;
        margin: 0;
        list-style: none;
        width: 100%;
        overflow-x: auto;
        overflow-y: hidden;
        height: min-content;
        padding-bottom: var(--spacing-xs);

        .listItem {
          padding: var(--spacing-xs) var(--spacing-l);
          border: 1px solid var(--white);
          border-radius: var(--border-radius);
          color: var(--white);
          font-size: var(--font-size-main);
          font-weight: 400;
          width: 100%;
          text-align: center;
          transition: var(--base-transition-delay);
          cursor: pointer;
          white-space: nowrap;

          &:hover {
            background-color: var(--white-opacity-30);
          }
          &.active {
            background-color: var(--white);
            color: var(--black-90);
            min-height: var(--tab-item-height);
            border-radius: var(--border-radius) var(--border-radius) 0 0;
          }
        }
      }
    }
    .contentDescription {
      @include flexContainer(column, flex-start, flex-start);

      flex-grow: 1;

      width: 100%;
      min-width: 550px;
      border: 1px solid var(--white);
      border-radius: 0 0 var(--border-radius) var(--border-radius);
      padding: var(--spacing-l);
      margin-bottom: var(--spacing-7xl);

      .wrapper {
        @include flexContainer(column, flex-start, flex-start);

        flex-grow: 1;
        width: 100%;
        animation: fadeIn var(--base-transition-delay) ease-in-out;

        .loader {
          @include flexContainer(row, center);

          width: 100%;
          padding: var(--spacing-m) 0;
        }

        .title {
          font-weight: 600;
          font-size: var(--font-size-large);
          margin-bottom: var(--spacing-m);
        }
        .description {
          font-size: var(--font-size-medium);
          font-weight: 400;
          text-align: justify;
          line-height: var(--line-height-base);
        }
        .descriptionList {
          @include flexContainer(column, flex-start, flex-start);

          width: 100%;
          list-style: disc;
          padding: var(--spacing-l) var(--spacing-2xl);
          margin: 0;

          .descriptionItem {
            font-size: var(--font-size-medium);
            font-weight: 400;
            color: var(--white);
          }
        }
        .sliderContainer {
          width: 100%;
          border-radius: var(--border-radius);
        }
      }
    }
    .footerText {
      font-size: var(--font-size-small);
      opacity: 0.6;
    }
  }
}

@media (max-width: 991px) {
  .container {
    padding: var(--spacing-4xl);
    padding-bottom: 0;
    .inner {
      .contentDescription {
        min-width: 100%;
      }
    }
  }
}

@media (max-width: 575px) {
  .container {
    padding: var(--spacing-2xl) var(--spacing-l);
    padding-bottom: 0;

    .inner {
      .contentDescription {
        min-width: 100%;

        .wrapper {
          .title {
            font-size: var(--font-size-extra-medium);
          }
          .description {
            font-size: var(--font-size-main);
          }
          .descriptionList .descriptionItem {
            font-size: var(--font-size-base);
          }
        }
      }
    }
  }
}
