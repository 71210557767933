@import "@styles/mixins";

.container {
  @include flexContainer(row);
  column-gap: var(--spacing-xl);

  &:hover {
    .link {
      opacity: 1;
      transform: translateX(0);
    }
  }
  .link {
    opacity: 0;
    transition: var(--base-transition-delay);
    transform: translateX(-8px);
    cursor: pointer;
    width: 20px;

    > svg {
      width: 100%;
      height: 100%;
    }
  }
}
