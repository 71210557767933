// Panel.module.scss

.panel {
  border: 1px solid #d9d9d9;
  border-radius: var(--border-radius);
  margin-bottom: 8px;
  // overflow: auto;
}

.header {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 12px 16px;
  cursor: pointer;
  background-color: var(--gray);
  .arrow {
    display: flex;
    align-items: center;
    transform: rotate(-180deg);
    &.active {
      transform: rotate(0deg);
    }
  }

  &:hover {
    background-color: var(--secondary-hover);
  }
}

.content {
  padding: 16px;
}
