.container {
  position: relative;
}
.dropdownContainer {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0px 40px 0px 20px;
  cursor: pointer;
  border-radius: var(--border-radius);
  transition: 0.1s;

  &:first-child {
    padding: 0px 40px 0px 0px;
  }
  .iconContainer {
    display: flex;
    margin-right: 10px;
  }

  .loadingWrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.selectedItem {
  width: 100%;
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: var(--white);
  font-size: var(--font-size-main);
}
.label {
  position: relative;
  color: var(--primary);
  opacity: 0.8;
  font-size: var(--font-size-main);
  margin-bottom: var(--spacing-xs);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  &.required::after {
    content: "(Required)";
    font-size: var(--font-size-small);
    color: var(--error);
    margin-left: 8px;
  }
}
.selected {
  color: var(--white);
}
.disable {
  pointer-events: none;
  opacity: 0.2;
}
.listContainer {
  position: absolute;
  left: 0;
  top: calc(100%);
  width: 100%;
  max-height: 200px;
  width: fit-content;
  padding-top: 4px;
  max-height: 0;
  z-index: 50;
  transition: max-height, opacity 0.3s;
  cursor: pointer;
  opacity: 0;
  overflow: hidden;
  pointer-events: none;
}
.container.open .listContainer {
  opacity: 1;
  max-height: 200px;
  overflow: unset;
  pointer-events: all;
}
.container.open .dropdownContainer {
  outline: 1.3px solid var(--primary);
}
.listContainer .wrapper {
  width: 100%;
  box-shadow: var(--box-shadow);
  min-height: min-content;
  max-height: 200px;
  overflow-y: auto;
  border-radius: var(--border-radius);
}
.list {
  list-style: none;
  padding: var(--spacing-xs) 0;
  background-color: var(--white);
  min-height: min-content;

  .subItem {
    display: block;
    padding: var(--spacing-xs) var(--spacing-m);
    padding-left: var(--spacing-2xl);
    transition: 0.3s;
    color: var(--primary);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: var(--font-size-base);
    user-select: none;
    background-color: var(--main-color-border);

    &:hover {
      background-color: var(--secondary-hover);
    }
    &.active {
      background-color: var(--primary);
      color: var(--white);
    }
  }
  .listItem {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: var(--spacing-xs) var(--spacing-m);
    transition: 0.3s;
    color: var(--primary);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    user-select: none;

    &.subitemsLablel {
      font-weight: 400;
      padding-right: var(--spacing-2xl);
      background-color: var(--main-color-border);
      cursor: default;
      color: var(--muted-text);
    }
    &:hover {
      background-color: var(--secondary-hover);
    }
    &.active {
      background-color: var(--primary);
      color: var(--white);
    }
    .iconContainer {
      position: absolute;
      right: var(--spacing-m);
      width: 10px;
      height: auto;
      transform-origin: center;
      transition: 0.3s;

      > svg {
        width: 100%;
        height: auto;
      }
      &.open {
        transform: rotate(180deg);
      }
    }
  }
}

.dropdownContainer:before,
.dropdownContainer:after {
  content: "";
  position: absolute;
  width: 7px;
  border-bottom: 2px solid var(--white);
  border-radius: 4px;
  transform-origin: center center;
  transition: 0.3s;
}

.dropdownContainer:before {
  right: 24px;
  transform: rotate(45deg);
}

.dropdownContainer:after {
  right: 20px;
  transform: rotate(-45deg);
}

.container.open .dropdownContainer:before {
  transform: rotate(-45deg);
}

.container.open .dropdownContainer:after {
  transform: rotate(45deg);
}
