.radioButtons {
  display: flex;
}

.radioButtonLabel {
  width: 100%;
  margin-right: 10px;
  @media (max-width: 767px) {
    margin-right: 0;
    &:first-child {
      margin-right: 10px;
    }
  }
}

.radioButtonLabel input[type="radio"] {
  display: none;
}

.radioButtonLabel input[type="radio"] + .button {
  width: 130px;
  padding: 11px 12px;
  background-color: var(--white);
  border: none;
  border-radius: var(--border-radius);
  cursor: pointer;
  border: 1px solid var(--main-color);
  display: flex;
  align-items: center;
  span {
    margin-left: var(--spacing-sx);
    font-size: var(--font-size-base);
  }
  @media (max-width: 767px) {
    width: 100%;
  }
}

.radioButtonLabel input[type="radio"]:checked + .button {
  background-color: var(--main-color);
  color: var(--white);
}

.radioButtonLabel input[type="radio"]:disabled + .button {
  background-color: var(--main-color-border);
  color: var(--white);
  border: 1px solid var(--main-color-border-2);
  cursor: not-allowed;
  svg {
    path {
      fill: white;
    }
  }
}

.radioButtonLabel input[type="radio"]:hover + .button {
  transition: all 0.3s;
  opacity: 0.8;
}
