.container {
  position: relative;
  padding: 0;
  animation: var(--slide-up-animation);
}
.innerContainer {
  width: 100%;
}
.mainSection {
  position: relative;
}
.sectionTitle {
  font-size: var(--font-size-main);
  font-weight: 600;
  margin-bottom: var(--spacing-m);
  width: 100%;
  border-bottom: 1px solid #eef0f4;
}
.heading {
  margin-bottom: var(--spacing-m);
  font-size: var(--font-size-medium);
}
.mainHeading {
  margin-bottom: var(--spacing-2xl);
  font-size: var(--font-size-extra-medium);
  color: var(--secondary);
}
.heading .label {
  width: 30%;
  min-width: min-content;
  margin-top: auto;
  font-size: 14px;
  opacity: 0.5;
  font-weight: 400;
  white-space: nowrap;
}
.dropdownRow {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  min-width: min-content;
  margin-bottom: var(--spacing-m);
}
.dropdownWrapper {
  width: 30%;
  min-width: min-content;
}
.loader {
  margin-left: auto;
  padding-right: calc(50px - 10px);
}

.infoTableSection {
  margin-bottom: var(--spacing-m);
}
.pieChrtsSection {
  margin-bottom: var(--spacing-4xl);
}
.chartsSection,
.tickersSection {
  margin-bottom: var(--spacing-2xl);
}
.etfTableSection:not(:last-child) {
  padding-bottom: var(--spacing-2xl);
}

.category {
  margin-right: var(--spacing-xl);
  white-space: nowrap;
  font-size: var(--font-size-main);
  font-weight: 400;
  color: var(--primary);
}
.category > span {
  font-weight: 600;
}

.infoContainer {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding-bottom: var(--spacing-xs);
  margin-bottom: var(--spacing-xs);
  border-bottom: 1px solid var(--main-color-border);
  gap: 8px;
}
.selectorsGroup {
  display: grid;
  grid: auto / minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) auto;
  grid-gap: 16px;
  margin-bottom: var(--spacing-m);
}
.selectorTitle {
  margin-bottom: var(--spacing-xs);
  font-weight: 400;
}
.buttonWrapper {
  display: flex;
  align-items: flex-end;
  height: 100%;
  width: min-content;
  margin-left: auto;
}
.buttonWrapper > button {
  width: 100px;
  justify-content: center;
}

@media (max-width: 991px) {
  .selectorsGroup {
    grid-template-columns: minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr);
  }
  .buttonWrapper {
    grid-column: 3/4;
  }
}

@media (max-width: 575px) {
  .dropdownRow {
    flex-wrap: wrap;
    gap: var(--spacing-m);
  }
  .dropdownWrapper {
    width: 100%;
  }
  .category:nth-child(2) {
    margin-right: 0;
  }
  .category:nth-child(1) {
    margin-right: auto;
  }
  .heading .label {
    display: none;
  }
  .selectorsGroup {
    grid-template-columns: minmax(120px, 1fr);
  }
  .buttonWrapper {
    grid-column: 1/2;
  }
}
