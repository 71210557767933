@import "@styles/mixins.scss";

.container {
  @include flexContainer(column, flex-start, flex-start);

  flex-grow: 1;
  width: 100%;
  row-gap: var(--spacing-l);

  .inner {
    @include flexContainer(column, flex-start, flex-start);

    width: 100%;
    row-gap: var(--spacing-l);

    .fieldRow {
      @include flexContainer(column, flex-start, flex-start);

      width: 100%;

      &.disable {
        opacity: 0.2;
        pointer-events: none;
      }

      .groupLabel {
        font-weight: 500;
        margin-bottom: var(--spacing-xs);
      }
    }
  }

  .bntWrapper {
    @include flexContainer(row, flex-end, flex-end);

    margin-left: auto;
    margin-top: auto;
  }
}

.group {
  @include flexContainer(row, flex-start, flex-end);

  width: 100%;
  border: 1px solid var(--main-border);
  border-radius: var(--border-radius);
  padding: var(--spacing-m);
  column-gap: var(--spacing-m);

  &[data-flow="column"] {
    flex-direction: column;
    align-items: flex-start;
    row-gap: var(--spacing-2xl);
  }

  .itemWrapper {
    @include flexContainer(column, flex-start, stretch);

    flex-grow: 1;
    width: 100%;
  }
  .statusGroup {
    @include flexContainer(row, flex-start, flex-end);

    // width: 100%;
    column-gap: var(--spacing-l);
    padding-bottom: var(--spacing-s);
  }

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
    row-gap: var(--spacing-2xl);

    .statusGroup {
      row-gap: var(--spacing-xs);
    }
  }
  @media (max-width: 575px) {
    .statusGroup {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}
