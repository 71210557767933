@import "@styles/mixins.scss";

.container {
  @include flexContainer(column, flex-start, flex-start);

  flex-grow: 1;
  background-color: var(--container-bg);
  box-shadow: var(--box-shadow);
  width: 100%;
  min-height: var(--inner-content-min-height);
  border-radius: var(--border-radius);

  .inner {
    @include flexContainer(column, flex-start, flex-start);

    flex-grow: 1;
    width: 100%;
    padding: var(--spacing-l);
    border-radius: var(--border-radius);
    animation: var(--slide-up-animation);

    .subHeading {
      margin-bottom: var(--spacing-m);
      font-size: var(--font-size-medium);
    }
    .editSubHeading {
      display: flex;
      align-items: center;
      overflow: hidden;
      gap: var(--spacing-xs);
      margin-bottom: var(--spacing-m);
      width: 100%;

      > p:nth-child(1) {
        font-size: var(--font-size-base);
        font-weight: 500;
        opacity: 0.5;
        white-space: nowrap;
      }
      > p:nth-child(2) {
        font-size: var(--font-size-main);
        font-weight: 600;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
    .headerNavContainer {
      @include flexContainer(row, space-between, center);
      width: 100%;
      margin-bottom: var(--spacing-m);
      column-gap: var(--spacing-m);

      .navGroup {
        @include flexContainer(row, space-between, center);
        width: 100%;
        min-height: 40px;
      }

      .buttonsGroup {
        display: flex;
        align-items: center;
        gap: var(--spacing-m);

        .btnWrapper {
          display: flex;
          width: 100%;
        }
      }
    }

    .mainSection {
      @include flexContainer(column, flex-start, flex-start);

      position: relative;
      width: 100%;
      flex-grow: 1;

      .topSection {
        @include flexContainer(column, flex-start, flex-start);
        @include borderedContainer(transparent);

        flex-grow: 1;
        position: relative;
        width: 100%;
        padding: var(--spacing-l);
        margin-bottom: var(--spacing-2xl);
        background-color: var(--white);
        box-shadow: var(--box-shadow);
      }
      .bottomSection {
        position: relative;
        width: 100%;
      }
      .topSection.disabled,
      .bottomSection.disabled {
        pointer-events: none;
        user-select: none;
      }
      .topSection.disabled:before,
      .bottomSection.disabled:before {
        position: absolute;
        content: "";
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: var(--white);
        opacity: 0.85;
        transition: 0.3s;
        border-radius: var(--border-radius);
        z-index: 100;
      }
      .footerButtonWrapper {
        width: 100%;
        padding-top: 20px;
        display: flex;
        justify-content: flex-end;
      }
      .previewSection {
        width: 100%;
        margin-top: var(--spacing-2xl);
      }
    }
  }
}

// .container .inner {
//   .previewSection {
//     width: 100%;
//     margin-top: var(--spacing-2xl);
//   }
// }

@media (max-width: 767px) {
  .container {
    .inner {
      .headerNavContainer {
        row-gap: var(--spacing-m);
        flex-wrap: wrap;

        .buttonsGroup {
          width: 100%;
          flex-wrap: wrap;
        }
      }
    }
  }
}
