@import "@styles/mixins";

.container {
  --header-title-min-width: 430px;
}

.container {
  @include flexContainer(column, flex-start, flex-start);

  width: 100%;
  height: 100%;
  flex-grow: 1;

  padding: var(--spacing-l) 0;
  margin: 0 auto;
  max-width: var(--max-inner-header-width);
  overflow: hidden;

  .inner {
    @include flexContainer(row, space-between, center);

    width: 100%;
    height: 100%;
    flex-grow: 1;
    column-gap: var(--spacing-m);
    animation: SlideIn 500ms cubic-bezier(0.08, 0.44, 0, 1.005);

    .headingGroup {
      @include flexContainer(column, flex-start, flex-start);

      min-width: var(--header-title-min-width);

      .title,
      .subtitle {
        color: var(--white);
        text-align: start;
        white-space: nowrap;
      }

      .title {
        display: flex;
        font-size: var(--font-size-extart);
        font-weight: 500;
        line-height: 1;

        .symbol {
          font-weight: 400;
          font-size: 0.5em;
          line-height: 1.33;
        }
      }
      .subtitle {
        font-size: var(--font-size-main);
        font-weight: 400;
        line-height: 1.2;
      }
    }
    .footerText {
      @include flexContainer(row-reverse, center);

      font-size: var(--font-size-small);
      column-gap: var(--spacing-m);
      color: var(--white);
      text-align: center;
      row-gap: var(--spacing-xs);

      .copyright {
        opacity: 0.8;
      }
      .footerNav {
        font-size: var(--font-size-small);
        font-weight: 500;
        > a {
          text-decoration: none;
          color: inherit;
          transition: var(--base-transition-delay);

          &:hover {
            color: var(--white-opacity-70);
          }
        }
      }
    }
  }

  @media (max-width: 1440px) {
    .inner {
      .headingGroup {
        min-width: auto;
      }
      .footerText {
        flex-direction: column;
      }
    }
  }

  @media (max-width: 991px) {
    .inner {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-end;
      row-gap: var(--spacing-l);

      .footerText {
        flex-direction: row-reverse;
        justify-content: space-between;
        width: 100%;
      }
    }
  }

  @media (max-width: 767px) {
    .inner {
      .headingGroup {
        .title {
          font-size: var(--font-size-large);
        }
        .subtitle {
          font-size: var(--font-size-main);
        }
      }
    }
  }

  @media (max-width: 575px) {
    .inner {
      align-items: center;
      .headingGroup {
        align-items: center;
        .title {
          font-size: var(--font-size-large);
          text-align: center;
        }
        .subtitle {
          font-size: var(--font-size-base);
          text-align: center;
        }
      }
      .footerText {
        flex-direction: column;
        justify-content: center;
        width: auto;
      }
    }
  }
}

@keyframes SlideIn {
  0% {
    transform: translateX(25%);
    opacity: 0.2;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
