.container {
  --container-height: 200px;
  --container-width: 188px;
  --checkbox-height: 33px;

  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  border: 1px solid var(--main-color-separator);
  border-style: dashed;
  border-radius: var(--border-radius);
  font-weight: var(--font-size-medium);
  font-weight: 600;
  color: var(--muted-text);
  cursor: pointer;

  &.uneraseble:not(.canPast) {
    cursor: default;
  }

  &.operator {
    border: none;
    color: var(--white);
    background-color: var(--secondary);
    transition: 0.3s;

    &:hover:not(.uneraseble:not(.canPast)) {
      background-color: var(--secondary-hover);
      color: var(--main-color);
    }

    &.inputNumber {
      width: 120px;
      padding: 2px;
      border: 1px solid var(--main-color-separator);
      background-color: transparent;
    }

    &.booleanType {
      min-width: 65px;
    }
  }

  &.ticker {
    width: auto;
    border: 1px solid var(--main-color-separator);
    color: var(--main-color);
    background-color: var(--white);

    .operatorInner {
      white-space: nowrap;
      transition: color 0.3s;
    }
    &:hover .operatorInner {
      color: var(--secondary);
    }
  }

  .emptyInner,
  .operatorInner {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 36px;

    &.active {
      outline-offset: 2px;
      outline: 2px solid var(--warning);
    }
  }

  .operatorInner {
    position: relative;

    &.active .iconContainer {
      opacity: 1;
      pointer-events: all;
    }

    .tickerText {
      padding: var(--spacing-xs);

      &.functionOperator {
        color: var(--warning);
      }
    }

    .inputNumberWrapper {
      padding: var(--spacing-xxs);

      > input {
        width: 100%;
        font-size: var(--font-size-base);
        height: 100%;
        padding: 4px;
        border: none;
        border-radius: var(--border-radius);
        color: var(--primary);
        outline: none;
        font-weight: 600;

        &::placeholder {
          color: var(--muted-text);
        }
      }
    }

    .iconContainer {
      position: absolute;
      top: -8px;
      left: -8px;
      z-index: 20;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 16px;
      height: 16px;
      opacity: 0;
      transition: 0.2s;
      pointer-events: none;
      > svg {
        width: 100%;
        height: 100%;
        path {
          fill: var(--error);
        }
      }
    }
  }

  .listContainer {
    position: absolute;
    left: 0;
    top: 100%;
    padding-top: 11px;
    visibility: hidden;
    z-index: 100;

    &.open {
      visibility: visible;
    }

    &.open .listInner {
      max-height: var(--container-height);
      opacity: 1;
    }
    &.open .listInner .wrapper {
      overflow-y: auto;
      overflow-x: hidden;
    }

    .triangle {
      top: 6px;
      left: 12px;
      width: 10px;
      height: 10px;
      position: absolute;
      background-color: var(--white);
      transform: rotate(45deg);
      border-top: 1px solid var(--main-color-separator);
      border-left: 1px solid var(--main-color-separator);
      z-index: 10;
    }

    .listInner {
      height: var(--container-height);
      width: var(--container-width);
      max-height: 0;
      border-radius: var(--border-radius);
      background-color: var(--white);
      box-shadow: var(--box-shadow);
      border: 1px solid var(--main-color-separator);
      overflow: hidden;
      transition: 0.2s;
      opacity: 0;

      .wrapper {
        display: grid;
        grid-template-columns: repeat(4, 36px);
        grid-auto-rows: 36px;
        gap: var(--spacing-xxs);
        width: 100%;
        height: calc(100% - var(--checkbox-height) - var(--spacing-s));
        min-height: calc(var(--container-height) - var(--checkbox-height) - var(--spacing-s));
        padding-left: var(--spacing-xs);
        margin-top: var(--spacing-xs);
        overflow: hidden;

        &.replacable {
          .item {
            background-color: var(--error-light);
            &:hover:not(.disableNumber) {
              background-color: var(--error);
            }
          }
        }

        .item {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 36px;
          height: 36px;
          background-color: var(--secondary-hover);
          border-radius: var(--border-radius);
          color: var(--main-color);
          transition: 0.3s;

          &:hover:not(.disableNumber) {
            color: var(--white);
            background-color: var(--secondary);
          }

          &.inputNumberItem {
            display: block;
            width: 100%;
            grid-column: 1/5;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            text-align: center;
            padding: var(--spacing-xs);
          }
          &.inputBooleanItem {
            display: block;
            width: 100%;
            grid-column: 1/3;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            text-align: center;
            padding: var(--spacing-xs);

            &[data-type="TRUE"] {
              grid-column: 1/3;
            }
            &[data-type="FALSE"] {
              grid-column: 3/5;
            }
          }
          &.disableNumber {
            cursor: not-allowed;
            opacity: 0.2;
          }
        }
      }
      .footer {
        padding: var(--spacing-xs);
      }
    }
  }
}
