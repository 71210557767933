@import "@styles/mixins.scss";

.container,
.formContainer,
.formContainer > form {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.container {
  width: 100%;
  height: 100%;
  animation: SlideUp 0.3s ease-in-out;
  justify-content: center;
  flex-grow: 1;
}
.formContainer {
  position: relative;
  background-color: var(--white);
  box-shadow: var(--box-shadow);
  min-width: 300px;
  max-width: 500px;
  width: 100%;
  padding: var(--spacing-l);
  border-radius: var(--border-radius);
}
.formContainer > form {
  width: 100%;
}
.formContainer .inputWrapper,
.dropdownWrapper {
  width: 100%;
}
.dropdownWrapper {
  margin-bottom: var(--spacing-l);
}
.formInnerWrapper {
  @include flexContainer(column, flex-start, flex-start);
  width: 100%;

  .productWrapper {
    @include flexContainer(column, flex-start, flex-start);

    row-gap: var(--spacing-xs);
    width: 100%;

    &:last-child {
      margin-top: var(--spacing-l);
    }
  }
}
.frontBg {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: var(--bg-white-opacity);
  z-index: 10;
  backdrop-filter: grayscale(1);
}
.loaderContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 65px;
  padding-bottom: var(--spacing-xl);
}
.title {
  font-size: var(--font-size-extra-medium);
  color: var(--primary);
  margin-bottom: var(--spacing-m);
  font-weight: 500;
}
.btnGroup {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: var(--spacing-m);
  padding-top: var(--spacing-l);
}

@keyframes SlideUp {
  0% {
    transform: translateY(100px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
