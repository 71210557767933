.container {
  position: relative;
  background-color: var(--white);
  padding: var(--spacing-l);
  max-width: 600px;
  width: 70%;
  border-radius: var(--border-radius);
  .close {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    width: 50px;
    height: 50px;
    font-size: 0;
    cursor: pointer;

    &:before,
    &:after {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 2px;
      height: 25px;
      background-color: var(--gray-3);
      transform: rotate(45deg) translate(-50%, -50%);
      transform-origin: top left;
      transition: all 420ms;
      content: "";
    }
    &:after {
      transform: rotate(-45deg) translate(-50%, -50%);
    }
    &:hover:before,
    &:hover:after {
      background-color: var(--primary);
    }
  }
  .head {
    display: flex;
    align-items: flex-start;
    gap: var(--spacing-xs);
    h2 {
      font-size: var(--font-size-extra-medium);
      font-weight: 600;
      color: var(--primary);
      margin-bottom: var(--spacing-m);
    }
  }

  .inner {
    display: flex;
    padding: 16px 16px 50px 16px;
    flex-direction: column;
    border: 1px solid var(--gray-3);
    border-radius: var(--border-radius);
    .select {
      margin-bottom: var(--spacing-2xl);
      .field {
        margin-bottom: var(--spacing-xxs);
      }
    }
    .text {
      text-align: center;
      color: var(--secondary);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
    }
  }
}
