@import "@styles/mixins.scss";

.emptyContainer {
  @include flexContainer(row);

  width: 100%;
  flex-grow: 1;
  max-height: 400px;
  gap: var(--spacing-2xl);
  border-radius: var(--border-radius);
  border: 1px solid var(--main-color-border);
  padding: var(--spacing-2xl);
  box-shadow: var(--box-shadow);
  border: 1px solid var(--main-color-separator);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;

  .imageContainer {
    width: 200px;
    height: auto;

    > svg {
      width: 100%;
      height: auto;
    }
  }
  .textContainer {
    @include flexContainer(column, center, center);

    row-gap: var(--spacing-xs);

    > p {
      font-size: var(--font-size-medium);
      color: var(--primary);
      font-weight: 500;

      &:last-child {
        font-size: var(--font-size-main);
        color: var(--muted-text);
      }
    }
  }
}

@media (max-width: 767px) {
  .emptyContainer {
    flex-direction: column;
  }
}
