@import "@styles/mixins";

.container {
  @include flexContainer(row, space-between, flex-end);
  margin-bottom: var(--spacing-xs);
  column-gap: var(--spacing-2xl);

  .inner {
    font-size: var(--font-size-main);
    font-weight: 400;
    font-family: "Inter", sans-serif;
    color: var(--text);
    white-space: pre-wrap;
    word-break: break-word;
    width: 100%;

    > a {
      color: var(--secondary);

      &:visited {
        color: var(--primary);
      }
    }
  }
}

@media (max-width: 575px) {
  .container {
    flex-direction: column;
    row-gap: var(--spacing-xs);
  }
}
