@import "@styles/mixins.scss";

.container {
  @include borderedContainer(var(--main-border));
  position: relative;
  width: calc(100% - var(--spacing-m));
  height: 96px;
  transition: 0.3s;

  &:hover {
    @include borderedContainer();

    box-shadow: var(--box-shadow);
  }

  a {
    @include flexContainer();
    height: 100%;
    text-decoration: none;
    padding: var(--spacing-m);
    color: inherit;
  }
  .info {
    padding: 0 var(--spacing-m) 0 var(--spacing-m);
    font-style: normal;
    font-weight: 500;
    font-size: var(--font-size-main);
    color: var(--secondary);
  }
  .addIcon {
    width: 48px;
    height: 48px;
    align-self: center;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
