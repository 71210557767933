.container {
  background-color: var(--white);
  /* box-shadow: var(--base-shadow); */
  border-radius: var(--border-radius);
  padding: var(--spacing-m);
  min-height: 150px;
  border: 1px solid var(--gray-3);
}
.inner {
  width: 100%;
  display: grid;
  gap: var(--spacing-m);
  grid: 1fr / repeat(3, 1fr);
}
.loadingContainer {
  padding-right: var(--spacing-xxl-2);
  overflow: hidden;
}
.item {
  display: flex;
  justify-content: space-between;
  gap: var(--spacing-m);
  width: 100%;
  min-width: 150px;
  padding-bottom: var(--spacing-xxs);
  border-bottom: 1px solid var(--main-border);
  font-size: 14px;
  font-weight: 400;

  > span {
    padding-top: 8px;
    padding-bottom: 6px;
  }

  > span:last-child {
    font-weight: 600;
    color: var(--primary);
  }

  > span:first-child {
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
  }
}

@media (max-width: 991px) {
  .container {
    padding: var(--spacing-m) var(--spacing-xl);
  }
  .inner {
    grid: auto / 100%;
  }
  .loadingContainer {
    padding-right: 0;
  }
}
