@import "@styles/mixins";

.headerContainer {
  --logo-width: 115px;
  --collapsed-menu-icon-width: 24px;
  --buttons-navigation-width: 253px;

  @include flexContainer(row);
  font-family: "Poppins", "Archivo", sans-serif;

  position: fixed;
  inset: 0 0 0;
  height: var(--home-header-height);
  width: 100%;
  min-width: 100%;
  padding: var(--spacing-m) var(--spacing-9xl);
  z-index: 500;

  &.home {
    background-color: var(--black-opacity-10);
    backdrop-filter: blur(10px);
  }

  .inner {
    @include flexContainer(row, flex-start, center);

    width: 100%;
    max-width: var(--max-inner-header-width);

    .logoContainer {
      display: flex;
      width: var(--logo-width);
      min-width: var(--logo-width);

      > a {
        @include flexContainer(row, center, center);

        width: 100%;
      }

      > a > svg {
        width: 100%;
        height: auto;
      }
    }
    .collapsedMenu {
      @include flexContainer(row, center, center);
      width: var(--collapsed-menu-icon-width);
      position: relative;
      height: 100%;
      cursor: pointer;

      > svg {
        position: absolute;
        left: 0;
        width: 100%;
        height: auto;
        transition: var(--fast-transition-delay);

        &:first-child {
          opacity: 1;
        }
        &:last-child {
          opacity: 0;
        }
      }

      &.close {
        > svg {
          &:first-child {
            opacity: 0;
          }
          &:last-child {
            opacity: 1;
          }
        }
      }
    }
    .navigation {
      @include flexContainer(row, center, center);

      width: 100%;
      padding: 0 0 0 calc(var(--buttons-navigation-width) - var(--logo-width));

      &.mobile {
        @include flexContainer(column, center, center);
        padding: 0;

        &.open {
          display: flex;
          animation: AppearAnimation 0.3s ease-in-out;
          opacity: 1;
        }

        display: none;
        position: fixed;
        top: var(--home-header-height);
        left: 0;
        right: 0;
        height: calc(100% - var(--home-header-height));
        background-color: var(--white);
        z-index: 600;
        opacity: 0;
        height: calc(100vh - var(--home-header-height));
        height: calc(100dvh - var(--home-header-height));

        .navList {
          flex-direction: column;
          row-gap: var(--spacing-xs);
          animation: SlideTopToBottom 0.5s ease-in-out;
          width: 200px;

          .navItem {
            color: var(--black-90);
            padding: var(--spacing-xxs) var(--spacing-2xl);
            border-radius: var(--border-radius);
            transition: var(--base-transition-delay);
            font-size: var(--font-size-medium);
            font-weight: 400;
            width: 100%;

            &.active {
              opacity: 0.5;
              pointer-events: none;
            }

            &:hover {
              background-color: var(--black-90);
              color: var(--white);
            }
            &::before {
              display: none;
            }
          }
        }
      }

      .navList {
        @include flexContainer(row, center, center);

        column-gap: var(--spacing-2xl);

        .navItem {
          @include flexContainer(row, center, center);

          padding-bottom: 2px;
          font-size: var(--font-size-main);
          font-weight: 500;
          position: relative;
          cursor: pointer;
          color: var(--white);
          transition: var(--base-transition-delay);

          > a {
            text-decoration: none;
            color: inherit;
          }
          &.active::before {
            width: 100%;
            pointer-events: none;
          }

          &:hover::before {
            width: 100%;
          }

          &::before {
            position: absolute;
            content: "";
            top: 100%;
            height: 2px;
            width: 0;
            background-color: var(--white);
            transition: var(--base-transition-delay);
          }

          &[data-inverse="false"] {
            color: var(--black-90);
            &::before {
              background-color: var(--black-90);
            }
          }
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .headerContainer {
    .inner {
      justify-content: space-between;
    }
  }
}

@media (max-width: 575px) {
  .headerContainer {
    padding: var(--spacing-m) var(--spacing-l);
  }
}

@keyframes AppearAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes SlideTopToBottom {
  0% {
    transform: translateY(-200px);
    row-gap: var(--spacing-4xl);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    row-gap: var(--spacing-xs);
    opacity: 1;
  }
}
