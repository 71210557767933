.container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: var(--spacing-4xl);
  margin-bottom: var(--spacing-m);
  .arrowWrapper {
    display: flex;
    align-items: center;
    padding: 0;
    height: 16px;
    cursor: pointer;
    &.disabled {
      opacity: 0.3;
      cursor: default;
    }
    .arrowRight {
      transform: rotate(90deg);
    }
    .arrowLeft {
      transform: rotate(-90deg);
    }
    .leftText,
    .rightText {
      color: var(--main-color);
      font-size: var(--font-size-main);
      font-weight: 400;
    }
    .leftText {
      margin-left: var(--spacing-xs);
    }
    .rightText {
      margin-right: var(--spacing-xs);
    }
  }
}

@media (max-width: 767px) {
  .container {
    display: none;
  }
}
