.container {
  .wrapper {
    width: 100%;
    min-height: 300px;
    height: 100%;
    background-color: var(--white);
    padding: var(--spacing-l);
    border-radius: var(--border-radius);
    animation: var(--fade-in);

    .innerContainer {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border: 1px solid var(--main-border);
      border-radius: var(--border-radius);
      padding: var(--spacing-2xl);
      color: var(--main-color);
      font-size: var(--font-size-main);
      font-weight: 500;

      > p {
        margin-bottom: var(--spacing-l);
      }
    }
  }
}

.loaderContainer,
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100vh - var(--header-height) - var(--spacing-2xl) * 2);
  padding: var(--spacing-2xl);
}
