@import "@styles/mixins.scss";

.wrapper {
  --loder-bg-color: #b6cddb26;

  @include flexContainer(column, flex-start, flex-start);
  position: relative;
  flex-grow: 1;
  width: 100%;
  transition: 0.1s;
  transition-timing-function: ease-in;

  &.loading {
    border-radius: var(--border-radius);
    max-height: calc(100vh - var(--header-height) - var(--spacing-2xl) * 2 - var(--spacing-l) * 2);
    overflow: hidden;
    background-color: var(--white);
  }

  &.loading > .inner {
    opacity: 0.1;
    pointer-events: none;
  }
  &.fullWidth {
    .innerLoading {
      padding: 0;
    }
  }
  &.withAnimation {
    .inner {
      opacity: 0;
      transition: 0.2s ease-in;
    }
  }
  .inner {
    @include flexContainer(column, flex-start, flex-start);
    flex-grow: 1;
    // display: grid;
    // grid-template-columns: 100%;
    // grid-template-rows: 1fr;
    // height: 100%;
    width: 100%;

    &.hidden {
      display: none;
    }
  }
  .innerLoading {
    display: grid;
    width: 100%;
    height: 100%;
    padding: var(--spacing-m);
    min-height: 240px;

    // background-color: var(--loder-bg-color);

    &.transparent {
      position: absolute;

      .innerEmpty {
        background-color: transparent;
        border-radius: var(--border-radius);
      }
    }

    .innerEmpty {
      @include flexContainer;
      width: 100%;
      height: 100%;
      border: 1px solid var(--main-border);
      border-radius: var(--border-radius);
      background-color: var(--loder-bg-color);
    }
    .innerEmptyNoBorder {
      @include flexContainer;
      width: 100%;
      height: 100%;
    }
  }
}
