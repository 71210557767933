@import "@styles/mixins";

.container {
  @include flexContainer(column, flex-start, flex-start);
  flex-grow: 1;
  width: 100%;
  height: 100%;
  padding: var(--spacing-l);
  background-color: var(--container-bg);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  animation: var(--slide-up-animation);

  .editSubHeading {
    display: flex;
    align-items: center;
    gap: var(--spacing-xs);
    margin-bottom: var(--spacing-xl);

    > p:first-child {
      font-size: var(--font-size-main);
      font-weight: 500;
      opacity: 0.5;
    }
    > p {
      font-size: var(--font-size-medium);
      font-weight: 600;
    }
  }

  .subHeading {
    margin-bottom: var(--spacing-xl);
    font-size: var(--font-size-medium);
  }

  .navigation {
    @include flexContainer(row, space-between, center);
    width: 100%;
    margin-bottom: var(--spacing-2xl);

    .navGroup {
      @include flexContainer;
      column-gap: var(--spacing-2xl);
    }
  }
  .subTitle {
    @include flexContainer;
    font-size: var(--font-size-medium);
    font-weight: 500;
    color: var(--primary);
    margin-bottom: var(--spacing-xs);
  }
  .mainContent {
    position: relative;
    @include flexContainer(column, flex-start, flex-start);
    @include borderedContainer;
    width: 100%;
    padding: var(--spacing-l);
    flex-grow: 1;

    &.disable {
      pointer-events: none;

      &::before {
        position: absolute;
        content: "";
        border-radius: var(--border-radius);
        inset: 0;
        background-color: var(--white);
        opacity: 0.8;
        z-index: 10;
      }
    }

    .topBox {
      --inner-item-column-1-width: calc((100% - var(--spacing-m)) * 2 / 3);
      --inner-item-column-2-width: calc((100% - var(--spacing-m)) * 1 / 3);
      --margin-top: calc(1.3em + var(--spacing-xs));

      margin-bottom: var(--spacing-xl);

      @include flexContainer(column, flex-start, flex-start);
      width: 100%;

      .infoSection {
        width: 100%;
        border-radius: var(--border-radius);
        border: 1px solid var(--main-border);
        padding: var(--spacing-l);
        margin-bottom: var(--spacing-l);

        > p {
          color: var(--muted-text);
          font-size: var(--font-size-base);

          &:first-child {
            color: var(--secondary);
            font-size: var(--font-size-medium);
            font-weight: 500;
            margin-bottom: var(--spacing-xs);
          }
        }
      }

      .groupRow {
        @include flexContainer(row, flex-start, flex-end);

        width: 100%;
        column-gap: var(--spacing-m);

        > .itemWrapper {
          &:nth-child(1) {
            column-gap: var(--spacing-m);
            width: 100%;
          }
          &:nth-child(2) {
            width: var(--inner-item-column-2-width);
            min-width: var(--inner-item-column-2-width);
          }
        }

        &:nth-child(2) {
          margin-bottom: var(--spacing-m);

          .itemWrapper {
            &:nth-child(2) {
              display: flex;
              column-gap: var(--spacing-m);
              padding-bottom: 2px;
            }
          }
        }
        &:nth-child(3) {
          margin-bottom: var(--spacing-m);

          > div:nth-child(2) {
            min-width: var(--inner-item-column-2-width);
            width: var(--inner-item-column-2-width);
          }
        }
        &:nth-child(4) {
          .itemWrapper {
            width: 100%;
          }
        }
      }
    }

    .constructorBox {
      --inner-column-width: calc(100% * (1 / 3));

      @include flexContainer(row, flex-start, stretch);
      width: 100%;
      column-gap: var(--spacing-m);
      flex-grow: 1;

      &.disabled {
        .constructorDesk {
          opacity: 0.3;
          pointer-events: none;
        }
      }

      .constructorDesk {
        @include flexContainer(column, flex-start, flex-start);

        width: calc(var(--inner-column-width) * 2 + var(--spacing-m));
        flex-grow: 1;

        .innerDesk {
          position: relative;
          @include flexContainer(column, flex-start, flex-start);
          @include borderedContainer(var(--primary));

          width: 100%;
          min-height: 448px;
          margin-bottom: var(--spacing-m);
          flex-grow: 1;

          &.error {
            border: 2px solid var(--error);

            .errorContainer {
              visibility: visible;
              transform: scale(1);
              opacity: 1;
            }
          }
          .errorContainer {
            position: absolute;
            @include flexContainer;
            @include borderedContainer(var(--error));
            width: calc(100% - var(--spacing-l) * 2);
            padding: var(--spacing-l);
            margin: 0 var(--spacing-l);
            background-color: var(--white);
            color: var(--error);
            font-size: var(--font-size-main);
            font-weight: 500;
            bottom: var(--spacing-l);
            box-shadow: var(--box-shadow);
            text-align: center;
            transition: 0.3s;
            z-index: 10;
            transform: scale(0.6);
            visibility: hidden;
            pointer-events: none;
            opacity: 0;
          }
        }
        .label {
          margin-bottom: var(--spacing-xs);
          font-size: var(--font-size-main);
        }
        .footer {
          @include borderedContainer();
          width: 100%;
          padding: var(--spacing-m);

          > p {
            color: var(--secondary);
            font-size: var(--font-size-base);
            font-weight: 500;

            > span {
              word-break: break-all;
              color: var(--primary);
            }
          }
        }
      }
      .constructorItemsForm {
        width: calc(var(--inner-column-width));

        .itemWrapper {
          margin-bottom: var(--spacing-m);

          &.switchContainer {
            width: 100%;
            padding-top: var(--spacing-xs);
          }
        }
        .bottomWrapper {
          @include flexContainer(row, flex-start, flex-start);
          width: 100%;

          &:not(:last-child) {
            margin-bottom: var(--spacing-m);
          }
        }
      }
    }
  }
}

.customLabel {
  display: flex;
  width: 100%;
  cursor: default;

  .labelText {
    overflow: hidden;
    opacity: 0.8;
    text-overflow: ellipsis;
    white-space: nowrap;
    transition: 0.1s;
    margin-right: auto;

    &:hover {
      opacity: 1;
      color: var(--secondary);
    }
  }
}

@media (max-width: 1439px) {
  .container {
    .mainContent {
      .topBox {
        .groupRow {
          > .itemWrapper {
            &:nth-child(1) {
              column-gap: var(--spacing-m);
              width: 100%;
            }
            &:nth-child(2) {
              width: 100%;
            }
          }
          &:nth-child(3) {
            > div {
              width: 100%;
              &:nth-child(2) {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1199px) {
  .container {
    .mainContent {
      .topBox {
        flex-wrap: wrap-reverse;
        --inner-item-column-1-width: calc((100% - var(--spacing-m)) * 1 / 2);
        --inner-item-column-2-width: calc((100% - var(--spacing-m)) * 1 / 2);
        margin-bottom: var(--spacing-4xl);

        .groupRow:nth-child(3) {
          > div {
            &:nth-child(2) {
              width: 100%;
              min-width: var(--inner-item-column-2-width);
            }
            &:first-child {
              width: 50%;
              min-width: 240px;
            }
          }
        }
        .groupRow {
          .itemWrapper {
            &:first-child {
              width: 50%;
              min-width: 240px;
            }
            &:last-child {
              min-width: var(--inner-item-column-2-width);
            }
          }
          &:nth-child(5) {
            .itemWrapper {
              width: 100%;
            }
          }
        }
      }

      .constructorBox {
        flex-wrap: wrap-reverse;

        .constructorDesk,
        .constructorItemsForm {
          width: 100%;
        }

        .constructorItemsForm {
          @include flexContainer(row, flex-start, flex-start);
          flex-wrap: wrap;
          column-gap: var(--spacing-m);

          .itemWrapper {
            width: 100%;
            &.fullWidth {
              width: 100%;
            }
          }
          .bottomWrapper {
            width: calc(50% - var(--spacing-m) / 2);
            &:nth-child(1) {
              order: 1;
            }
            &:nth-child(2) {
              order: 2;
            }
            &:nth-child(3) {
              order: 3;
            }
            &:nth-child(4) {
              order: 4;
            }
            &:nth-child(6) {
              order: 7;
              margin: var(--spacing-m) 0 var(--spacing-xl) 0;
            }
            &:nth-child(7) {
              margin: var(--spacing-m) 0 var(--spacing-xl) 0;
              order: 6;
            }
            &:nth-child(8) {
              margin: var(--spacing-m) 0 var(--spacing-xl) 0;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .container {
    .mainContent {
      .topBox {
        --inner-item-column-1-width: 100%;
        --inner-item-column-2-width: 100%;
        row-gap: 0;

        .groupRow {
          &:nth-child(2) {
            flex-wrap: wrap;
            row-gap: var(--spacing-m);
            .itemWrapper {
              &:nth-child(2) {
                margin-top: 0;
              }
              &:first-child {
                width: 100%;
              }
            }
          }
          &:nth-child(3) {
            margin-bottom: var(--spacing-m);
            row-gap: var(--spacing-m);
            flex-wrap: wrap;

            > div {
              &:first-child {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .container {
    .mainContent {
      .topBox {
        .groupRow {
          &:nth-child(2) {
            .itemWrapper {
              &:nth-child(2) {
                flex-direction: column;
                row-gap: var(--spacing-m);
              }
            }
          }
        }
      }

      .constructorBox {
        .constructorItemsForm {
          .itemWrapper {
            width: 100%;
          }
          .bottomWrapper {
            width: 100%;

            &:nth-child(6) {
              margin-bottom: 0;
              order: 6;
            }
            &:nth-child(7) {
              order: 7;
              margin-bottom: var(--spacing-xl);
            }
          }
        }
      }
    }
  }
}
