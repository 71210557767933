@import "@styles/mixins";

.container {
  @include flexContainer(column, flex-start, flex-start);

  flex-grow: 1;
  width: 100%;
  animation: var(--slide-up-animation);

  .inner {
    width: 100%;
    height: 100%;
    min-height: 400px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(49%, 1fr));
    grid-template-rows: min-content 1fr;
    gap: var(--spacing-m);
    margin-bottom: var(--spacing-4xl);
  }
}
