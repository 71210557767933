@import "@styles/mixins";

.container {
  width: 100%;
  background-color: var(--container-bg);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  padding: var(--spacing-l);
  animation: var(--slide-up-animation);
  min-width: 365px;

  .section {
    &:not(:last-child) {
      margin-bottom: var(--spacing-2xl);
    }

    .heading {
      margin-bottom: var(--spacing-xs);
      font-size: var(--font-size-medium);
    }
    .scetionContainer {
      @include flexContainer;

      width: 100%;
      background-color: var(--white);
      border-radius: var(--border-radius);
      box-shadow: var(--box-shadow);
      padding: var(--spacing-l);

      .emptyContainer {
        @include flexContainer(row, flex-start);

        width: 100%;
        gap: var(--spacing-l);

        > img {
          opacity: 0.7;
          min-width: 250px;
          max-width: 322px;
          width: 100%;
        }

        .textSection {
          @include flexContainer(column);

          width: 100%;
          padding: 0 var(--spacing-4xl);
          row-gap: var(--spacing-xs);
          text-align: center;

          > p {
            color: var(--muted-text);
            font-weight: 400;
            max-width: 600px;

            &:first-child {
              color: var(--primary);
              font-size: var(--font-size-medium);
              font-weight: 600;
            }

            &:nth-child(2) {
              margin-bottom: var(--spacing-l);

              > span {
                font-weight: 600;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1199px) {
  .container {
    .section {
      .scetionContainer {
        .emptyContainer {
          flex-direction: column;
        }
      }
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
