@import "@styles/mixins";

.container {
  @include flexContainer;
  width: 100%;

  height: 40px;
  position: relative;
  border: 1px solid var(--primary);
  border-radius: var(--border-radius);
  cursor: pointer;
  transition: 0.3s;

  &.selected {
    border: 1px solid var(--secondary);
    background-color: var(--secondary);
    color: var(--white);

    .groupContainer {
      > svg path {
        stroke: var(--white);
      }
    }
  }
  &:hover {
    filter: brightness(1.5);
  }
  &:active {
    transform: scale(0.98);
  }

  &.disabled {
    opacity: 0.2;

    .uploadField {
      cursor: not-allowed;
      pointer-events: none;
    }
    .uploadField::-webkit-file-upload-button {
      cursor: not-allowed;
    }
  }

  .uploadField {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 10;
    cursor: pointer;
  }

  .uploadField::-webkit-file-upload-button {
    cursor: pointer;
  }

  .groupContainer {
    @include flexContainer;
    column-gap: var(--spacing-xs);

    padding: var(--spacing-xs) var(--spacing-m);
  }
}
