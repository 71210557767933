@import "@styles/mixins";

.container {
  width: 100%;
  color: var(--primary);
  padding: var(--spacing-l);
  border-radius: var(--border-radius);
  background-color: var(--white);

  .pagination {
    padding-top: var(--spacing-l);
  }
  .footnote {
    padding-top: var(--spacing-l);
    color: var(--secondary);
    font-size: var(--font-size-small);
  }
}

.modalContainer {
  @include flexContainer(column, flex-start, flex-start);

  overflow-y: auto;
  position: relative;
  width: 70vw;
  max-width: 1440px;
  min-height: 400px;
  background-color: var(--white);
  padding: var(--spacing-l);
  border-radius: var(--border-radius);

  .close {
    @include flexContainer;

    position: absolute;
    top: 0;
    right: 0;
    width: 50px;
    height: 50px;
    font-size: 0;
    cursor: pointer;
    color: var(--gray-3);

    &:before,
    &:after {
      position: absolute;
      width: 2px;
      height: 25px;
      background-color: currentColor;
      transform: rotate(45deg);
      transform-origin: center;
      transition: all 420ms;
      content: "";
    }

    &:after {
      transform: rotate(-45deg);
    }
    &:hover {
      color: var(--primary);
    }
  }

  > h2 {
    font-size: var(--font-size-medium);
    font-weight: 600;
    margin-bottom: var(--spacing-xs);
    font-family: Inter, sans-serif;

    > span {
      color: var(--muted-text);
      font-weight: 500;
      margin-left: var(--spacing-m);
      white-space: nowrap;
      font-size: var(--font-size-main);
    }
  }

  .modalInner {
    @include flexContainer(column, flex-start, flex-start);
    @include borderedContainer(var(--main-border));

    flex-grow: 1;
    padding: var(--spacing-l);
    width: 100%;
    height: 100%;

    .loaderWrapper {
      @include flexContainer;

      flex-grow: 1;
      width: 100%;
    }

    .chartWrapper {
      @include flexContainer;

      flex-grow: 1;
      width: 100%;

      .emptyData {
        @include flexContainer;

        flex-grow: 1;
        width: 100%;
        height: 100%;
        color: var(--muted-text);
        font-size: var(--font-size-main);
      }
    }
  }

  @media (max-width: 767px) {
    width: calc(100% - var(--spacing-m) * 2);
  }
}
