@import "@styles/mixins";

.container {
  @include flexContainer(column, flex-start, flex-start);

  width: 100%;
  flex-grow: 1;
  color: var(--white);
  padding-bottom: 0;
  background-color: var(--black-90);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  &.themWhite {
    background-image: none;
    background-color: var(--white-opacity-70);
    height: calc(100vh - var(--header-height));
    height: calc(100dvh - var(--header-height));
  }

  .inner {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    justify-content: center;
    width: 100%;
    height: 100%;

    iframe {
      width: 100%;
      height: 100%;
      border: none;
      margin: 0 auto;
      opacity: 0;
      transition: 0.5s;

      &.loading {
        height: 0;
      }
    }
    .notFound {
      @include flexContainer(row, center);

      width: 100%;
      max-width: 450px;
      padding: var(--spacing-4xl);
      background-color: var(--white-opacity-10);
      border-radius: var(--border-radius);
      color: var(--white);

      > p {
        font-size: var(--font-size-medium);
        font-weight: 500;
        text-align: center;
      }
    }
    &:has(.notFound) {
      @include flexContainer(column);
    }
  }
}
