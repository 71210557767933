@import "@styles/mixins.scss";

.container {
  --button-width: 160px;

  @include flexContainer(column, flex-start, stretch);
  flex-grow: 1;
  width: 100%;

  .inner {
    @include flexContainer(column, flex-start, stretch);

    width: 100%;
    flex-grow: 1;
    border-radius: var(--border-radius);
    animation: var(--slide-up-animation);

    .btnGroup {
      @include flexContainer(column, center, stretch);

      width: auto;
      flex-grow: 1;
      min-width: 160px;
    }

    .mainSection {
      @include flexContainer(column, flex-start, flex-start);

      width: 100%;
      flex-grow: 1;
      background-color: var(--white);
      position: relative;

      .progressContainer {
        @include flexContainer;

        position: absolute;
        inset: -5px;
        z-index: 10;
        backdrop-filter: blur(8px);
        animation: FadeIn 0.3s ease;
        border-radius: var(--border-radius);
      }

      .form {
        @include flexContainer(column, flex-start, flex-start);

        width: 100%;
        flex-grow: 1;
        row-gap: var(--spacing-m);

        &.disabled {
          opacity: 0.1;
        }

        .inputGroup {
          @include flexContainer(row, flex-start, flex-end);

          column-gap: var(--spacing-m);
          width: 100%;

          .btnWrapper {
            display: flex;
            min-width: var(--button-width);
          }
        }
      }
    }
    .resultSection {
      @include flexContainer(column, flex-start, stretch);

      width: 100%;
      margin-top: var(--spacing-2xl);
    }
  }
}

@media (max-width: 1199px) {
  .container {
    .inner {
      .mainSection {
        .form {
          .inputGroup {
            &:first-child {
              row-gap: var(--spacing-m);
              flex-direction: column-reverse;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .container {
    .inner {
      .mainSection {
        .form {
          .inputGroup {
            flex-wrap: wrap;
            row-gap: var(--spacing-m);

            .btnWrapper {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
