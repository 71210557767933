@import "@styles/mixins.scss";

.container {
  position: relative;
  .bgimage {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 52px;
    height: 52px;
    border: 2px solid var(--secondary);
    border-radius: 50%;
    cursor: pointer;
  }
  .info {
    position: absolute;
    width: 280px;
    height: auto;
    right: 0;
    top: 68px;
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
    display: flex;
    background-color: var(--white);
    flex-direction: column;
    padding: var(--spacing-l);
    overflow: hidden;
    animation: FadeIn 0.3s ease-in-out;

    .userInfoBlock {
      @include flexContainer(column, center, center);
      .bgimageMenu {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        width: 65px;
        height: 65px;
        border: 2px solid var(--secondary);
        border-radius: 50%;
        cursor: pointer;
        margin-bottom: var(--spacing-sx);
      }
      .name {
        padding-bottom: var(--spacing-xs);
        font-size: var(--font-size-main);
        font-family: Archivo;
        font-size: var(--font--size-medium);
        font-style: normal;
        font-weight: 500;
        color: var(--main-color);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .email {
        font-style: normal;
        font-weight: 400;
        font-size: var(--font-size-main);
        color: var(--main-color);
        opacity: 0.5;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    .menuIcon {
      display: flex;
      align-items: center;
      margin-right: var(--spacing-s);
    }

    .menuItems {
      margin-top: var(--spacing-2xl);
      margin-bottom: var(--spacing-sx);
      padding-top: var(--spacing-sx);
      padding-bottom: var(--spacing-sx);
      border-top: 1px solid var(--main-color-separator);
      border-bottom: 1px solid var(--main-color-separator);
      a {
        text-decoration: none;
        color: var(--main-color);
        display: flex;
        padding: var(--spacing-sx);
        align-items: center;
        font-weight: 500;

        &:hover {
          background-color: var(--secondary-hover);
        }
      }
      .disabled {
        opacity: 0.2;
        pointer-events: none;
      }
    }

    .logout {
      display: flex;
      padding: var(--spacing-sx);
      align-items: center;
      font-weight: 500;
      cursor: pointer;

      &:hover {
        background-color: var(--secondary-hover);
      }
    }
  }
}

@keyframes FadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
