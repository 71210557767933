@import "@styles/mixins.scss";

.container {
  @include flexContainer(column, flex-start, flex-start);
  width: 100%;
  background-color: var(--container-bg);
  box-shadow: var(--box-shadow);
  animation: var(--slide-up-animation);

  .loaderContainer {
    @include flexContainer;
    width: 100%;
    height: 100%;
  }
  .innerContainer {
    @include flexContainer(column, flex-start, flex-start);
    width: 100%;
    padding: var(--spacing-l);
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);

    .subHeading {
      margin-bottom: var(--spacing-m);
      font-size: var(--font-size-medium);
    }

    .wrapper {
      width: 100%;
      margin-bottom: var(--spacing-2xl);
    }

    .buttonsGroup {
      @include flexContainer(row, flex-end);
      // width: 100%;
      margin-left: auto;
    }

    .topTitle {
      display: flex;
      align-items: center;
      gap: var(--spacing-m);
      max-width: 90%;
      flex-grow: 1;
      margin-bottom: var(--spacing-m);

      > svg {
        width: 28px;
        height: 28px;
        min-width: 28px;
      }

      > svg.overlay {
        color: var(--secondary);
      }
      .title {
        width: 100%;
        font-size: var(--font-size-main);
        font-weight: 400;
        color: var(--primary);
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;

        > span {
          color: var(--muted-text);
          padding-right: var(--spacing-xs);
        }
      }
    }

    .navigationWrapper {
      @include flexContainer(row, space-between, center);
      gap: var(--spacing-m);
      width: 100%;
      margin-bottom: var(--spacing-m);

      .btnWrapper {
        width: 100%;
      }

      .buttonsGroup {
        @include flexContainer(row, flex-start);
        gap: var(--spacing-m);
        // flex-wrap: wrap;
        flex-grow: 1;
        animation: FadeIn 0.3s ease-in-out;
      }
    }
  }
}

.container .innerContainer .tableSection {
  width: 100%;
  margin-bottom: var(--spacing-m);

  .heading {
    margin-bottom: var(--spacing-m);
    font-size: var(--font-size-medium);
  }
  .tableWrapper {
    @include flexContainer;
    @include borderedContainer;
    width: 100%;
    padding: var(--spacing-l);
  }
}

.container .innerContainer .chartSection {
  @include flexContainer(row, flex-start, flex-start);
  @include borderedContainer;
  width: 100%;
  padding: var(--spacing-l);
  margin-bottom: var(--spacing-2xl);

  .chartContainer {
    @include flexContainer;
    @include borderedContainer(var(--main-border));
    width: 100%;
    min-height: 300px;
    overflow: hidden;

    .emptyData {
      @include flexContainer;
      width: 100%;
      height: 100%;
      color: var(--muted-text);
    }
  }
  .loaderContainer {
    @include flexContainer;
    height: 100%;
    width: 100%;
  }
}

.container .innerContainer {
  .sectionsWraper {
    position: relative;
    width: 100%;
    &.loading {
      .chartSection,
      .tableSection {
        opacity: 0.3;
        pointer-events: none;
      }
    }
  }
}

@media (max-width: 767px) {
  .container .innerContainer .navigationWrapper {
    gap: var(--spacing-m);
    flex-direction: column;
    align-items: stretch;

    .buttonsGroup {
      gap: var(--spacing-m);
      flex-direction: column;
      align-items: stretch;
      width: 100%;
    }
  }
}

@keyframes FadeIn {
  0% {
    opacity: 0;
    transform: translateX(400px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
