.chart {
  position: relative;
  display: grid;
  grid-auto-columns: minmax(200px, 1fr);
  grid-template-rows: min-content min-content auto;
  background-color: var(--white);
  width: 100%;
  border-radius: var(--border-radius);

  .tabs {
    display: flex;
    gap: var(--spacing-xs);
    justify-content: flex-start;
    margin-bottom: var(--spacing-m);
    .tab {
      padding: var(--spacing-sx) var(--spacing-s);
      border-radius: var(--border-radius);
      font-weight: 600;
      font-size: var(--font-size-base);
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      cursor: pointer;
      border: 1px solid var(--main-color-separator);
      border-radius: var(--border-radius);
      opacity: 0.5;

      &.active {
        background-color: var(--primary);
        color: var(--white);
        opacity: 1;
      }
    }
  }

  .head {
    display: flex;
    width: 95%;
    margin-bottom: var(--spacing-2xl);

    .heding {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width: 100%;
    }

    .menuInner {
      display: flex;
      align-items: center;

      .icon {
        min-width: 20px;
        min-height: 20px;
      }
      span {
        margin-left: var(--spacing-sx);
      }
    }
  }

  @media (max-width: 768px) {
    min-width: 200px;
    width: 100%;
  }
}
