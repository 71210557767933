@import "@styles/mixins";

.container {
  --bg-editmode-color: rgba(204, 231, 255, 0.278);

  @include flexContainer(column, flex-start, flex-start);
  flex-grow: 1;
  position: relative;
  width: 100%;
  padding: var(--spacing-m);
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  background-color: var(--bg-editmode-color);
  transition: 0.3s background-color;

  &.replaceMode {
    background-color: rgba(252, 108, 125, 0.1);
  }

  &.error {
    border: 1px solid var(--error);
  }
  .inner {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: var(--spacing-xs);
    padding-bottom: 220px;

    .operator {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 36px;
      height: 36px;
      border-radius: var(--border-radius);
      background-color: var(--secondary);
      color: var(--white);
    }
  }

  .helpContainer {
    @include flexContainer;
    position: absolute;
    inset: 60px 0 60px 0;
    z-index: 10;

    > p {
      @include borderedContainer(var(--main-border));
      text-align: center;
      border-style: dashed;
      color: var(--muted-text);
      font-weight: 400;
      padding: var(--spacing-2xl);
      margin: 0 var(--spacing-l);
    }
  }
}
