@import "./notification.css";
@import "./mixins.scss";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

ul {
  list-style: none;
}

body {
  margin: 0;
  font-family: "Archivo", "Inter", "Ubuntu", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  height: 100dvh;
  width: 100vw;
  min-height: -webkit-fill-available;
  min-width: 350px;
  color: var(--main-color);
  background-color: white;
  overflow: hidden;
}

:root {
  /* colors */
  --main-color: #093b79;
  --main-hover: #01429480;
  --main-border: #0142941a;
  --main-color-border: rgba(1, 66, 148, 0.1);
  --main-color-border-2: #dde5f1;
  --main-color-separator: rgba(1, 66, 148, 0.5);

  --primary: #013789;
  --primary-lighten: #0250c5;

  --secondary: #008ddd;
  --secondary-lighten: #20adff;

  --text: #002049;

  --white: white;
  --gray: #eeeeee;
  --gray-3: #80808050;
  --gray-4: rgba(9, 59, 121, 0.5);
  --readonly: #4a688f;

  --success: #158f01;
  --warning: #faad14;
  --error: #ab0317;

  --disabled: rgba(0, 0, 0, 0.25);
  --border: #d9d9d9;
  --content-bg: #fdfdfd;

  --error-light: #fc6c7d4d;
  --bg-white-opacity: rgba(255, 255, 255, 0.85);
  --bg-white-opacity-2: rgba(255, 255, 255, 0.9);
  --side-bar-color: rgba(227, 245, 255, 0.8);

  --green-drop-ok: #afd8a8;
  --placeholder: rgba(1, 66, 148, 0.4);
  --muted-text: rgba(9, 59, 121, 0.502);
  --table-separator: rgba(0, 67, 148, 0.251);
  --table-hedaer-fixed-bg: #e2f2fe;
  --tooltip-color: #3367aa;
  --card-bg-hover-color: rgb(230, 243, 255);
  --container-bg: rgb(230 242 249);
  --sidebar-menu: #cce7ff;
  --secondary-hover: #cce7ff;
  --secondary-row-hover: #cce7ff80;
  --link: #1890ff;
  --sidenav-bg: #004cb8;
  --action-color: #96fb9a;

  --black-90: #161616;
  --black-opacity-10: rgba(0, 0, 0, 0.05);
  --black-opacity-20: rgba(0, 0, 0, 0.2);
  --black-opacity-30: rgba(0, 0, 0, 0.3);
  --black-opacity-35: rgba(0, 0, 0, 0.35);
  --black-opacity-55: rgba(0, 0, 0, 0.55);
  --black-opacity-70: rgba(0, 0, 0, 0.7);
  --black-opacity-90: rgba(0, 0, 0, 0.9);
  --white-opacity-10: rgba(255, 255, 255, 0.1);
  --white-opacity-30: rgba(255, 255, 255, 0.3);
  --white-opacity-70: rgba(255, 255, 255, 0.7);

  /* opacity */
  --readonly-opacity: 0.7;

  /* radius */
  --radius: 2px;
  --border-radius: 4px;
  --border-radius-8: 8px;
  --border-radius-12: 12px;

  /* transitions */
  --sidenav-transition: 200ms;
  --bezier-transition: cubic-bezier(0.2, 0, 0, 1);
  --base-transition-delay: 300ms;
  --fast-transition-delay: 100ms;

  /* elements-dimensions */
  --side-bar-width: calc(260px - 32px);
  --sidenav-mobile-width: 85px;
  --footer-height: 50px;
  --header-height: 78px;
  --side-bar-width: calc(282px - 32px);
  --sidenav-mobile-width: 85px;
  --sidenav-transition: 200ms;
  --logo-height: 80px;
  --logout-height: 205px;
  --sidenav-link-height: 40px;
  --sidenav-link-height-collapsed: 40px;
  --bezier-transition: cubic-bezier(0.2, 0, 0, 1);
  --border-base-color: rgba(1, 66, 148, 0.1);
  --min-footer-height: 50px;
  --line-height-base: 1.44;
  --inner-content-min-height: calc(
    100vh - var(--header-height) - var(--spacing-2xl) * 2 - var(--spacing-l) * 2
  );
  --empty-list-item-height: 70px;
  --home-header-height: 65px;

  /* container dimensions */
  --max-inner-content-width: 1200px;
  --max-inner-header-width: 1440px;

  /* shadows */
  --box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);
  --box-shadow-header: 0px 4px 12px rgba(218, 234, 248, 0.67);
  --box-shadow-sidebar: 4px 0px 12px rgba(0, 0, 0, 0.12);
  --hover-shadow: 0 4px 8px 4px #0002;

  --font-size-tiny: 11px;
  --font-size-small: 12px;
  --font-size-base: 14px;
  --font-size-main: 16px;
  --font-size-medium: 20px;
  --font-size-extra-medium: 24px;
  --font-size-large: 32px;
  --font-size-extart: 48px;

  /* spacings */
  --spacing-xxs: 4px;
  --spacing-xs: 8px;
  --spacing-sx: 10px;
  --spacing-s: 12px;
  --spacing-m: 16px;
  --spacing-18: 18px;
  --spacing-l: 20px;
  --spacing-xl: 24px;
  --spacing-xxl: 28px;
  --spacing-2xl: 30px;
  --spacing-3xl: 36px;
  --spacing-4xl: 40px;
  --spacing-5xl: 44px;
  --spacing-6xl: 48px;
  --spacing-7xl: 50px;
  --spacing-8xl: 56px;
  --spacing-9xl: 60px;
  --spacing-10xl: 82px;

  --line-height-main: 1.44;
  --line-height-medium: 1.33;
  --line-height-small: 1.2;

  --slide-up-animation: SlideUp 0.3s cubic-bezier(0, 0.96, 0.35, 0.94);
  --fade-in-animation: FadeInSlideRight 0.5s cubic-bezier(0.45, 1.08, 1, 1);
  --fade-in-default: fadeIn 0.3s ease-out;
  --fade-in-slide-left: FadeInSlideLeft 0.3s ease-in-out;
  --fade-in-slide-left-small: FadeInSlideLeftSmall 0.3s ease-in-out;
  --fade-bounce-in-right: bounceInRight;
  --fade-in: fadeIn;
}

@keyframes SlideUp {
  0% {
    opacity: 0;
    transform: translateY(100px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes FadeInSlideRight {
  0% {
    opacity: 0;
    transform: translateX(100px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes FadeInSlideLeft {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes FadeInSlideLeftSmall {
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes tickerScroll {
  0% {
    transform: translate3d(0, 0, 0);
  }

  100% {
    transform: translate3d(-108rem, 0, 0);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes bounceInRight {
  0%,
  60%,
  75%,
  90%,
  100% {
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: translate3d(3000px, 0, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0);
  }
  75% {
    transform: translate3d(10px, 0, 0);
  }
  90% {
    transform: translate3d(-5px, 0, 0);
  }
  100% {
    transform: none;
  }
}
