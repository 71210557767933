.videoWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.videoTitle {
  margin-bottom: var(--spacing-xs);
}

.card {
  display: flex;
  gap: var(--spacing-xs);
  position: relative;
  width: 100%;
  padding: var(--spacing-m);
  background: var(--white);
  border-radius: var(--border-radius);
  border: 1px solid var(--main-color-border);
  max-height: 209px;

  .videoContent {
    position: relative;
    transition: all 0.3s;
    border-radius: var(--border-radius);
    flex: 0 0 40%;
    max-width: 352px;
    overflow: hidden;
    cursor: pointer;
    background-position: center;
    background-size: cover;
    aspect-ratio: 16/9;

    &:hover {
      .playIcon {
        transform: scale(1.1);
        transition: transform 0.3s ease;
      }
    }

    .playIcon {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      transform: scale(0.8);
      transition: transform 0.3s ease;
    }
    .videoPreview {
      position: relative;
      display: flex;
      overflow: hidden;
      filter: blur(1px);
      height: 100%;
      // background: #000;
    }

    .video {
      width: 100%;
      height: auto;
      transition: transform 0.3s;
    }
  }
  .description {
    flex: 1 1 70%;
    height: auto;
    overflow-y: auto;
    border-radius: var(--border-radius);
    background-color: var(--secondary);
    box-shadow: var(--box-shadow);

    .inner {
      padding: var(--spacing-l);
      color: var(--white);
      > p:not(:last-child) {
        margin-bottom: var(--spacing-xs);
      }
    }
  }
}

@media (max-width: 767px) {
  .card {
    flex-direction: column;
    max-height: 100%;
    .videoContent {
      max-width: 100%;
    }
  }
  .description {
    max-height: 200px;
  }
}

.videoTutorialContainer {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 80%;
  background-color: var(--white);
  padding: var(--spacing-l);
  border-radius: var(--border-radius);

  .close {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    width: 50px;
    height: 50px;
    font-size: 0;
    cursor: pointer;

    &:before,
    &:after {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 2px;
      height: 25px;
      background-color: var(--gray-3);
      transform: rotate(45deg) translate(-50%, -50%);
      transform-origin: top left;
      transition: all 420ms;
      content: "";
    }
    &:after {
      transform: rotate(-45deg) translate(-50%, -50%);
    }
    &:hover:before,
    &:hover:after {
      background-color: var(--primary);
    }
  }

  .title {
    font-size: var(--font-size-medium);
    font-weight: 600;
    color: var(--primary);
    margin-bottom: var(--spacing-m);
  }
  .inner {
    display: flex;
    justify-self: center;
    align-items: center;
    border: 1px solid var(--main-border);
    border-radius: var(--border-radius);
    padding: var(--spacing-l);

    > video {
      height: auto;
      width: 100%;
    }
  }
}
