@import "@styles/mixins";

.container {
  --slogan-width: 250px;

  @include flexContainer(row, center);

  width: var(--slogan-width);

  &.asLink {
    cursor: pointer;
  }
  &.medium {
    --slogan-width: 175px;
  }
  &.small {
    --slogan-width: 130px;
  }
  > svg {
    width: 100%;
    height: auto;
  }
}
