@import "@styles/mixins";

.container {
  --icon-size: 36px;
}

.container {
  @include flexContainer(column, flex-start, flex-start);

  position: relative;
  padding: var(--spacing-s);
  width: 100%;
  row-gap: var(--spacing-s);
  border-radius: var(--border-radius);
  border: 1px solid var(--main-color);
  background-color: var(--white);
  cursor: pointer;
  transition: var(--base-transition-delay);
  margin-bottom: var(--spacing-l);

  &[data-collapsed="true"] {
    justify-content: center;
    align-items: center;
    width: calc(100% - 16px);
    margin-bottom: 20px;
    row-gap: 0;
    padding: 8px;

    .title {
      font-size: var(--font-size-small);
      font-weight: 500;
      max-width: 55px;
      margin-bottom: 4px;
    }
    .infoSection {
      justify-content: center;
      .itemsGroup {
        display: none;
      }
    }
  }

  .title {
    font-size: var(--font-size-main);
    font-weight: 600;
    color: var(--primary);
    white-space: nowrap;
    overflow: hidden;
  }

  .infoSection {
    @include flexContainer(row, flex-start, center);

    width: 100%;
    column-gap: var(--spacing-s);

    .iconWrapper {
      @include flexContainer(row);

      width: var(--icon-size);
      height: var(--icon-size);
      min-width: var(--icon-size);
      color: var(--success);

      > svg {
        width: 100%;
        height: 100%;
      }
    }
    .itemsGroup {
      @include flexContainer(column, flex-start flex-start);

      width: 100%;
      row-gap: var(--spacing-s);
      padding-left: var(--spacing-s);
      border-left: 1px solid var(--main-border);
      .item {
        @include flexContainer(row, space-between);

        width: 100%;
        .itemLabel {
          font-size: var(--font-size-small);
          font-weight: 400;
          color: var(--main-color);
          opacity: 0.6;
        }
      }
    }
  }
}

.sideSection {
  @include flexContainer(column, flex-start, flex-start);

  max-width: 320px;
  width: 320px;
  min-height: 92px;
  background-color: var(--white);
  position: fixed;
  left: calc(100% + 50px);
  bottom: 0;
  padding: var(--spacing-l);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  z-index: 200;
  animation: var(--fade-in-slide-left-small);

  &[data-collapsed="true"] {
    &::after {
      bottom: calc(72px / 2 - 8px / 2);
    }
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    left: -4px;
    bottom: calc(52px - 8px / 2);
    width: 8px;
    height: 8px;
    transform: rotate(45deg);
    background-color: var(--white);
    z-index: 199;
  }

  .list {
    @include flexContainer(column, flex-start, flex-start);

    width: 100%;

    .listItem {
      @include flexContainer(row, flex-start);

      width: 100%;
      font-size: var(--font-size-base);
      font-weight: 400;
      color: var(--main-color);
      transition: var(--base-transition-delay);
      padding: 4px 0;
      overflow: hidden;
      cursor: pointer;

      .listItemLabel {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 100%;
      }

      &:hover:not([data-title]) .listItemLabel {
        color: var(--secondary);
        text-decoration: underline;
      }

      &[data-title] {
        font-weight: var(--font-size-base);
        font-weight: 600;
        cursor: default;

        &:not(:first-child) {
          margin-top: var(--spacing-xs);
        }
      }
    }
  }
}
