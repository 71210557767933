@import "@styles/mixins.scss";
.container {
  // @include flexContainer();
  width: 100%;
  .apply {
    margin-top: var(--spacing-m);
    display: flex;
    justify-content: flex-end;
  }
}
