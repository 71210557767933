@import "@styles/mixins";

.container {
  @include flexContainer(row, flex-start, flex-start);

  width: 100%;
  padding: var(--spacing-m);
  margin-top: var(--spacing-4xl);
  background-color: var(--text);
  color: var(--white);
  text-align: center;
  font-size: var(--font-size-base);
  min-height: var(--min-footer-height);

  &.withoutStyle {
    padding: 0 var(--spacing-m);
    background-color: transparent;
    margin-top: var(--spacing-l);
    min-height: auto;
    color: var(--primary);

    .inner {
      > span {
        > a,
        span {
          color: var(--primary);
        }
      }
    }
  }

  .inner {
    display: flex;
    flex-wrap: wrap;
    row-gap: var(--spacing-s);
    column-gap: var(--spacing-2xl);

    > span {
      display: flex;
      flex-wrap: wrap;
      gap: var(--spacing-2xl);

      > a,
      span {
        opacity: 0.5;
        transition: 0.3s;
        color: var(--white);
        text-decoration: none;
        cursor: pointer;
        &:hover {
          opacity: 1;
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .container {
    justify-content: center;
    .inner {
      justify-content: center;
    }
  }
}
