@import "@styles/mixins";

.container {
  --max-form-width: 550px;

  overflow: hidden;
}

.container {
  @include flexContainer(column, flex-start, flex-start);

  width: 100%;
  flex-grow: 1;
  color: var(--white);
  padding: var(--spacing-4xl);
  padding-bottom: 0;

  .inner {
    @include flexContainer(column, flex-start, center);

    width: 100%;
    padding-top: var(--spacing-7xl);
    row-gap: var(--spacing-l);
    flex-grow: 1;
    max-width: var(--max-inner-content-width);
    margin: 0 auto;
    animation: var(--slide-up-animation);

    .formContainer {
      @include flexContainer(column, center, center);

      max-width: var(--max-form-width);
      width: 100%;
      height: 100%;

      .loader {
        @include flexContainer(row, center);

        width: 100%;
        height: 30px;
        padding: var(--spacing-l) 0;
      }
      .mainForm {
        @include flexContainer(column, flex-start, stretch);

        width: 100%;
        background-color: var(--white-opacity-10);
        padding: var(--spacing-l);
        border-radius: var(--border-radius);

        .title {
          font-size: var(--font-size-extra-medium);
          font-weight: 400;
          line-height: var(--line-height-small);
          text-transform: capitalize;
          margin-bottom: var(--spacing-l);
          text-align: center;
          width: 100%;

          .topText {
            color: var(--secondary-lighten);
            font-weight: 600;
          }
        }

        .fieldWrapper {
          @include flexContainer(row, stretch, center);

          width: 100%;
        }
        .btnWrapper {
          @include flexContainer(row, center);

          width: 40%;
          margin: 0 auto;
        }
      }
    }
    .message,
    .pageLoader {
      @include flexContainer(column, center);

      flex-grow: 1;
      width: 100%;
    }

    .message {
      .messageContent {
        @include flexContainer(column, center);

        width: 100%;
        padding: var(--spacing-4xl);
        border-radius: var(--border-radius);
        background-color: var(--white-opacity-10);
        max-width: 650px;
        min-height: 250px;
        text-align: center;
        animation: var(--slide-up-animation);

        .title {
          font-size: var(--font-size-large);
          font-weight: 600;
          margin-bottom: var(--spacing-2xl);
        }
        .text {
          font-size: var(--font-size-medium);
          font-weight: 600;
          margin-bottom: var(--spacing-xs);
        }
        .footnote {
          font-size: var(--font-size-base);
          font-weight: 400;
          opacity: 0.7;
        }
        .image {
          width: 150px;
          height: auto;
          margin-bottom: var(--spacing-l);
        }
      }
    }
  }
}
