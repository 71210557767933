.container {
  margin-bottom: 10px;
  .icon {
    cursor: pointer;
  }
  .iconMute {
    svg {
      path {
        fill: red;
      }
    }
    cursor: no-drop;
  }
}

.filterContainer {
  position: relative;
  width: 100%;
  height: auto;
  background-color: white;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  padding: 20px;
  .inner {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(32.33%, 250px));
    padding: var(--spacing-m) 0;
    gap: var(--spacing-xs);
    .filterHead {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .label {
      font-size: var(--font-size-main);
      font-weight: 600;
      margin-bottom: 10px;
    }
    .checkbox {
      margin-top: 10px;
    }
    .resetFilter {
      display: flex;
      margin-top: var(--spacing-m);
    }
  }
  .apply {
    margin-top: var(--spacing-m);
    display: flex;
    justify-content: flex-end;
    button {
      margin-left: 10px;
    }
  }
}
