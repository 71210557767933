.filterContainer {
  .itemsGroup {
    display: flex;
    justify-content: space-between;
    gap: var(--spacing-m);

    &:not(:last-child) {
      margin-bottom: var(--spacing-m);
    }
    .wrapper {
      width: 100%;
    }
  }
}
