.container,
.formContainer,
.formContainer > form {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.container {
  padding: var(--spacing-7xl) 0;
  padding-bottom: 0;
  row-gap: var(--spacing-4xl);
  width: 100%;
  color: var(--white);
  flex-grow: 1;

  .scrollContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 var(--spacing-l);
    flex-grow: 1;
    justify-content: center;
    padding-bottom: var(--spacing-4xl);
    animation: SlideUp 0.3s ease-in-out;
  }
}
.formContainer {
  position: relative;
  background-color: var(--white-opacity-10);
  border-radius: var(--border-radius);
  min-width: 300px;
  max-width: 500px;
  width: 100%;
  padding: var(--spacing-l);
  overflow: hidden;
  border-radius: var(--border-radius);
}
.formContainer > form {
  width: 100%;
}
.formContainer .inputWrapper {
  width: 100%;
}
.formInnerWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.frontBg {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: var(--bg-white-opacity);
  z-index: 10;
  backdrop-filter: grayscale(1);
}
.form {
  margin-bottom: var(--spacing-m);
}
.loaderContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: var(--spacing-4xl);
  padding: var(--spacing-l);
}
.title {
  font-size: var(--font-size-extra-medium);
  color: var(--white);
  margin-bottom: var(--spacing-m);
  font-weight: 500;
}

.showAnimated {
  display: block;
  animation: SlideRight 0.3s ease-in-out;
}
.hidden {
  display: none;
}
.hideAnimated {
  display: none;
  animation: SlideLeft 0.3s ease-in-out;
}
.loginAnimated {
  display: none;
  animation: SlideLeft 1s ease-in-out;
}

@keyframes SlideUp {
  0% {
    transform: translateY(100px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

@keyframes SlideRight {
  0% {
    transform: translateX(200px);
    opacity: 0;
  }
  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}

@keyframes SlideLeft {
  0% {
    display: block;
    transform: scale(1);
    opacity: 1;
  }
  100% {
    display: block;
    transform: translateX(0.2);
    opacity: 0;
  }
}
