.container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-width: calc(200px + var(--spacing-m) * 2);

  .inner {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    max-height: 600px;
    min-height: 200px;

    .loaderContainer {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
