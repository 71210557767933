@import "@styles/mixins.scss";

.container {
  @include flexContainer(column, flex-start, stretch);

  flex-grow: 1;
  width: 100%;

  .inner {
    @include flexContainer(column, flex-start, stretch);

    width: 100%;
    flex-grow: 1;
    border-radius: var(--border-radius);
    min-height: var(--inner-content-min-height);

    .content {
      @include flexContainer(column, flex-start, stretch);

      flex-grow: 1;
      width: 100%;
      row-gap: var(--spacing-2xl);
      background-color: var(--white);
      position: relative;

      .userForm {
        @include flexContainer(column, flex-start, flex-start);

        &.disabled {
          opacity: 0.1;
        }

        flex-grow: 1;
        width: 100%;
        row-gap: var(--spacing-m);

        .selectedItemContainer {
          @include flexContainer(column, flex-start, flex-start);

          width: 100%;
          row-gap: var(--spacing-xs);

          > p {
            color: var(--primary);
            opacity: 0.7;
          }
        }

        .rowGroup {
          @include flexContainer(row, flex-start, flex-end);

          width: 100%;
          column-gap: var(--spacing-m);

          .labelWrapper {
            @include flexContainer(row, flex-start, center);

            column-gap: var(--spacing-xs);
            margin-bottom: var(--spacing-xs);
            width: 100%;

            > span:first-child {
              opacity: 0.8;
            }
            .link {
              display: flex;
              align-items: center;
              column-gap: var(--spacing-xs);
              margin-left: var(--spacing-m);
              color: var(--primary);
              font-size: var(--font-size-base);
              font-weight: 500;
              margin-left: auto;
              cursor: pointer;
              transition: 0.1s;

              &:hover {
                filter: brightness(1.2);
                color: var(--secondary);

                .iconContainer > svg > path {
                  fill: var(--secondary);
                }
              }
              .iconContainer {
                width: 22px;
                display: flex;

                > svg {
                  width: 100%;
                  height: auto;
                  > path {
                    transition: 0.1s;
                    fill: var(--primary);
                  }
                }
              }
            }

            .infoIconContainer {
              @include flexContainer(row, flex-start, center);

              opacity: 0.7;
              width: 18px;
              height: auto;
              transition: 0.1s;
              cursor: pointer;

              &:hover {
                opacity: 1;
              }
              > svg {
                width: 100%;
                height: auto;

                > path {
                  fill: var(--warning);
                }
              }
            }
          }
          .buttonWrapper {
            width: 100%;
            display: flex;
          }
          .loaderWrapper {
            @include flexContainer(row, flex-end, center);

            width: 100%;
          }
        }
      }
      .progressContainer {
        @include flexContainer;

        position: absolute;
        inset: -5px;
        z-index: 10;
        backdrop-filter: blur(8px);
        animation: FadeIn 0.3s ease;
        border-radius: var(--border-radius);
      }
    }
    .resultSection {
      @include flexContainer(column, flex-start, flex-start);

      width: 100%;

      &.marginTop {
        margin-top: var(--spacing-2xl);
      }
    }
  }
}

@media (max-width: 991px) {
  .container .inner .content .userForm .rowGroup {
    flex-direction: column;
    row-gap: var(--spacing-m);
  }
}
