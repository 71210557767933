.App {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  height: 100dvh;
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
}
