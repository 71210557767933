@import "@styles/mixins";

.container {
  --bottom-shift: 108px;
}

.container {
  @include flexContainer(column, flex-start, center);

  width: 100%;
  padding: var(--spacing-l) var(--spacing-9xl);
  position: fixed;
  bottom: var(--bottom-shift);
  z-index: 400;
  display: none;

  &.show {
    display: flex;
    animation: ZoomIn 0.3s ease-out;
  }

  .inner {
    @include flexContainer(column, flex-start, flex-start);

    width: 100%;
    max-width: 550px;
    border-radius: var(--border-radius);
    background-color: var(--black-opacity-90);
    box-shadow: var(--box-shadow);
    padding: var(--spacing-l);
    color: var(--white);
    row-gap: var(--spacing-l);

    .topGroup {
      @include flexContainer(row, flex-start);

      width: 100%;
      column-gap: var(--spacing-l);

      .title {
        width: 100%;
        font-size: var(--font-size-extra-medium);
        font-weight: 600;
        color: inherit;
      }
      > img {
        width: 32px;
        height: auto;
      }
    }

    .text {
      font-size: var(--font-size-base);
      font-weight: 400;
      color: inherit;
      line-height: var(--line-height-small);
    }
    .btnGroup {
      @include flexContainer(row, flex-end, flex-start);

      width: 100%;
      column-gap: var(--spacing-l);

      .btnWrapper {
        display: block;
      }
    }
  }
}

@media (max-width: 991px) {
  .container {
    --bottom-shift: 212px;
  }
}

@media (max-width: 575px) {
  .container {
    --bottom-shift: 275px;
    padding: var(--spacing-l);

    .inner {
      .btnGroup {
        flex-wrap: wrap;
        justify-content: center;
        row-gap: var(--spacing-xs);
      }
      .title,
      .text {
        width: 100%;
        text-align: center;
      }
      .text {
        text-align: justify;
      }
    }
  }
}

@keyframes ZoomIn {
  0% {
    opacity: 0;
    transform: scale(0.3);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
