@import "@styles/mixins";

.groupSection {
  @include flexContainer(column, flex-start, flex-start);

  width: 100%;
  row-gap: var(--spacing-m);
  border: 1px solid var(--main-color-separator);
  border-radius: var(--border-radius);
  padding: var(--spacing-m);

  .assetGroupSection {
    width: 100%;
    color: var(--primary);

    .assetGroupTitle {
      font-size: var(--font-size-main);
      font-weight: 500;
      margin-bottom: var(--spacing-xxs);

      &.file {
        color: var(--secondary);
      }
    }
    .assetsGroupList {
      @include flexContainer(row, stretch, flex-start);

      flex-wrap: wrap;
      width: 100%;
      column-gap: var(--spacing-xs);
      list-style: none;

      .assetGroupItem {
        @include flexContainer(row, flex-start);

        position: relative;
        align-items: center;
        padding: 4px var(--spacing-xs);
        color: var(--main-color-separator);
        font-size: var(--font-size-small);
        cursor: pointer;
        width: calc((100% - (3 * var(--spacing-xs))) / 4);
        transition: 0.1s;

        > span[data-index] {
          padding-left: var(--spacing-xs);

          &::before {
            opacity: 1;
            content: attr(data-index) ".";
            position: absolute;
            right: calc(100% - 8px);
            transition: 0.1s;
          }
        }
        &:hover {
          color: var(--primary);
          font-weight: 600;

          > span::before {
            opacity: 0;
          }
        }
        &:hover .iconContainer {
          opacity: 1;
          pointer-events: all;
        }

        .iconContainer {
          @include flexContainer;

          position: absolute;
          left: -8px;
          width: 18px;
          opacity: 0;
          transition: 0.1s;
          pointer-events: none;
          z-index: 10;

          > svg {
            width: 100%;
            height: auto;
          }
        }
      }
    }
  }

  .emptyContainer {
    @include flexContainer;

    width: 100%;
    padding: var(--spacing-2xl);
    font-size: var(--font-size-main);
    font-weight: 500;
    color: var(--muted-text);
  }
}

@media (max-width: 991px) {
  .groupSection .assetGroupSection .assetsGroupList .assetGroupItem {
    width: calc((100% - var(--spacing-xs)) / 2);
  }
}

@media (max-width: 575px) {
  .groupSection .assetGroupSection .assetsGroupList .assetGroupItem {
    width: 100%;
  }
}
