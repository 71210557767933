.container {
  padding: 0 10px;
  .tagContent {
    display: flex;
    flex-direction: column;
    .comma ~ .comma:not(:empty):before {
      content: ", ";
    }
  }
  .sectionName {
    font-size: var(--spacing-m);
    font-weight: bold;
    margin-bottom: var(--spacing-xs);
  }
  .select {
    margin-bottom: var(--spacing-xs);
    display: flex;
    align-items: flex-end;
  }
  .content {
    width: 250px;
  }
  .remove {
    display: flex;
    height: 40px;
    margin-left: var(--spacing-sx);
    img {
      width: 18px;
      align-self: center;
      cursor: pointer;
      transition: all 0.3s;
      &:hover {
        opacity: 0.7;
      }
    }
  }
}
