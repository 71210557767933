@import "@/styles/mixins.scss";

.container {
  @include flexContainer(column, flex-start, flex-start);

  margin-top: var(--spacing-2xl);
  width: 100%;

  &:last-child {
    margin-top: 0;
  }
  .headingGroup {
    @include flexContainer(row, flex-start);

    margin-bottom: var(--spacing-m);
  }
  .wrapper {
    @include flexContainer(column, flex-start, flex-start);

    width: 100%;
    min-height: 150px;

    &.noPadding {
      padding: 0;
      border: none;
      box-shadow: none;
    }

    .inner {
      width: 100%;
      overflow: hidden;
      padding-bottom: var(--spacing-xl);

      .loaderContainer {
        @include flexContainer();
        height: 100%;
      }
      .listWrapper {
        --min-item-width: 350px;

        width: 100%;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(var(--min-item-width), 1fr));
        row-gap: var(--spacing-m);
        margin-top: 10px;

        .createNewItem {
          width: 100%;
          width: 322px;
          padding-right: var(--spacing-m);
          .newItem {
            width: 100%;
          }
        }

        @media (max-width: 575px) {
          --min-item-width: 280px;
        }
      }
    }

    .paginationContainer {
      width: 100%;
      margin-bottom: var(--spacing-l);
      padding-right: var(--spacing-l);
    }
  }

  .searchWrapper {
    @include flexContainer(row, flex-start);

    width: 100%;
    column-gap: var(--spacing-l);
    margin-bottom: var(--spacing-l);
  }
}
