.container {
  display: flex;
  flex-direction: column;
  width: 100%;

  .inner {
    position: relative;
    display: flex;
    align-items: flex-start;
    width: 100%;
    gap: var(--spacing-m);
    padding: var(--spacing-xs) var(--spacing-m);
    padding-right: var(--spacing-4xl);
    border: 1px solid var(--main-border);
    border-radius: var(--border-radius);

    &[data-disabled="true"] {
      .text {
        color: var(--readonly);
      }
    }

    > span {
      color: var(--main-color);
    }

    .text {
      color: var(--main-color);
      width: 100%;

      &.placeholder {
        opacity: 0.5;
      }
    }
    .iconContainer {
      position: absolute;
      top: var(--spacing-xs);
      right: var(--spacing-m);
      height: 20px;
      width: 20px;
      cursor: pointer;

      > svg {
        width: 100%;
        height: auto;
      }
    }
  }
  .error {
    display: flex;
    align-items: center;
    width: 100%;
    padding-top: var(--spacing-xs);
    padding-left: var(--spacing-m);
    padding-right: var(--spacing-m);

    .icon {
      display: flex;
      align-items: center;
      > svg {
        width: 16px;
        height: 16px;
        path {
          fill: var(--error);
        }
      }
    }
    > p {
      width: 100%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      color: var(--error);
      font-size: var(--font-size-base);
      padding-left: var(--spacing-xs);
    }
  }
}
