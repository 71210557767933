@import "@styles/mixins";

.container {
}

.container {
  // min-height: calc(100vh - var(--header-height) - var(--spacing-2xl) * 2 - var(--spacing-l) * 2);
  @include flexContainer(column, flex-start, flex-start);

  width: 100%;
  flex-grow: 1;

  .heading {
    color: var(--secondary);
    font-size: var(--font-size-extra-medium);
    margin-bottom: var(--spacing-2xl);
  }
  .search {
    margin-top: var(--spacing-2xl);
  }

  .foldersSection,
  .foldersSection .inner {
    @include flexContainer(column, flex-start, flex-start);

    height: 100%;
    width: 100%;
    flex-grow: 1;
  }
}
