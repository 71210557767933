@import "@styles/mixins";

.logoWrapper {
  --logo-width: 115px;
}

.logoWrapper {
  @include flexContainer(column, center);

  width: var(--logo-width);

  &.small {
    --logo-width: 115px;
  }
  &.medium {
    --logo-width: 175px;
  }
  &.large {
    --logo-width: 250px;
  }
  > svg {
    width: 100%;
    height: auto;
  }

  &.active {
    cursor: pointer;
  }
}
