@import "@styles/mixins";

.container {
  --min-container-height: 61px;
  --max-container-height: 400px;
  --padding-vertical: 12px;

  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  column-gap: var(--spacing-xs);
  align-items: flex-start;
  width: calc(100% + 40px);
  left: calc(var(--spacing-l) * -1);
  top: calc(var(--spacing-l) * -1);
  padding: var(--padding-vertical) var(--spacing-m);
  background-color: var(--primary);
  border-radius: var(--border-radius) var(--border-radius) 0 0;
  overflow: hidden;

  .wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: hidden;
    height: 0;
    opacity: 0;

    &.show {
      opacity: 1;
      height: auto;
    }

    .infoContainer {
      width: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      margin-top: var(--spacing-m);
      background-color: var(--white);
      border-radius: var(--border-radius);

      .faqDescription {
        width: 100%;
        padding: var(--spacing-m);
        margin: var(--spacing-xs) 0;
        background-color: var(--white);
        border-radius: var(--border-radius);
        > p {
          color: var(--primary);
        }
        .subHeading {
          font-size: var(--font-size-medium);
          font-weight: bold;
          margin-bottom: var(--spacing-l);
        }
        .list {
          list-style: none;
          padding-left: var(--spacing-m);

          .item {
            > p {
              font-size: var(--font-size-main);
              font-weight: 600;

              &:nth-child(2) {
                font-size: var(--font-size-base);
                line-height: var(--line-height-medium);
                font-weight: 400;
                padding: var(--spacing-m) var(--spacing-xl);
                display: none;

                &::before {
                  content: "A:";
                  margin-right: var(--spacing-xs);
                  font-size: var(--font-size-main);
                  font-weight: 600;
                }
              }
              &:first-child {
                padding: var(--spacing-xxs) 0;
                cursor: pointer;

                &:before {
                  content: "Q:";
                  margin-right: var(--spacing-xs);
                }
              }
            }
            &.active {
              > p:nth-child(2) {
                display: flex;
                animation: flyIn 0.1s ease-in;
              }
            }
          }
        }
      }

      .inner {
        padding: var(--spacing-l);
        margin: var(--spacing-xs) 0;
        border-radius: var(--border-radius);
        color: var(--primary);
        font-size: var(--font-size-base);

        > p:not(:last-child) {
          margin-bottom: var(--spacing-xs);
        }
        .subHeading {
          font-size: var(--font-size-medium);
          font-weight: bold;
          margin-bottom: var(--spacing-l);
        }
      }
    }
  }

  &.active > svg {
    cursor: pointer;
  }
  > svg {
    height: 28px;
    min-width: 28px;
    transition: 0.3s;

    &:hover {
      transform: scale(1.1);
    }
  }
  &.info.open {
    max-height: var(--max-container-height);
  }
  &.info {
    max-height: var(--min-container-height);
    transition: max-height 0.1s;

    > h2 {
      font-size: var(--font-size-extra-medium);
      color: var(--secondary);
      width: auto;
    }
  }
  .tutorialBox {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: var(--spacing-xs);
    margin-left: var(--spacing-2xl);
    text-decoration: none;
    color: var(--white);
    font-weight: 400;
    font-size: var(--font-size-main);
    transition: 0.1s;
    width: 110px;
    cursor: pointer;

    &:hover {
      gap: var(--spacing-m);

      > span:first-child {
        opacity: 1;
      }
    }

    > span {
      min-width: 28px;
      display: flex;
      align-items: center;
      transition: 0.1s;
      font-weight: 600;

      &:first-child {
        opacity: 0.7;
      }
    }
  }
}
.titleGroup {
  display: flex;
  align-items: center;
  column-gap: var(--spacing-m);
  width: 100%;

  .heading {
    @include flexContainer();

    font-family: Inter, sans-serif;
    color: var(--white);
    margin-bottom: 0;
    width: auto;
    font-weight: 600;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  > svg {
    min-width: 24px;
    width: 24px;
    cursor: pointer;
    opacity: 0.7;
    transition: 0.1s;

    > path {
      fill: var(--white);
    }
    &:hover {
      opacity: 1;
    }
  }
  .faq {
    font-size: var(--font-size-medium);
    font-weight: 600;
    color: var(--white);
    margin-left: auto;
    opacity: 0.7;
    transition: 0.1s;
    cursor: pointer;

    &:not(.hide):hover {
      opacity: 1;
    }
    &.hide {
      pointer-events: none;
      opacity: 0;
    }
  }
}
.heading {
  font-family: Inter, sans-serif;
  margin-bottom: 0;
  width: 100%;
  font-weight: 600;
}

.videoTutorialContainer {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 80%;
  height: 80dvh;
  height: 80vh;
  background-color: var(--white);
  padding: var(--spacing-l);
  border-radius: var(--border-radius);

  .close {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    width: 50px;
    height: 50px;
    font-size: 0;
    cursor: pointer;

    &:before,
    &:after {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 2px;
      height: 25px;
      background-color: var(--gray-3);
      transform: rotate(45deg) translate(-50%, -50%);
      transform-origin: top left;
      transition: all 420ms;
      content: "";
    }
    &:after {
      transform: rotate(-45deg) translate(-50%, -50%);
    }
    &:hover:before,
    &:hover:after {
      background-color: var(--primary);
    }
  }

  .title {
    font-size: var(--font-size-medium);
    font-weight: 600;
    color: var(--primary);
    margin-bottom: var(--spacing-m);
  }
  .inner {
    display: flex;
    position: relative;
    justify-self: center;
    align-items: center;
    border: 1px solid var(--main-border);
    border-radius: var(--border-radius);
    padding: var(--spacing-l);
    height: calc(100% - var(--spacing-l) * 2);

    > video {
      opacity: 1;
      height: 100%;
      width: 100%;
      background-color: #000000;
    }
  }
}

.main {
  font-size: var(--font-size-large);
}
.sub {
  font-size: var(--font-size-extra-medium);
  color: var(--secondary);
}
.min {
  font-size: var(--font-size-medium);
}
.min16 {
  font-size: var(--font-size-main);
}
.min14 {
  font-size: var(--font-size-base);
}

@media (max-width: 575px) {
  .titleGroup {
    margin-left: auto;

    .tutorialBox {
      > span:first-child {
        display: none;
      }
      > span:last-child {
        margin-left: var(--spacing-2xl);
      }
    }
  }
}

@keyframes flyIn {
  0% {
    opacity: 0;
    transform: translateX(50px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}
