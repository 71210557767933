.container {
  display: flex;
  padding: var(--spacing-l);
  background-color: var(--white);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  max-width: 550px;
  min-width: 400px;
  width: 100%;
  margin: 0 20px;

  .inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;

    .heading {
      width: 100%;
      padding-bottom: var(--spacing-m);
      border-bottom: 1px solid var(--main-border);
    }

    .heading,
    .icon {
      margin-bottom: var(--spacing-l);
    }
    .infoDescription {
      font-weight: 400;
      font-size: var(--font-size-main);
      opacity: 0.5;
      margin-bottom: var(--spacing-2xl);
      line-height: var(--line-height-medium);
    }

    .btnGroup {
      display: flex;
      justify-content: center;
      gap: var(--spacing-2xl);
    }
    .loaderWrapper {
      margin-bottom: var(--spacing-2xl);
      min-height: 20px;
    }
  }
}
