@import "@styles/mixins.scss";

.container {
  @include flexContainer(column, flex-start, flex-start);
  flex-grow: 1;
  // height: calc(100vh - var(--spacing-2xl) * 2 - var(--header-height));
  min-height: 100%;
  width: 100%;
  overflow: hidden;

  .inner {
    @include flexContainer(column);
    flex-grow: 1;
    width: 100%;
    height: 100%;
    padding: var(--spacing-l);
    background-color: var(--container-bg);
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
    animation: var(--slide-up-animation);

    .content {
      @include flexContainer(column);
      @include borderedContainer;
      padding: var(--spacing-l) var(--spacing-7xl);
      flex-grow: 1;
      width: 100%;
      height: 100%;
      padding: 0 var(--spacing-7xl);

      .text {
        margin-top: var(--spacing-2xl);
        font-size: var(--font-size-medium);
        color: var(--secondary);
        font-weight: 400;
      }
      .image {
        height: auto;
        width: 100%;
        max-width: 400px;
      }
    }
  }
}
