@import "@styles/mixins";

.container {
  @include flexContainer(column, flex-start, flex-start);

  flex-grow: 1;
  width: 100%;

  .section {
    @include flexContainer(column, flex-start, flex-start);

    flex-grow: 1;
    width: 100%;
    margin-bottom: var(--spacing-l);
  }
}
