/* Mobile table */

.mobileTableContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  color: var(--main-color);
}
.mobileTableHederCell {
  display: flex;
  width: 100%;
  align-items: center;
  padding: var(--spacing-m);
  background-color: var(--sidebar-menu);
  font-weight: 600;
  font-size: var(--font-size-main);
  border-radius: var(--border-radius);
}
.mobileTableRowList {
  margin: 0;
  padding: 0;
  list-style: none;
}
.mobileTableRowItem {
  display: flex;
  width: 100%;
  padding: var(--spacing-s) var(--spacing-m);
  background: transparent;
}
.mobileTableRowItem:not(:last-child) {
  border-bottom: solid 1px var(--main-border);
}
.cellTitle {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding-right: var(--spacing-m);
  padding-left: var(--spacing-2xl);
}
.count {
  opacity: 0.5;
  padding-right: var(--spacing-m);
  min-width: 2rem;
}
