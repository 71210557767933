@import "@styles/mixins.scss";

.container {
  @include flexContainer(row, center);

  --image-width: 250px;

  flex-grow: 1;
  width: 100%;
  gap: var(--spacing-6xl);
  max-height: 400px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: var(--border-radius);
  border: 1px solid var(--main-color-separator);
  padding: var(--spacing-l);

  .iconWrapper {
    width: var(--image-width);

    > svg {
      width: 100%;
      height: auto;
    }
  }

  .textContainer {
    @include flexContainer(column, center);
    row-gap: var(--spacing-m);

    > p:first-child {
      font-size: var(--font-size-medium);
      font-weight: 500;
    }
    > p:last-child {
      font-size: var(--font-size-main);
      color: var(--muted-text);
    }
  }
}
