@import "@styles/mixins.scss";

.container {
  @include flexContainer(column, flex-start, flex-start);

  --button-width: 160px;

  width: 100%;
  row-gap: var(--spacing-m);
  flex-grow: 1;

  &:has(.emptyContainer) {
    justify-content: center;
  }

  .list {
    @include flexContainer(column, flex-start, flex-start);

    list-style: none;
    width: 100%;
    row-gap: var(--spacing-m);

    .item {
      @include flexContainer(row, flex-start);
      animation: FadeInZoom 0.2s ease-in-out;

      width: 100%;
      column-gap: var(--spacing-m);

      .btnWrapper {
        display: flex;
        min-width: var(--button-width);
      }
    }
  }
}

@media (max-width: 767px) {
  .container .list .item .btnWrapper {
    display: none;
  }
}

@keyframes FadeInZoom {
  0% {
    opacity: 0;
    transform: scale(0.8);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
