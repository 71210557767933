.container {
  width: 100%;

  &[data-disabled="true"] .tabsNavigation .tabNavItem {
    opacity: 0.2;
    pointer-events: none;
  }
  &[data-readonly="true"] .tabsNavigation .tabNavItem {
    opacity: var(--readonly-opacity);
    pointer-events: none;
  }
  .tabsNavigation {
    display: flex;
    align-items: flex-end;
    gap: var(--spacing-xxs);
    min-height: 40px;

    .tabNavItem {
      position: relative;
      display: flex;
      gap: var(--spacing-m);
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: 250px;
      min-width: 150px;
      // top: 1px;
      padding: var(--spacing-xs) var(--spacing-m);
      // margin-top: 4px;
      height: 36px;
      color: var(--text);
      border-top-left-radius: var(--border-radius);
      border-top-right-radius: var(--border-radius);
      border: 1px solid var(--main-color-separator);
      border-bottom: none;
      cursor: pointer;
      user-select: none;
      transition: 0.3s;
      background-color: var(--white);

      &.active {
        background-color: var(--primary);
        color: var(--white);
        margin-top: 0;
        height: 40px;
      }
      &.active {
        background-color: var(--primary);
        color: var(--white);
        .iconContainer > svg path {
          fill: var(--white);
        }
      }

      .iconContainer {
        display: flex;
        align-items: center;
        width: 22px;
        height: auto;

        &.rotated {
          transform-origin: center;
          transform: rotate(180deg);
        }
      }
      .label {
        width: 100%;
        display: block;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }
  .tabActiveContainer {
    width: 100%;
    border-radius: var(--border-radius);
    border-top-left-radius: 0;
    border: 2px solid var(--primary);
    padding: var(--spacing-l);
    background-color: var(--white);
  }
}
