@import "@styles/mixins";

.container {
  @include flexContainer(row, space-between);

  width: 100%;
  position: relative;
  padding: var(--spacing-l) var(--spacing-9xl);
  z-index: 10;
  margin-top: auto;

  .inner {
    @include flexContainer(row, space-between);

    width: 100%;
    margin: 0 auto;
    max-width: var(--max-inner-header-width);

    .footerText {
      @include flexContainer(row, space-between, center);

      font-size: var(--font-size-small);
      color: var(--white);
      text-align: center;
      row-gap: var(--spacing-xxs);
      column-gap: var(--spacing-m);

      .copyright {
        opacity: 0.8;
      }
      .footerNav {
        font-size: var(--font-size-small);
        font-weight: 500;
        > a {
          text-decoration: none;
          color: inherit;
          transition: var(--base-transition-delay);

          &:hover {
            color: var(--white-opacity-70);
          }
        }
      }
    }
  }
  &[data-full-width="true"] {
    padding: var(--spacing-l) 0;
  }
}

@media (max-width: 991px) {
  .container {
    .inner {
      justify-content: center;
    }
  }
}

@media (max-width: 575px) {
  .container {
    padding: var(--spacing-l);

    &[data-full-width="true"] {
      padding: var(--spacing-l) 0;
    }
    .inner {
      flex-direction: column;
      justify-content: center;

      .footerText {
        flex-direction: column-reverse;
      }
    }
  }
}
