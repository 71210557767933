@import "@styles/mixins";

.container {
  @include flexContainer(column, center);

  flex-grow: 1;
  width: 100%;
}

.container {
  .messageContent {
    @include flexContainer(column, center);

    width: 100%;
    padding: var(--spacing-4xl);
    border-radius: var(--border-radius);
    background-color: var(--white-opacity-10);
    max-width: 650px;
    min-height: 250px;
    text-align: center;
    animation: var(--slide-up-animation);
    color: var(--white);

    .title {
      font-size: var(--font-size-large);
      font-weight: 600;
      margin-bottom: var(--spacing-2xl);
    }
    .text {
      font-size: var(--font-size-medium);
      font-weight: 600;
      margin-bottom: var(--spacing-xs);
    }
    .footnote {
      font-size: var(--font-size-base);
      font-weight: 400;
      opacity: 0.7;
    }
    .image {
      width: 150px;
      height: auto;
      margin-bottom: var(--spacing-l);
      color: var(--white);
    }
  }
}

.container {
  &.inverse {
    .messageContent {
      background-color: var(--white);
      box-shadow: var(--box-shadow);
      color: var(--primary);

      .image {
        color: var(--secondary);
      }
    }
  }
}
