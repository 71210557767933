.container {
  position: absolute;
  right: 18px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  max-width: calc(100% - 18px);
  min-width: 32px;
  height: calc(100% - 4px);
  overflow: hidden;
  background-color: var(--white);
  border-radius: var(--border-radius);
  transition: 0.3s;
  cursor: default;
  z-index: 5;

  &.disabled {
    .navButtonContainer {
      opacity: 0.1;
      cursor: default;
    }
  }

  .navButtonContainer {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: var(--spacing-xs) var(--spacing-s);
    min-width: 32px;
    max-width: 32px;
    height: 32px;
    background-color: var(--white);
    transition: 0.3s;
    cursor: pointer;

    .arrowContainer {
      position: absolute;
      transition: opacity 0.3s;
      opacity: 0;

      .menu {
        position: relative;
        bottom: 0px;
      }

      &:nth-child(2) {
        transform: rotate(90deg);
      }
    }
    &:not(.openNav) .arrowContainer:nth-child(1) {
      opacity: 1;
    }
    &.openNav .arrowContainer:nth-child(2) {
      opacity: 1;
    }
  }
  .favIconContainer {
    position: absolute;
    right: 5px;
    bottom: -4px;
  }
  .itemsList {
    list-style: none;
    display: flex;
    justify-content: center;
    gap: var(--spacing-xs);
    width: calc(100% - 32px - 16px);
    padding-top: var(--spacing-m);
    overflow: hidden;
    opacity: 0;
    transition: 0.7s;

    &.open {
      opacity: 1;
    }
    .item {
      display: flex;
      width: 38px;
      flex-direction: column;
      align-items: center;
      gap: var(--spacing-xs);
      padding: var(--spacing-xs);
      cursor: pointer;

      &.disabled {
        opacity: 0.3;
        pointer-events: none;
      }

      > svg {
        transition: 0.1s;
        height: 24px;
        width: auto;
      }
      &:hover > svg {
        transform: scale(1.3);
      }
      &:hover > p {
        opacity: 1;
      }
      > p {
        margin-top: auto;
        font-size: var(--font-size-small);
        opacity: 0;
        transition: 0.3s;
      }
    }
  }
}
