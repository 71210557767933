.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  &[data-disabled="true"] {
    opacity: 0.2;
    pointer-events: none;
    cursor: default;
  }

  .btnContainer {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: var(--spacing-sx) var(--spacing-m);
    padding-right: var(--spacing-4xl);
    border-radius: var(--border-radius);
    background-color: var(--primary);
    overflow: hidden;
    min-height: 40px;
    cursor: pointer;

    .title {
      color: var(--white);
      font-size: var(--font-size-base);
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      user-select: none;
    }
  }

  .collapsContainer {
    margin-top: 4px;
    width: 100%;
    overflow: hidden;
    max-height: 0;
    transition: opacity, max-height, 0.3s;
    opacity: 0;

    .collapseInner {
      width: 100%;
      padding: var(--spacing-m);
      border: 1px solid var(--main-color-separator);
      border-radius: var(--border-radius);
    }
  }

  &.open {
    .collapsContainer {
      overflow: unset;
      max-height: 500px;
      opacity: 1;
      animation: RollDown 0.3s ease-in-out;
    }
  }
  &.close {
    .collapsContainer {
      overflow: hidden;
      max-height: 0;
      opacity: 0;
      animation: RollUp 0.3s ease-in-out;
    }
  }
}

.btnContainer:before,
.btnContainer:after {
  content: "";
  position: absolute;
  width: 7px;
  border-bottom: 2px solid var(--white);
  border-radius: 4px;
  transform-origin: center center;
  transition: 0.3s;
}

.btnContainer:before {
  right: 24px;
  transform: rotate(45deg);
}

.btnContainer:after {
  right: 20px;
  transform: rotate(-45deg);
}

.container.open .btnContainer:before {
  transform: rotate(-45deg);
}

.container.open .btnContainer:after {
  transform: rotate(45deg);
}

@keyframes RollDown {
  0% {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
  }
  100% {
    opacity: 1;
    max-height: 500px;
    overflow: unset;
  }
}

@keyframes RollUp {
  0% {
    opacity: 1;
    max-height: 500px;
  }
  100% {
    opacity: 0;
    max-height: 0;
  }
}
