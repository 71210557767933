.container {
  display: inline-flex;
  justify-content: center;
  flex-grow: 1;
  overflow: hidden;
  min-width: 130px;
  font-family: inherit;

  > a.disableLink {
    opacity: 0.2;
    cursor: default;
    pointer-events: none;
  }

  a {
    text-decoration: none;
  }
  &.large {
    min-width: 200px;
    .button {
      height: auto;
      padding: 12px 24px;
      min-width: 200px;
      font-size: var(--font-size-main);
      border-radius: var(--border-radius-8);
    }
  }
  &.small {
    min-width: 100px;

    .button {
      height: auto;
      padding: 6px 12px;
      min-width: 100px;
      user-select: none;
    }
  }
  &.iconOnly {
    min-width: 40px;

    .button {
      height: 40px;
      padding: 6px 8px;
      min-width: 40px;
      user-select: none;
    }
  }
  &.inverse {
    .empty {
      color: var(--primary);
      border: 1px solid var(--primary);
      > svg path {
        stroke: var(--primary);
      }
      &:hover {
        background-color: transparent;
        backdrop-filter: none;
      }
    }
  }
}
.button {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  column-gap: var(--spacing-m);
  padding: var(--spacing-s) var(--spacing-l);
  cursor: pointer;
  border-radius: var(--border-radius);
  font-weight: 400;
  font-size: var(--font-size-base);
  white-space: nowrap;
  min-width: 130px;
  transition: 0.3s;
  height: var(--spacing-4xl);
  font-family: inherit;

  img {
    max-width: 22px;
    max-height: 22px;
  }
  > span {
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
  }
  > svg {
    max-width: 22px;
    max-height: 22px;
    min-width: 22px;
  }
}

.primary {
  background-color: var(--primary);
  border: none;
  color: var(--white);
  svg path {
    stroke: var(--white);
  }
}
.secondary {
  background-color: var(--secondary);
  border: none;
  color: var(--white);
  svg path {
    stroke: var(--white);
  }
}
.white {
  background-color: var(--white);
  border: none;
  color: var(--black-90);
  backdrop-filter: blur(10px);
}
.black {
  background-color: var(--black-90);
  border: none;
  color: var(--white);

  svg path {
    stroke: var(--white);
  }
}
.gost {
  background-color: var(--white);
  color: var(--primary);
  border: 1px solid var(--primary);
  svg path {
    stroke: var(--primary);
  }
}
.empty {
  background-color: transparent;
  color: var(--white);
  border: 1px solid var(--white);
  backdrop-filter: blur(10px);

  > svg path {
    transition: var(--base-transition-delay);
    stroke: var(--white);
  }
  &:hover {
    background-color: var(--white-opacity-70);
    color: var(--black-90);
    > svg path {
      stroke: var(--black-90);
    }
  }
}
.danger {
  background-color: var(--white);
  border: 1px solid var(--error);
  color: var(--error);
}
.download {
  background-color: var(--warning);
  border: none;
  color: var(--primary);
  svg path {
    stroke: var(--primary);
  }
}
.action {
  background-color: var(--action-color);
  color: var(--black-90);
  border: none;
  border-radius: 30px;
  min-height: 50px;

  > svg path {
    transition: var(--base-transition-delay);
    fill: var(--black-90);
  }
}

.button:active {
  transform: scale(0.98);
}

.button.white:hover {
  filter: none;
  background-color: var(--white-opacity-70);
}

.button:hover {
  filter: brightness(1.3);
}
.button.transparent {
  background-color: transparent;
  color: var(--primary);
  padding: var(--spacing-xs) var(--spacing-m);
  min-width: auto;
}
.button.transparent:hover {
  text-decoration: underline;
}
.button:disabled {
  opacity: 0.2;
  pointer-events: none;
}
.button.active {
  color: var(--secondary);
}
