.container {
  position: relative;
  .head {
    width: 100%;
    margin-bottom: var(--spacing-m);
  }
  .charts {
    margin-bottom: var(--spacing-m);
    &:last-child {
      margin-bottom: 0;
    }
  }
}
