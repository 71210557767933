@import "@styles/mixins.scss";

.wrapper {
  position: relative;
  width: 100vw;
  height: 100vh;
  height: 100dvh;
  min-height: -webkit-fill-available;
  font-family: Inter, sans-serif;

  .loaderWrapper {
    @include flexContainer();
    width: 100%;
    height: 100%;
  }

  .main {
    @include flexContainer(column, flex-start, flex-start);

    padding-top: var(--header-height);
    padding-left: var(--sidenav-mobile-width);
    transition: padding-left var(--sidenav-transition) var(--bezier-transition);
    background-size: cover;
    background-position: center center;
    background-attachment: fixed;
    overflow: hidden;
    // for mobile view webkit
    height: 100%;
    height: 100dvh;
    min-height: -webkit-fill-available;

    &.fullWidth {
      padding-left: calc(var(--side-bar-width) + 32px);
    }
    &.mobileView {
      padding-left: 0;
    }
  }
  .container {
    @include flexContainer(column, flex-start, flex-start);

    flex-grow: 1;
    position: relative;
    width: 100%;
  }
  .scrollContainer {
    @include flexContainer(column, flex-start, flex-start);

    // height: 100%;
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: none;
    width: 100%;
  }
  .scrollContainer::-webkit-scrollbar {
    display: none;
  }

  .sidebarWrapper {
    position: fixed;
    top: var(--header-height);
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0;
    background-color: var(--white);
    overflow: hidden;
    z-index: 300;
    max-height: 0;
    opacity: 0;
    transition: 0.3s;

    &.show {
      opacity: 1;
      max-height: 100%;
      padding: var(--spacing-l);
      animation: ShowMnu 0.3s ease-in-out;
    }
  }
}

@keyframes ShowMnu {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
