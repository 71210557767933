@import "@styles/mixins.scss";

.strategiesBlock {
  padding: var(--spacing-l);
  border-radius: var(--border-radius);
  @include borderedContainer(transparent);

  box-shadow: var(--box-shadow);
  margin-bottom: var(--spacing-l);
}

.core,
.overlay,
.benchmark {
  h5 {
    font-weight: 600;
    font-size: 16px;
    margin-bottom: var(--spacing-xs);
  }
}

.coreRebalance {
  display: flex;
  justify-content: space-between;
  .rebalance {
    color: var(--main-color-separator);
  }
  span {
    margin-left: var(--spacing-xl);
    color: var(--main-color);
    text-transform: capitalize;
  }
}

.benchmarkWrapper {
  padding: var(--spacing-l);
  @include borderedContainer(transparent);

  box-shadow: var(--box-shadow);
}

.overlay {
  margin-top: var(--spacing-2xl);
}
