@import "@styles/mixins";

.container {
  position: relative;

  .form {
    width: 100%;
    display: flex;
    flex-direction: column;

    .loadingFrontContainer {
      @include flexContainer();

      position: absolute;
      inset: -5px;
      z-index: 10;
      backdrop-filter: blur(8px);
      animation: FadeIn 0.3s ease;
      border-radius: var(--border-radius);
    }

    .wrapper {
      width: 100%;

      &.disable {
        pointer-events: none;
        opacity: 0.1;
      }
      &:not(:last-child) {
        margin-bottom: var(--spacing-l);
      }
      > ul.groupSection {
        list-style: none;
      }
      .subheading {
        font-size: var(--font-size-medium);
        margin-bottom: var(--spacing-xs);
        font-weight: 500;
      }
      .customSubheading {
        font-size: var(--font-size-main);
        font-weight: 500;
        margin-bottom: var(--spacing-xs);
      }
      .groupSection {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: var(--spacing-6xl);
        row-gap: var(--spacing-xs);
        border: 1px solid var(--main-color-separator);
        border-radius: var(--border-radius);
        padding: var(--spacing-m);

        .fullWidth {
          display: flex;
          width: 100%;
          flex-grow: 1;
        }

        .checBoxItem {
          width: 45%;
        }
        > li.checBoxItem {
          position: relative;
          padding-left: var(--spacing-2xl);

          &::before {
            content: "";
            display: inline-block;
            transform: rotate(45deg);
            height: 10px;
            width: 6px;
            border-bottom: 2px solid var(--secondary);
            border-right: 2px solid var(--secondary);
            color: var(--secondary);
            position: absolute;
            top: 2px;
            left: 8px;
          }
        }
        .radioGroup {
          width: 100%;
          display: grid;
          grid-template-columns: repeat(2, minmax(200px, 1fr));
          row-gap: var(--spacing-xs);
          column-gap: var(--spacing-6xl);
          margin-bottom: var(--spacing-s);
        }
      }
    }
    .group {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: var(--spacing-2xl);
      width: 100%;
      flex-wrap: wrap;
    }
  }
}

@media (max-width: 767px) {
  .container .form .wrapper .groupSection {
    .radioGroup {
      grid-template-columns: minmax(200px, 1fr);

      .lastRadioItem {
        grid-column: 1/2;
      }
    }
  }
  .container .form .wrapper .groupSection .checBoxItem {
    width: 100%;
  }
}

@keyframes FadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
