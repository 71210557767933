@import "@styles/mixins";

.container {
  position: relative;
  width: 100%;

  &.disabled:not(.customLabel) {
    .innerWrapper {
      > .labelWrapper {
        .labelInner {
          // opacity: 0.2;
          pointer-events: none;
        }
      }
    }
  }
  &.disabled {
    .innerWrapper {
      > .labelWrapper {
        .inputContainer {
          opacity: 0.2;
          pointer-events: none;
        }
      }
    }
  }
  &[data-readonly="true"] {
    .innerWrapper {
      > .labelWrapper {
        .inputContainer {
          opacity: var(--readonly-opacity);
          pointer-events: none;
        }
      }
    }
  }

  .innerWrapper {
    > .labelWrapper {
      .labelInner {
        display: flex;
        justify-content: space-between;
        row-gap: var(--spacing-m);
        width: 100%;
        overflow: hidden;
        cursor: default;
        margin-bottom: 8px;

        > .label {
          > .labelText {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            text-transform: capitalize;
            color: var(--muted-text);
          }
        }
      }
      .inputContainer {
        position: relative;
        width: 100%;
        min-width: 140px;
        display: flex;
        align-items: center;

        .iconContainer {
          display: flex;
          align-items: center;
          position: absolute;
          left: var(--spacing-m);
          opacity: 0.3;
          z-index: 5;

          &.clear {
            right: var(--spacing-4xl);
            left: auto;
            opacity: 1;
            cursor: pointer;
          }
          > svg {
            width: 16px;
          }
        }

        .input {
          padding: 9px 16px;
          border: 1px solid var(--main-color);
          border-radius: 4px;
          width: 100%;
          padding-right: 64px;
          padding-left: var(--spacing-4xl);
          font-size: var(--font-size-main);
          color: var(--main-color);
          font-family: Inter, sans-serif;
          height: 40px;
          background-color: transparent;
          transition: 0.1s;

          &::placeholder {
            color: var(--muted-text);
          }
          // &:focus {
          //   outline-color: var(--main-color);
          //   outline-width: 3px;
          // }
        }
      }
    }
  }
}
.dropdownContainer {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  border: 1px solid var(--primary);
  padding: var(--spacing-xs) var(--spacing-4xl) var(--spacing-xs) var(--spacing-m);
  cursor: pointer;
  border-radius: var(--border-radius);
  min-height: 40px;
  transition: 0.3s;
}
.selectedItem {
  width: 100%;
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: var(--placeholder);
  font-size: var(--font-size-main);
}

.label {
  display: flex;
  align-items: center;
  column-gap: var(--spacing-xs);
  position: relative;
  color: var(--primary);
  opacity: 0.8;
  font-size: var(--font-size-main);

  &.required::after {
    content: "(Required)";
    font-size: var(--font-size-small);
    color: var(--error);
  }

  .labelIcon {
    max-height: 22px;
    cursor: pointer;
  }
}

.selected {
  color: var(--primary);
}

.listContainer {
  position: absolute;
  left: 0;
  top: calc(100%);
  width: 100%;
  max-height: 200px;
  padding-top: 4px;
  max-height: 0;
  z-index: 50;
  transition: max-height, opacity 0.3s;
  opacity: 0;
  overflow: hidden;
  pointer-events: none;

  &.reversePosition {
    top: unset;
    bottom: calc(100% - 1em);
  }
  &.withoutLabel {
    bottom: calc(100% + var(--spacing-m));
  }
}
.container.open .listContainer {
  opacity: 1;
  max-height: 200px;
  overflow: unset;
  pointer-events: all;
}
.container.open .dropdownContainer {
  outline: 1.3px solid var(--primary);
}
.listContainer .wrapper {
  width: 100%;
  box-shadow: var(--box-shadow);
  min-height: min-content;
  border-radius: var(--border-radius);
}
.list {
  list-style: none;
  padding: var(--spacing-xs) 0;
  background-color: var(--white);
  min-height: min-content;
}
.listItem {
  display: flex;
  width: 100%;
  column-gap: var(--spacing-xs);
  align-items: center;
  padding: var(--spacing-xs) var(--spacing-m);
  transition: 0.3s;
  color: var(--primary);
  overflow: hidden;
  cursor: pointer;
  border-bottom: 1px solid var(--white);
  border-radius: var(--spacing-xxs);

  .icon {
    display: flex;
    align-items: center;
    width: 18px;
    height: 18px;
    transition: 0.3s;
    opacity: 0.3;

    > svg {
      height: 100%;
      width: auto;

      > path {
        fill: var(--secondary);
      }
    }
  }
  .info {
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    min-width: 90%;
  }

  &.empty {
    @include flexContainer;

    height: var(--empty-list-item-height);
    width: calc(100% - var(--spacing-xs) * 2);
    color: var(--muted-text);
    font-weight: 500;
    border-radius: var(--border-radius);
    border: 1px solid var(--main-border);
    margin: 0 var(--spacing-xs);
    cursor: default;
  }
  &.groupItem {
    color: var(--primary);
    background-color: var(--main-border);
    text-transform: uppercase;
    font-weight: 600;
    cursor: default;
  }

  &[data-label]:before {
    content: attr(data-label) ":";
    opacity: 0.5;
  }
}
.listItem:not(.groupItem):hover {
  background-color: var(--secondary-hover);
}
.listItem.active {
  background-color: var(--primary);
  color: var(--white);
}

.container .innerWrapper .inputContainer .loaderContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border: 1px solid var(--main-color);
  border-radius: 4px;
  padding-right: 48px;
  padding-left: var(--spacing-4xl);
  height: 40px;
}

.container .innerWrapper .inputContainer:before,
.container .innerWrapper .inputContainer:after {
  content: "";
  position: absolute;
  width: 7px;
  border-bottom: 2px solid var(--primary);
  border-radius: 4px;
  transform-origin: center center;
  transition: 0.3s;
}
.container .innerWrapper .inputContainer .input:is(:focus) {
  outline: 1.3px solid var(--primary);
}

.container .innerWrapper .inputContainer:before {
  right: 24px;
  transform: rotate(45deg);
}

.container .innerWrapper .inputContainer:after {
  right: 20px;
  transform: rotate(-45deg);
}

.container.open .innerWrapper .inputContainer:before {
  transform: rotate(-45deg);
}

.container.open .innerWrapper .inputContainer:after {
  transform: rotate(45deg);
}
