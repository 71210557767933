.container {
  width: 100%;
  height: 390px;

  .indicatorName {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-weight: 600;
  }
  .description,
  .type {
    color: var(--primary);
    opacity: 0.7;
  }
}
