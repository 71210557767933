@import "@styles/mixins";

.container {
  @include flexContainer(column, flex-start, flex-start);

  flex-grow: 1;
  width: 100%;

  .loaderContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  .innerContainer {
    @include flexContainer(column, flex-start, flex-start);

    flex-grow: 1;
    width: 100%;
    row-gap: var(--spacing-l);

    .wrapper {
      @include flexContainer(column, flex-start, flex-start);

      flex-grow: 1;
      width: 100%;
      // margin-bottom: var(--spacing-2xl);
    }
  }
}
