@import "@styles/mixins.scss";

.container {
  --bg-white-opacity-3: rgba(255, 255, 255, 0.961);
}

.container {
  @include flexContainer(column, flex-start, flex-start);

  width: 100%;
  flex-grow: 1;
  .headingGroup {
    @include flexContainer(row, space-between, flex-start);
    .subHeading {
      font-size: var(--font-size-medium);
      margin-bottom: var(--spacing-m);
    }
  }

  .wrapper {
    @include flexContainer(column, flex-start, flex-start);

    width: 100%;
    position: relative;

    .listContainer {
      display: grid;
      width: 100%;
      grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
      gap: var(--spacing-m);
    }

    .paginationContainer {
      padding-top: var(--spacing-xxs);
      width: 100%;
      margin-bottom: var(--spacing-l);
    }

    .moreDataLoadContainer {
      @include flexContainer();

      position: absolute;
      inset: 0;
      background-color: var(--bg-white-opacity-3);
    }
  }
}
