@import "@styles/mixins.scss";

.container {
  @include flexContainer(row, flex-start, center);

  --image-width: 270px;
  --image-small-width: 200px;
  --min-icon-width: 150px;

  flex-grow: 1;
  width: 100%;
  border: 1px solid var(--main-color-border);
  border-radius: var(--border-radius);
  padding: var(--spacing-l);
  column-gap: var(--spacing-m);

  .leftSide {
    @include flexContainer(row, flex-start, center);

    gap: var(--spacing-m);
    flex-wrap: wrap;

    .iconWrapper {
      width: var(--image-width);
      min-width: var(--min-icon-width);

      > svg {
        width: 100%;
        height: auto;
      }
    }
    .list {
      @include flexContainer(column, flex-start, flex-start);

      max-width: 250px;

      .text {
        color: var(--muted-text);
        font-size: var(--font-size-small);
        font-weight: 500;

        &:first-child {
          width: 100%;
          font-size: var(--font-size-main);
          font-weight: 600;
          color: var(--primary);
          margin-bottom: var(--spacing-xs);
          text-align: center;
        }
      }
    }
  }
  .rightSide {
    @include flexContainer(column, center);

    padding: var(--spacing-l);
    row-gap: var(--spacing-l);
    border: 1px solid var(--main-color-separator);
    border-radius: var(--border-radius);
    min-width: 250px;
    flex-grow: 1;

    .title {
      font-size: var(--font-size-medium);
      color: var(--secondary);
      font-weight: 600;
    }

    .btnWrapper {
      @include flexContainer(column, flex-start, stretch);
      width: 100%;
      max-width: 300px;
      flex-grow: 1;
    }
  }
}

@media (max-width: 1199px) {
  .container {
    flex-direction: column;
    align-items: stretch;
    row-gap: var(--spacing-m);

    .leftSide {
      justify-content: center;

      .iconWrapper {
        width: var(--image-small-width);
      }
    }
  }
}

@media (max-width: 575px) {
  .container {
    padding: var(--spacing-xs);

    .rightSide {
      padding: var(--spacing-xs);
    }
  }
}
