@import "@styles/mixins.scss";

.wrapper {
  position: relative;
  // height: calc(100%);
  width: 100%;

  &.loading {
    border-radius: var(--border-radius);
    max-height: calc(100vh - var(--header-height) - var(--spacing-2xl) * 2 - var(--spacing-l) * 2);
    overflow: hidden;
    background-color: var(--white);
  }

  &.loading > .inner {
    opacity: 0.1;
    pointer-events: none;
  }
  .inner {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 1fr;
    height: 100%;
    opacity: 1;
    transition: 0.3s;
  }
  .transparent {
    position: absolute;
  }
  .innerLoading {
    display: grid;
    width: 100%;
    height: 100%;
    padding: var(--spacing-m);
    min-height: 30vh;

    .innerEmpty {
      @include flexContainer;
      width: 100%;
      height: 100%;
      border: 1px solid var(--main-border);
    }
    .innerEmptyNoBorder {
      @include flexContainer;
      width: 100%;
      height: 100%;
    }
  }
}
