.container,
.formContainer,
.formContainer > form {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.container {
  flex-grow: 1;
  justify-content: center;
  animation: SlideUp 0.3s ease-in-out;
  padding: 0 var(--spacing-l);
  width: 100%;
}
.formContainer {
  position: relative;
  background-color: var(--white-opacity-10);
  box-shadow: var(--box-shadow);
  min-width: 300px;
  max-width: 500px;
  width: 100%;
  padding: var(--spacing-l);
  overflow: hidden;
}
.formContainer > form {
  width: 100%;
}
.formContainer .inputWrapper {
  width: 100%;
}
.form {
  margin-bottom: var(--spacing-m);
}
.loaderContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 30px;
  padding: var(--spacing-l);
}
.btnGroup {
  margin-top: var(--spacing-m);
}
.title {
  font-size: var(--font-size-extra-medium);
  color: var(--white);
  margin-bottom: var(--spacing-m);
  font-weight: 500;
}

@keyframes SlideUp {
  0% {
    transform: translateY(100px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
