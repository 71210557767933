@mixin borderedContainer($border: var(--main-color-separator)) {
  background-color: var(--white);
  border: 1px solid $border;
  border-radius: var(--border-radius);
}

@mixin flexContainer($dir: row, $justify: center, $align: center) {
  display: flex;
  flex-direction: $dir;
  justify-content: $justify;
  align-items: $align;
}
