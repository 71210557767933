.container {
  width: 100%;
  background-color: white;
  .description {
    width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
  }
}
