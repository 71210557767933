.container {
  width: 100%;
  position: relative;

  --error-bg: #ffe8f3;

  padding: 2px;

  > label.required {
    position: relative;
  }
  > label.required::after {
    content: "(Required)";
    font-size: var(--font-size-small);
    color: var(--error);
  }
  &.disabled .fieldWrapper {
    opacity: 0.2;
  }
  &.readOnly .fieldWrapper {
    opacity: var(--readonly-opacity);
    pointer-events: none;
    cursor: default;
  }
  .helpText {
    position: absolute;
    bottom: 2px;
    left: 0;
    color: var(--muted-text);
    font-size: var(--font-size-small);
    font-weight: 400;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    padding-left: var(--spacing-m);
    overflow: hidden;
  }

  .fieldWrapper > input {
    color: var(--primary);
  }

  &.themed {
    .fieldWrapper {
      .icon {
        color: var(--white);
      }

      .input {
        color: var(--white);
        outline: none;
        border-bottom: 2px solid transparent;
        background-color: var(--white-opacity-10);
      }
      // .input[type="email"] + .icon > svg path {
      //   fill: var(--white);
      //   stroke: transparent;
      // }
    }
    .input::placeholder,
    .phoneInput::placeholder {
      color: var(--white-opacity-30);
    }
    .input:focus,
    .phoneInput:focus {
      color: var(--white);
      outline: none;
      border-bottom-color: var(--white);
      background-position: 0 0;
      box-shadow: none;
    }
    > .label {
      color: var(--white);
    }
    .helpText {
      color: var(--white);
    }
    .input:-webkit-autofill {
      -webkit-text-fill-color: var(--white);
    }

    // autofill style configuration for WebKit
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      -webkit-background-clip: text;
      transition: background-color 0s ease-in-out 0s;
      -webkit-box-shadow: 0 0 0 1000px var(--white-opacity-30) inset !important;
      -webkit-text-fill-color: orange !important;
    }

    .error {
      bottom: calc(100% + 4px);
    }
  }
}

.paddingBottom {
  padding-bottom: var(--spacing-l);
}

.container .input {
  display: block;
  width: 100%;
  border: none;
  background-color: transparent;
  padding: var(--spacing-xs) var(--spacing-m);
  font-size: var(--font-size-main);
  color: var(--text);
  outline: 1px solid var(--primary);
  padding-right: var(--spacing-5xl);
  border-radius: var(--border-radius);
  min-height: 40px;
  transition: 0.1s;
}

.container .phoneInput {
  display: block;
  width: 100%;
  border: none;
  background-color: transparent;
  font-size: var(--font-size-main);
  color: var(--text);
  outline: 1px solid var(--primary);
  padding-right: var(--spacing-5xl);
  border-radius: var(--border-radius);
  min-height: 40px;
  transition: 0.1s;
}

input[type="number"] {
  -moz-appearance: textfield;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.fieldWrapper {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
}

:is(.fieldWrapper > input:focus) {
  box-shadow: var(--box-shadow);
}

.container .input:focus,
.phoneInput:focus {
  color: var(--text);
  outline: none;
  outline: 2px solid var(--primary);
  background-position: 0 0;
  box-shadow: var(--box-shadow);
}

.container .input:-webkit-autofill {
  -webkit-text-fill-color: var(--text);
}

.container .input::placeholder,
.phoneInput::placeholder {
  color: var(--placeholder);
}

.container .input[type="password"] ~ .icon {
  cursor: pointer;
}

.container > label {
  display: flex;
  align-items: center;
  column-gap: var(--spacing-xs);
  margin-bottom: var(--spacing-xs);
  color: var(--primary);
  opacity: 0.8;
  white-space: nowrap;
  overflow: hidden;

  > p {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.icon {
  position: absolute;
  right: var(--spacing-m);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 20px;
  height: 70%;
  z-index: 0;
  color: var(--primary);
}

// .icon > img {
//   width: 100%;
//   height: auto;
// }

.container.hasError .input {
  outline-color: var(--error);
  outline-width: 2px;
}

.container.hasError .input:-webkit-autofill {
  -webkit-box-shadow: inset 0 0 0 50px var(--error-bg) !important;
}

.error {
  position: absolute;
  display: flex;
  justify-content: center;
  left: 0;
  width: 100%;
  bottom: calc(100% - 20px);
  padding: var(--spacing-xs);
  background-color: var(--error-bg);
  z-index: 20;
  border-radius: var(--spacing-xxs);
  border: 1px solid var(--error);
  box-shadow: var(--box-shadow);
  color: var(--error);
  font-size: var(--font-size-base);
  font-weight: 500;
  line-height: var(--line-height-medium);
  animation: var(--fade-in-default);
  z-index: 10;

  &:before {
    content: "";
    position: absolute;
    bottom: -5px;

    width: 8px;
    height: 8px;
    transform: rotate(45deg);
    transform-origin: center;
    border-bottom: 1px solid var(--error);
    border-right: 1px solid var(--error);
    background-color: var(--error-bg);
    z-index: 20;
  }
}

@keyframes bounceInRight {
  0%,
  60%,
  75%,
  90%,
  100% {
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(200px, 0, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0);
  }

  75% {
    transform: translate3d(10px, 0, 0);
  }

  90% {
    transform: translate3d(-5px, 0, 0);
  }

  100% {
    transform: none;
  }
}
