@import "@styles/mixins";

.container {
  @include flexContainer(column, flex-start, flex-start);

  width: 100vw;
  height: 100%;
  flex-grow: 1;
  background-color: var(--black-90);
  font-family: "Poppins", "Inter", sans-serif;
  padding-top: var(--home-header-height);
  position: relative;

  .theme {
    position: absolute;
    inset: 0;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .inner {
    @include flexContainer(column, flex-start, flex-start);

    width: 100vw;
    height: 100%;
    flex-grow: 1;
    position: relative;
    z-index: 10;
  }
}
