@import "@styles/mixins";

.itemWrapper {
  @include flexContainer(column, flex-start, stretch);

  position: relative;
  width: 100%;

  .labelWrapper {
    @include flexContainer(row, flex-start, center);

    column-gap: var(--spacing-xs);

    .infoIconContainer {
      @include flexContainer(row, flex-start, center);

      opacity: 0.7;
      width: 18px;
      height: auto;
      transition: 0.1s;
      cursor: pointer;

      &:hover {
        opacity: 1;
      }
      > svg {
        width: 100%;
        height: auto;

        > path {
          fill: var(--warning);
        }
      }
    }
  }

  .infoContainer {
    @include flexContainer(column, flex-start, center);

    position: absolute;
    top: calc(100% + 8px);
    width: 100%;
    min-width: 300px;
    row-gap: var(--spacing-m);
    padding: var(--spacing-m);
    background-color: var(--white);
    border: 1px solid var(--warning);
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
    color: var(--primary);
    z-index: 20;
    animation: var(--fade-in-default);

    &::before {
      content: "";
      position: absolute;
      top: -5px;
      width: 8px;
      height: 8px;
      border-left: 1px solid var(--warning);
      border-top: 1px solid var(--warning);
      transform-origin: center;
      transform: rotate(45deg);
      background-color: var(--white);
      z-index: 10;
    }

    .title {
      width: 100%;
      text-align: center;
      font-size: var(--font-size-medium);
      color: var(--secondary);
    }
    .text {
      font-size: var(--font-size-base);
      color: inherit;
      &:not(:last-child) {
        margin-bottom: var(--spacing-xxs);
      }
    }
    > img {
      max-width: 230px;
      width: 100%;
      height: auto;
    }
  }
}

.buttonsGroup {
  @include flexContainer;

  width: 100%;
  gap: var(--spacing-m);

  &.smallWidth {
    width: 33%;
  }

  .loaderWrapper {
    @include flexContainer;

    width: 100px;
  }
}

@media (max-width: 991px) {
  .buttonsGroup {
    flex-wrap: wrap;

    &.smallWidth {
      width: 100%;
    }
  }
}
