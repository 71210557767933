@import "@styles/mixins";

.container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: var(--spacing-m);

  &:not(:last-child) {
    padding-bottom: var(--spacing-l);
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &[data-readonly="true"] .benchmarkWrapper > svg {
    opacity: var(--readonly-opacity);
  }

  &.benchmark {
    margin-top: var(--spacing-l);
    row-gap: var(--spacing-xs);
  }
  .benchmarkWrapper {
    @include borderedContainer(var(--main-border));
    @include flexContainer(row, flex-start);

    gap: var(--spacing-m);
    padding: var(--spacing-l);
    width: 100%;

    .inputWrapper {
      display: flex;
      flex-grow: 1;
    }
  }
}
