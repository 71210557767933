.container {
  width: 100%;
  position: relative;
  label {
    display: block;
    margin-bottom: var(--spacing-xs);
    color: var(--muted-text);
  }
  &.disabled {
    opacity: 0.3;
    pointer-events: none;
  }
}

.paddingBottom {
  padding-bottom: var(--spacing-l);
}
