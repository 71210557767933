.pagination {
  margin-top: var(--spacing-2xl);
}
.unconfirmed {
  display: flex;
  justify-content: flex-end;
  margin-top: var(--spacing-l);
  span {
    color: var(--muted-text);
    font-size: var(--font-size-base);
  }
}
