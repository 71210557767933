@import "@styles/mixins";

.container {
  @include flexContainer(column, flex-start);

  position: relative;
  background-color: var(--white);
  box-shadow: var(--box-shadow);
  min-width: 300px;
  width: 100%;
  max-width: 500px;
  width: 100%;
  padding: var(--spacing-l);
  overflow: hidden;
  animation: SlideUp 0.3s ease-in-out;
  border-radius: var(--border-radius);

  .buttonWrapper {
    @include flexContainer(row);

    width: 100%;
  }
}
.textContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: var(--spacing-l);
  color: var(--primary);
}
.iconContainer {
  margin-bottom: var(--spacing-l);
}

.title {
  font-size: var(--font-size-extra-medium);
  color: var(--black-90);
  margin-bottom: var(--spacing-m);
  font-weight: 500;
}
.subtitle,
.subtitleError {
  color: var(--secondary);
  font-size: var(--font-size-medium);
  margin-bottom: var(--spacing-l);
  text-align: center;
}
.subtitleError {
  color: var(--error);
}
.message {
  font-size: var(--font-size-main);
  padding: 0 var(--spacing-2xl);
  line-height: 1.44;
  text-align: center;
}

@keyframes SlideUp {
  0% {
    transform: translateY(100px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
