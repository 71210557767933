@import "@styles/mixins";

.container {
  @include flexContainer(row, flex-start, flex-start);
  margin-bottom: var(--spacing-m);
  overflow: hidden;
  padding-bottom: var(--spacing-m);
  position: relative;

  &.mobile {
    overflow-x: auto;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &.scroll {
    margin: 0;
    padding: 0;
    padding-bottom: var(--spacing-xxs);
  }

  width: 100%;

  .innerSlider {
    display: grid;
    grid-template-columns: repeat(5, minmax(200px, 1fr));

    width: 100%;
    column-gap: var(--spacing-xs);
  }
}

.wrapper {
  position: relative;

  .container {
    @include flexContainer(row, flex-start, flex-start);
    margin-bottom: var(--spacing-m);
    overflow: hidden;
    padding-bottom: var(--spacing-m);
    position: relative;

    &.mobile {
      overflow-x: auto;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    &.scroll {
      margin: 0;
      padding: 0;
      padding-bottom: var(--spacing-xxs);
    }

    width: 100%;
    .inner {
      display: flex;
      width: 100%;
    }
  }

  .actionButton {
    @include flexContainer(row, flex-end, center);

    position: absolute;
    right: 0;
    top: 0;
    width: 32px;
    min-height: 40px;
    border: none;
    background-color: transparent;
    background-image: linear-gradient(90deg, transparent, white),
      linear-gradient(90deg, transparent, white), linear-gradient(90deg, transparent, white),
      linear-gradient(90deg, transparent, white);

    > svg {
      width: 12px;
      height: auto;
      transform: rotate(90deg);
      opacity: 0.5;
    }
  }
}
