@import "@styles/mixins.scss";

.container {
  @include flexContainer(column, flex-start, flex-start);

  flex-grow: 1;
  width: 100%;

  .headingGroup {
    @include flexContainer(row, flex-start);

    margin-bottom: var(--spacing-m);
  }
  .searchWrapper {
    @include flexContainer(row, flex-start);

    width: 100%;
    column-gap: var(--spacing-l);
    margin-bottom: var(--spacing-l);

    .filterTitle {
      margin-bottom: var(--spacing-s);
      font-size: var(--font-size-main);
      font-weight: 500;
    }

    .filterCheckList {
      @include flexContainer(column, flex-start, flex-start);

      row-gap: var(--spacing-m);
      padding-left: var(--spacing-xs);

      &:nth-child(4),
      &:nth-child(2) {
        margin-bottom: var(--spacing-l);
      }
    }
    .filterButtonWrapper {
      @include flexContainer(column, flex-start, center);

      width: 100%;
    }
  }
  .wrapper {
    @include flexContainer(column, flex-start, flex-start);

    flex-grow: 1;
    width: 100%;
    min-height: 150px;

    &.noPadding {
      border: none;
      padding: 0;
      box-shadow: none;
    }
  }
}

.container .wrapper .paginationContainer {
  margin-bottom: var(--spacing-l);
  padding-right: var(--spacing-l);
  width: 100%;
}
