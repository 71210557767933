.head {
  display: flex;
  width: 95%;
  margin-bottom: var(--spacing-m);
  .menuInner {
    display: flex;
    align-items: center;
    white-space: nowrap;
    .icon {
      min-width: 20px;
      min-height: 20px;
    }
    span {
      margin-left: var(--spacing-sx);
    }
  }
}
