.tickerContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--spacing-xs) var(--spacing-m);
  width: 100%;
  background-color: var(--secondary-row-hover);
  border-radius: var(--border-radius);
  font-size: 14px;
  color: var(--gray-5);
  font-weight: 600;
  // border-bottom: 1px solid var(--main-border);

  > span:last-child {
    color: var(--primary);
  }
  > span:first-child {
    color: var(--muted-text);
  }
  &:not(:last-child) {
    margin-bottom: var(--spacing-xs);
  }
}
