@import "@styles/mixins";

.container {
  @include flexContainer(column, flex-start, stretch);

  flex-grow: 1;
  width: 100%;

  .wrapper {
    width: 100%;
    margin-top: var(--spacing-2xl);
  }
}
