@import "@styles/mixins.scss";

.innerList {
  @include flexContainer(column, flex-start, flex-start);

  flex-grow: 1;
  width: 100%;
  overflow: hidden;
  min-height: 110px;
  padding: 2px 0;
  padding-bottom: var(--spacing-s);

  .loaderContainer {
    @include flexContainer;
    @include borderedContainer(var(--main-border));
    width: 100%;
    height: 300px;
  }
  .listWrapper {
    --min-item-width: 350px;

    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(var(--min-item-width), 1fr));
    row-gap: var(--spacing-m);

    .createNewItem {
      width: 100%;
      padding-right: var(--spacing-m);

      .newItem {
        width: 100%;
      }
    }

    @media (max-width: 575px) {
      --min-item-width: 280px;
    }
  }
}
