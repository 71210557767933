.checkboxWrapper {
  display: flex;
  align-items: center;

  input[type="checkbox"] {
    -webkit-appearance: none;
    appearance: none;
    // min-width: 24px;
    // height: 24px;
    border-radius: var(--border-radius);
    margin-right: var(--spacing-xs);
    border: 2px solid var(--primary);
    outline: none;
    cursor: pointer;
  }

  input[type="checkbox"].small {
    min-width: 18px;
    height: 18px;
    border: 1px solid var(--primary);
  }

  input[type="checkbox"].medium {
    min-width: 24px;
    height: 24px;
  }

  input[type="checkbox"]:disabled {
    border-color: #c0c0c0;
    background-color: #c0c0c0;
  }
  input[type="checkbox"]:disabled + span {
    color: #c0c0c0;
  }

  input:checked {
    background-color: var(--white);
    position: relative;
  }

  input.medium:checked::before {
    content: "";
    display: inline-block;
    transform: rotate(45deg);
    height: 10px;
    width: 6px;
    margin-left: 60%;
    border-bottom: 2px solid var(--secondary);
    border-right: 2px solid var(--secondary);
    color: var(--secondary);
    position: absolute;
    top: 2px;
    right: 6px;
  }
  input.small:checked::before {
    content: "";
    display: inline-block;
    transform: rotate(45deg);
    height: 6px;
    width: 7px;
    margin-left: 60%;
    border-bottom: 2px solid var(--secondary);
    border-right: 2px solid var(--secondary);
    color: var(--secondary);
    position: absolute;
    top: 2px;
    right: 4px;
  }

  .label {
    cursor: pointer;
    color: var(--primary);
    opacity: 0.8;
    &.small {
      font-weight: 500;
      font-size: var(--font-size-base);
    }
  }
}
