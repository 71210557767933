@import "@styles/mixins";

.container {
  @include flexContainer(column, flex-end, flex-start);

  width: 100%;
  margin-bottom: var(--spacing-l);
  row-gap: var(--spacing-xs);

  .title {
    font-size: var(--font-size-main);
    font-weight: 600;
  }
  .innerContent {
    @include flexContainer(column, flex-end, flex-start);

    width: 100%;
    row-gap: var(--spacing-l);
    border-radius: var(--border-radius);
    border: 1px solid var(--main-border);
    padding: var(--spacing-m);

    .tableWrapper {
      width: 100%;
    }
    .paginationWrapper {
      width: 100%;
    }
  }
}
