@import "@styles/mixins.scss";

.container {
  @include flexContainer(column, flex-start, flex-start);

  --avatar-image-width: 190px;

  flex-grow: 1;
  background-color: var(--container-bg);
  box-shadow: var(--box-shadow);
  width: 100%;
  min-height: var(--inner-content-min-height);
  border-radius: var(--border-radius);

  .inner {
    @include flexContainer(column, flex-start, flex-start);

    flex-grow: 1;
    width: 100%;
    padding: var(--spacing-l);
    border-radius: var(--border-radius);
    animation: var(--slide-up-animation);

    .heading {
      font-size: var(--font-size-medium);
      font-weight: 600;
      margin-bottom: var(--spacing-l);
    }

    .navigation {
      width: 100%;
      margin-bottom: var(--spacing-l);
    }

    .infoSection {
      @include flexContainer(row, flex-start, stretch);

      width: 100%;
      margin-bottom: var(--spacing-l);
      column-gap: var(--spacing-m);

      .infoDetails {
        @include flexContainer(row, flex-start, flex-start);

        width: calc(100% - var(--avatar-image-width) - var(--spacing-m));
        flex-grow: 1;
      }

      @media (max-width: 767px) {
        flex-direction: column;
        row-gap: var(--spacing-m);

        .infoDetails {
          width: 100%;
        }
      }
    }

    .mainSection {
      @include flexContainer(column, flex-start, flex-start);

      width: 100%;
      flex-grow: 1;
      border-radius: var(--border-radius);
      box-shadow: var(--box-shadow);
      background-color: var(--white);
      padding: var(--spacing-l);

      .tabsContainer {
        @include flexContainer(column, flex-start, flex-start);

        width: 100%;
        flex-grow: 1;
      }
    }
  }
}
