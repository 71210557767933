@import "@styles/mixins";

.container {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;

  .templateInfo {
    @include flexContainer(column);

    width: 100%;
    border: 1px solid var(--secondary);
    border-radius: var(--border-radius);
    color: var(--secondary);
    padding: var(--spacing-l);
    font-size: var(--font-size-base);
    font-weight: 500;
    margin-bottom: var(--spacing-l);
    // background-color: var(--secondary);
    text-align: center;

    > p {
      font-size: var(--font-size-medium);
      font-weight: 600;
      margin-bottom: var(--spacing-xxs);
    }
  }

  .warning {
    @include flexContainer(column, flex-start, flex-start);

    width: 100%;
    row-gap: var(--spacing-xs);
    padding: var(--spacing-m);
    border-radius: var(--border-radius);
    color: var(--warning);
    border: 2px solid var(--warning);
    margin-bottom: var(--spacing-l);
    font-size: var(--font-size-base);
    font-weight: 500;

    .warningTitle {
      font-size: var(--font-size-medium);
      font-weight: 600;
    }
  }

  .row {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: var(--spacing-m);

    &:not(:last-child) {
      padding-bottom: var(--spacing-l);
    }
    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }

    &.noSpace {
      row-gap: 0;
      padding-bottom: 0;
      .itemWrapper {
        padding: 2px 0 var(--spacing-l) 0;
      }
    }
    .itemsGroup {
      display: flex;
      flex-wrap: wrap;
      width: calc((100% - 16px) * 2 / 3);
      gap: var(--spacing-m);
    }
    .itemWrapper {
      width: calc((100% - 32px) * 1 / 3);
      min-width: 270px;
      flex-grow: 1;

      > p {
        margin-bottom: var(--spacing-m);
        color: var(--primary);
        opacity: 0.8;
      }
      .radioGroup {
        display: flex;
        align-items: center;
        gap: var(--spacing-2xl);
      }
      &.empty {
        padding: 0;
      }
    }
    .inputWrapper {
      display: flex;
      flex-grow: 1;
      width: calc((100% - 16px) * 2 / 3);
    }
    .radioContainer {
      display: flex;
      gap: var(--spacing-m);
      flex-wrap: wrap;
      width: calc((100% - 16px) * 2 / 3);

      &.disabled {
        opacity: 0.5;
        pointer-events: none;
      }
      > p {
        margin-bottom: var(--spacing-m);
      }
    }
    &.benchmark {
      margin-top: var(--spacing-l);
      row-gap: var(--spacing-xs);
    }
    .benchmarkWrapper {
      @include borderedContainer(var(--main-border));
      @include flexContainer(row, flex-start);
      gap: var(--spacing-m);
      padding: var(--spacing-l);
      width: 100%;

      .inputWrapper {
        display: flex;
        flex-grow: 1;
      }
    }
  }
}

@media (max-width: 991px) {
  .container {
    .row {
      grid-template-columns: 1fr;
      .radioContainer {
        .radioGroup {
          margin-bottom: var(--spacing-m);
        }
      }
    }
  }
}

@media (max-width: 1199px) {
  .container {
    .row {
      .itemsGroup {
        width: 100%;
      }
    }
  }
}
