@import "@styles/mixins.scss";

.container {
  @include flexContainer(column, flex-start, flex-start);

  --icon-width: 180px;
  --small-icon-width: 32px;

  flex-grow: 1;
  background-color: var(--container-bg);
  box-shadow: var(--box-shadow);
  width: 100%;
  min-height: var(--inner-content-min-height);
  border-radius: var(--border-radius);

  .inner {
    @include flexContainer(column, flex-start, flex-start);

    width: 100%;
    flex-grow: 1;
    padding: var(--spacing-l);
    border-radius: var(--border-radius);
    animation: var(--slide-up-animation);

    .navGroup {
      margin-bottom: var(--spacing-l);
    }

    .content {
      @include flexContainer(column, flex-start, flex-start);

      flex-grow: 1;
      width: 100%;
      row-gap: var(--spacing-2xl);
      border-radius: var(--border-radius);
      background-color: var(--white);
      box-shadow: var(--box-shadow);
      padding: var(--spacing-m);
      position: relative;

      .loaderContainer {
        @include flexContainer(column);

        flex-grow: 1;
        width: 100%;
      }
      .tableContainer {
        @include flexContainer(column, flex-start, flex-start);

        flex-grow: 1;
        width: 100%;

        .headingRowGroup {
          @include flexContainer(row, flex-start);

          width: 100%;
          margin-bottom: var(--spacing-m);
          column-gap: var(--spacing-xs);

          .smallIconWrapper {
            @include flexContainer;

            width: var(--small-icon-width);
            min-width: var(--small-icon-width);
            height: var(--small-icon-width);

            > svg {
              width: 100%;
              height: 100%;
            }
          }

          > p {
            @include flexContainer(row, flex-start);

            flex-wrap: wrap;
            gap: var(--spacing-xs);
            font-weight: 500;
            font-size: var(--font-size-medium);
            color: var(--muted-text);

            > span {
              font-size: var(--font-size-main);
              color: var(--primary);
              font-weight: 400;
            }
          }
          .loaderWrapper {
            display: flex;
            margin-left: auto;
          }
        }
        .innerTableWrapper {
          display: flex;
          width: 100%;

          &.disable {
            opacity: 0.1;
            pointer-events: none;
          }
        }
        .buttonWrapper {
          padding: var(--spacing-m) 0;
          margin-left: auto;
        }
      }

      .pagination {
        @include flexContainer(row, flex-start);

        width: 100%;
      }
    }
  }
}

@media (max-width: 767px) {
  .container {
    .inner {
      .content {
        .tableContainer {
          .headingRowGroup {
            flex-direction: column;
            align-items: flex-start;
            row-gap: var(--spacing-m);
          }
        }
      }
    }
  }
}
