.tableContainer {
  height: 60dvh;
}

.dateRange {
  display: flex;
  align-items: flex-end;
  width: 50%;
  min-width: 350px;
  gap: var(--spacing-m);
  margin-bottom: var(--spacing-m);

  @media (max-width: 575px) {
    width: 100%;
  }
}
