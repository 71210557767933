.tabel {
  position: relative;
  overflow-y: auto;
  height: 100%;
  max-height: 450px;

  @media (max-width: 768px) {
    min-width: 200px;
    width: 100%;
  }
}

.head {
  margin-bottom: var(--spacing-sx);
  width: 100%;

  .description {
    display: block;
    font-size: var(--font-size-small);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    margin-bottom: var(--spacing-m);

    > span {
      color: var(--muted-text);
      padding-right: var(--spacing-xs);
    }
  }
  .heading {
    font-size: var(--font-size-main);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    margin-bottom: var(--spacing-xxs);

    > span {
      color: var(--muted-text);
      padding-right: var(--spacing-xs);
    }
  }
}
