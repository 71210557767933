@import "@styles/mixins.scss";

.container {
  @include flexContainer(column, flex-start, flex-start);

  padding: var(--spacing-l);
  width: 100%;
  flex-grow: 1;

  animation: var(--slide-up-animation);

  &.scrolled {
    overflow-y: auto;
  }

  .sliderWrapper {
    width: 100%;
    margin-bottom: var(--spacing-2xl);
  }
}

@media (max-width: 767px) {
  .container {
    padding: var(--spacing-2xl);
  }
}

@media (max-width: 575px) {
  .container {
    padding: var(--spacing-2xl) var(--spacing-m);
  }
}
