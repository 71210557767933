.container {
  width: 100%;
  position: relative;

  > label.required {
    position: relative;
    padding-left: var(--spacing-s);
  }
  > label.required::before {
    content: "*";
    position: absolute;
    left: 0;
    top: 0.2em;
    color: var(--error);
  }
}

.phoneInputContainer {
  overflow: none;
}

.container .input {
  position: relative;
  display: block;
  width: 100%;
  border: none;
  padding: var(--spacing-xs);
  padding-right: 148px;

  color: var(--primary);
  // opacity: 0.8;
  outline: 1px solid var(--primary);
  padding-left: var(--spacing-6xl);
  border-radius: var(--border-radius);

  > input {
    font-family: "Inter", sans-serif;
    background-color: transparent;
    display: block;
    width: 100%;
    border: none;
    resize: none;
    height: 22px;
    min-height: 22px;
    max-height: 300px;
    // overflow: hidden;
    font-size: var(--font-size-main);

    &:focus {
      outline: none;
    }
    &::placeholder {
      color: var(--muted-text);
    }
  }
}

.container .input .listContainer {
  position: absolute;
  bottom: 100%;
  left: 0;
  max-width: 100%;
  min-width: 250px;
  display: flex;
  max-height: 0px;
  opacity: 0;
  transition: max-height, opacity 0.5s;
  overflow-y: hidden;
  z-index: 50;
  pointer-events: none;

  &.open {
    max-height: 300px;
    opacity: 1;
    padding-bottom: var(--spacing-xs);
    overflow-y: unset;
    pointer-events: all;
  }
  .innerWrapper {
    width: 100%;
    padding: var(--spacing-m) 0;
    border-radius: var(--border-radius);
    background-color: var(--white);
    box-shadow: var(--box-shadow);
    cursor: pointer;

    .list {
      width: 100%;
      height: 100%;
      overflow-y: auto;

      .listItem {
        padding: var(--spacing-xxs) var(--spacing-m);
        padding-right: var(--spacing-2xl);
        color: var(--muted-text);
        font-size: var(--font-size-main);
        transition: 0.3s;

        &:hover {
          background-color: var(--secondary-hover);
          color: var(--main-color);
        }
      }
    }
  }
}

.fieldWrapper {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  background-color: var(--white);

  .clearBtnContainer {
    position: absolute;
    display: flex;
    align-items: center;
    right: 112px;
    top: 5px;
    padding: var(--spacing-xxs);
    width: 28px;
    height: 28px;
    cursor: pointer;
    &.disabled {
      display: none;
    }
    > svg {
      width: 100%;
      height: 100%;
    }
  }
  .inputInner {
    position: absolute;
    right: 0;
    height: 100%;
    width: 100px;
    display: flex;
    align-items: center;
  }
  .input {
    > input {
      color: var(--text);
    }
  }
}

.container .input:focus {
  outline: none;
  outline: 2px solid var(--primary);
  background-position: 0 0;
}

.container .input:-webkit-autofill {
  -webkit-text-fill-color: var(--text);
}

.container .input::placeholder {
  color: var(--placeholder);
}

.container > label {
  display: block;
  margin-bottom: var(--spacing-xs);
  color: var(--muted-text);
}

.icon {
  position: absolute;
  left: var(--spacing-m);
  top: 8px;
  display: flex;
  align-items: center;
  width: 20px;
  z-index: 2;
  opacity: 0.55;
}

.icon > img {
  width: 100%;
  height: auto;
}

@media (max-width: 575px) {
  .fieldWrapper {
    .inputInner {
      width: 65px;
      overflow: hidden;
    }
    .clearBtnContainer {
      right: 80px;
    }
  }
  .container .input {
    padding-right: 112px;
  }
}
