@import "@styles/mixins";

.container {
  @include flexContainer(column, flex-start, stretch);

  flex-grow: 1;
  width: 100%;
  background-color: var(--container-bg);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  padding: var(--spacing-l);
  animation: var(--slide-up-animation);
}
