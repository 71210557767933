.container {
  position: relative;
  background-color: var(--white);
  padding: var(--spacing-l);
  width: 800px;
  border-radius: var(--border-radius);
  display: flex;
  flex-direction: column;

  .close {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    width: 50px;
    height: 50px;
    font-size: 0;
    cursor: pointer;

    &:before,
    &:after {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 2px;
      height: 25px;
      background-color: var(--gray-3);
      transform: rotate(45deg) translate(-50%, -50%);
      transform-origin: top left;
      transition: all 420ms;
      content: "";
    }
    &:after {
      transform: rotate(-45deg) translate(-50%, -50%);
    }
    &:hover:before,
    &:hover:after {
      background-color: var(--primary);
    }
  }
  .recommend {
    display: flex;
    gap: var(--spacing-m);
    .loader {
      padding: 10px 65px;
    }
    .select {
      width: 100%;
    }
    .button {
      align-self: flex-end;
    }
  }
  .textInfo {
    margin-top: var(--spacing-m);
  }
  .section {
    display: flex;
    flex-wrap: wrap;
    .type {
      width: 230px;
      margin-right: var(--spacing-m);
    }
    .upload {
      display: flex;
      align-self: flex-end;
      margin-top: var(--spacing-m);
      .name {
        align-self: center;
        margin-left: var(--spacing-l);
        color: var(--muted-text);
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        max-width: 300px;
      }
    }
  }

  h2 {
    font-size: var(--font-size-extra-medium);
    font-weight: 600;
    color: var(--primary);
    margin-bottom: var(--spacing-m);
  }
  .modalFooter {
    display: flex;
    justify-content: flex-end;
    margin-top: var(--spacing-l);
    .loader {
      margin-right: var(--spacing-sx);
    }
    .buttons {
      button {
        margin-left: var(--spacing-m);
      }
    }
  }
}
