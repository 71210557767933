@import "@styles/mixins";

.emptyResultSection {
  @include flexContainer(column, flex-start, flex-start);

  width: 100%;
  margin-bottom: var(--spacing-2xl);
  flex-grow: 1;

  .heading {
    font-size: var(--font-size-medium);
    font-weight: 500;
    margin-bottom: var(--spacing-xs);
  }
  .inner {
    @include flexContainer;

    width: 100%;
    flex-grow: 1;
    column-gap: var(--spacing-7xl);
    padding: var(--spacing-2xl);
    border: 1px solid var(--main-color-separator);
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    animation: FadeInZoom 0.3s ease;

    .iconContainer {
      @include flexContainer;

      width: 120px;

      > svg {
        width: 120px;
        height: auto;
      }
    }

    .textSection {
      @include flexContainer(column, flex-start);

      padding: 0 var(--spacing-2xl);

      .title {
        font-size: var(--font-size-medium);
        margin-bottom: var(--spacing-xs);
        color: var(--primary);
        font-weight: 500;
        width: 100%;
      }
      .text {
        font-size: var(--font-size-main);
        color: var(--main-color);
        font-weight: 400;
      }
    }
  }
}

@keyframes FadeInZoom {
  0% {
    opacity: 0;
    transform: scale(0.7);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
