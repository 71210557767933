.container {
  width: 100%;
  display: grid;
  grid: auto / repeat(3, minmax(255px, 1fr));
  grid-gap: var(--spacing-m);
}
.tableContainre {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: var(--white);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  padding: var(--spacing-m);
  cursor: default;
}
.headerWrapper {
  display: flex;
  gap: var(--spacing-m);
  align-items: center;
  margin-bottom: var(--spacing-m);
}
.heading {
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  font-weight: 500;
  font-size: var(--font-size-main);
  color: var(--black);
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.btnContainer {
  display: flex;
  justify-content: space-between;
  padding-top: var(--spacing-xs);
  margin-top: auto;
}

.infiniteWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.noDataBox {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: var(--spacing-m) var(--spacing-m) var(--spacing-xl);
  text-align: center;
  color: var(--gray-5);
  opacity: 0.5;
  font-weight: 400;
  font-size: var(--font-size-medium-2);
}

@media (max-width: 1199px) {
  .container {
    grid: auto / repeat(2, minmax(255px, 1fr));
  }
}
@media (max-width: 767px) {
  .container {
    grid: auto / minmax(320px, 100%);
  }
}
